import { useState } from 'react'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.typings'
import { LoKationButton } from '../button/button'
import { Paper } from '../paper/paper'
import { TextInput } from '../text-input/text-input'

interface EmailNotificationComponentProps {
	serviceToNotify: string
	title?: string
	description?: string
	buttonLabel?: string
	noStyles?: boolean
	customField?: boolean
	customFieldLabel?: string
}

const apiCalls: { [key: string]: (customFieldValue?: string) => Promise<EmailNotificationTypes.EmailSubmitResponse> } =
	{
		cynosure: EmailNotificationAPI.cynosureEmailNotification,
		opcity: EmailNotificationAPI.opcityEmailNotification,
		cloudcma: EmailNotificationAPI.cloudCMANotification,
		freeTransactionCoordinator: EmailNotificationAPI.freeTransactionCoordinator,
		offboardingRequest: EmailNotificationAPI.offboardingRequest,
	}

export function EmailNotificationComponent(props: EmailNotificationComponentProps) {
	const [submitted, setSubmitted] = useState(false)
	const [customFieldValue, setCustomFieldValue] = useState('')
	async function sendNotification() {
		try {
			const apiCall = apiCalls[props.serviceToNotify]
			if (!apiCall) {
				throw new Error(`Unknown service: ${props.serviceToNotify}`)
			}
			if (props.customField && customFieldValue) {
				// Include the custom field value in the API call
				await apiCall(customFieldValue)
			} else {
				// No custom field value provided
				await apiCall()
			}
			setSubmitted(true)
			console.log('Notification was sent')
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<>
			{!props.noStyles ? (
				<Paper className="bg-color__adjust-alpha-5 p-20 my-20">
					<h2 className="mb-20">{props.title || 'Register'}</h2>
					<>
						{!submitted ? (
							<>
								{props.customField && props.customFieldLabel && (
									<TextInput
										label={props.customFieldLabel}
										dataType="text"
										value={customFieldValue}
										width="100%"
										className="mb-20"
										onChange={(updatedValue) => setCustomFieldValue(updatedValue)}
									/>
								)}
								<LoKationButton
									variant="contained"
									size="lg"
									primary={true}
									label={props.buttonLabel || 'Register'}
									onClick={sendNotification}
									className="mb-20"
								/>
							</>
						) : (
							<div className="mb-20">Your request has been submitted.</div>
						)}
					</>
					<div style={{ fontSize: '85%' }}>{props.description}</div>
				</Paper>
			) : (
				<>
					{!submitted ? (
						<LoKationButton
							variant="contained"
							size="lg"
							primary={true}
							label={props.buttonLabel || 'Register'}
							onClick={sendNotification}
						/>
					) : (
						<div>Your request has been submitted.</div>
					)}
					<div style={{ fontSize: '85%' }}>{props.description}</div>
				</>
			)}
		</>
	)
}
