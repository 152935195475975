import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Paper } from '@components/paper/paper'
import { useEffect, useState } from 'react'
import { UserService } from 'src/services/user/user.service'

import { EndUserProps } from '../../../../../services/user/user.types'
import { RegistrationService } from '../../../../registration/registration.service'
import { useAddOnService } from '../../state/add-on-service__state'
import { SubscribeToService } from './subscribe-to-service'

interface AddOnServiceListProps {
	serviceIds: string[]
	disableAllIfSubscribed?: boolean
	className?: string
	userToEdit?: EndUserProps
	currentUser?: EndUserProps | null
}

export const AddOnServiceList = ({
	serviceIds,
	className,
	disableAllIfSubscribed = false,
	userToEdit,
	currentUser,
}: AddOnServiceListProps) => {
	const [endUserId, setEndUserId] = useState<number | undefined>(undefined)
	const canUserEdit = currentUser ? UserService.isUserAdmin(currentUser) : false

	useEffect(() => {
		if (canUserEdit && userToEdit) {
			setEndUserId(userToEdit.endUserId)
		} else {
			setEndUserId(undefined)
		}
	}, [userToEdit])

	const {
		activeProductIds,
		productToSubscribe,
		optionalProductToSubscribe,
		productInitialPriceId,
		productTitle,
		productPrice,
		subscriptionType,
		productInitialTotal,
		productRecurringTotal,
		modalId,
		fetchActiveSubscriptions,
		manageSubscription,
		handleDismissModal,
	} = useAddOnService(endUserId)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		console.log('End User: ', endUserId)
		fetchActiveSubscriptions(endUserId).finally(() => setLoading(false))
	}, [endUserId])

	const registrationService = RegistrationService()
	const services = registrationService.getServices()
	const filteredServices = services.filter((service) => serviceIds.includes(service.id))
	const disableAllButtons =
		disableAllIfSubscribed &&
		serviceIds.some((serviceId) => {
			const service = services.find((service) => service.id === serviceId)
			return service && activeProductIds.includes(service.productId)
		})

	const luxuryServices = ['LUXURY_NONMEMBER_ANNUAL', 'LUXURY_NONMEMBER_MONTHLY']
	const luxuryAdditionalMember = ['LUXURY_MEMBER_ANNUAL', 'LUXURY_MEMBER_MONTHLY']
	const disableAllLuxuryButtons =
		disableAllIfSubscribed &&
		luxuryServices.some((serviceId) => {
			const service = services.find((service) => service.id === serviceId)
			if (service) {
				// console.log(activeProductIds)
				// console.log('Checking service:', service.productId);
				const isActiveProduct = activeProductIds.includes(service.productId)
				// console.log('Is active product:', isActiveProduct);
				return isActiveProduct
			}
			return false
		})

	return (
		<>
			{filteredServices.map((service) => (
				<div key={service.id} className={` ${className ? className : ''}`} style={{ width: '100%' }}>
					<Paper elevation={1} className="mt-10 p-20">
						{loading ? (
							<GenericContentLoader width={'fill'} height={87} />
						) : (
							<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down">
								<div className="flex flex-column flex-alignItems-center-md-down">
									<div className="product-name mb-10">{service.title}</div>
									<div className="flex flex-column flex-alignItems-center-md-down">
										<div className="mb-5">{service.description}</div>
										<div className="mb-5">{service.price}</div>
									</div>
								</div>
								<div className="subscribe-button">
									<LoKationButton
										className="mt-10"
										variant="outlined"
										label={
											luxuryAdditionalMember.includes(service.id)
												? 'Subscribe'
												: activeProductIds.includes(service.productId)
													? 'Subscribed'
													: 'Subscribe'
										}
										size={'sm'}
										onClick={() =>
											manageSubscription(
												service.title,
												service.price,
												service.subscriptionType,
												service.productId,
												service.initialCost,
												service.recurringCost,
												service.recurringPriceId[0],
												service.initialId[0],
												service.optionalRecurringPriceId[0],
											)
										}
										disabled={
											(activeProductIds.includes(service.productId) &&
												!luxuryAdditionalMember.includes(service.id)) ||
											disableAllButtons ||
											(disableAllLuxuryButtons && !luxuryAdditionalMember.includes(service.id))
										}
									/>
								</div>
							</div>
						)}
					</Paper>
				</div>
			))}

			{modalId &&
				productInitialTotal !== null &&
				productPrice &&
				subscriptionType &&
				productRecurringTotal !== null &&
				productTitle &&
				productToSubscribe && (
					<SubscribeToService
						modalId={modalId}
						title={productTitle}
						price={productPrice}
						subscriptionType={subscriptionType}
						initialTotal={productInitialTotal}
						initialRecurring={productRecurringTotal}
						productPriceIds={
							productInitialPriceId
								? [
										productToSubscribe,
										productInitialPriceId,
										...(optionalProductToSubscribe ? [optionalProductToSubscribe] : []),
									]
								: [
										productToSubscribe,
										...(optionalProductToSubscribe ? [optionalProductToSubscribe] : []),
									]
						}
						dismissModal={() => handleDismissModal(endUserId)}
						userToEdit={userToEdit}
					/>
				)}
		</>
	)
}
