import { Utilities } from '../utilities.service'
import { MessageDraftTypes } from './message-drafts.types'
import { MessagingService } from './messaging-service.types'

export function MessagingServiceService() {
	function areAllMessagesRead(messages: MessagingService.ChatMessage[]): boolean {
		return false
	}

	function getNewMessageDraftProps(): MessageDraftTypes.MessageDraft {
		return {
			senderId: -1,
			lastupdatedtimestamp: new Date().getTime(),
			message: '',
			endUserRecipients: [],
			licensedStateRecipients: [],
			specialtyRecipients: [],
			chatMessageDraftId: 0 - Utilities.generateRandomInt(1, 10000000),
			regionRecipients: [],
		}
	}

	function getChatGroupMemberNames(members: MessagingService.ChatGroupMember[]): string {
		const memberNames: string[] = []
		let memberNameString = ''

		members.forEach((member) => {
			memberNames.push(`${member.firstName} ${member.lastName}`)
		})

		memberNames.forEach((name, index) => {
			const isNotLast = index < memberNames.length - 1
			memberNameString += `${name}${isNotLast ? ', ' : ''} `
		})

		return memberNameString
	}

	return { areAllMessagesRead, getNewMessageDraftProps, getChatGroupMemberNames }
}
