import { Paper } from '@components/paper/paper'

import { FeaturedHubEditor } from '../featured-hub-editor/featured-hub-editor'
import { FeaturedResourceEditor } from '../featured-resource-editor/featured-resource-editor'

export function DomainAdminLandingSection() {
	return (
		<>
			<h1 className="mb-10">Landing Page</h1>
			<Paper className="p-20 mb-40">
				<h6>Featured Hubs</h6>
				<div className="mb-10 max-width__text-block">
					Determine which hubs you will present to users immediately after logging in.
				</div>
				<FeaturedHubEditor />

				<h6 className="mt-40">Featured Resources</h6>
				<div className="mb-10 max-width__text-block">
					Determine which resources you will present to users immediately after logging in.
				</div>
				<FeaturedResourceEditor />
			</Paper>
		</>
	)
}
