import './referral-contest-hub-section.scss'

import { LoKationButton } from '@components/button/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'
import { ReferralAPI } from 'src/services/referrals/referral.api'
import { EndUserReferral } from 'src/services/referrals/referral.types'

import { EndUserProps } from '../../services/user/user.types'
import CCGraphic from './cc_img.png'
import { Leaderboard } from './leaderboard'

interface ReferralLeaderboardProps {
	currentUser: EndUserProps | null
}

function ReferralLeaderboardPrototype({ currentUser }: ReferralLeaderboardProps) {
	const [referrals, setReferrals] = useState<EndUserReferral[] | undefined>(undefined)
	const [discount, setDiscount] = useState<number>(0)
	const [motivationText, setMotivationText] = useState<string>("It's never too late to start!")

	useEffect(() => {
		async function fetchUserReferrals() {
			if (!currentUser) return

			try {
				const res = await ReferralAPI.getReferralsFromUser(currentUser.endUserId)
				setReferrals(res.data)
				const bluePlanCount = countBluePlans(res.data)
				updateProgressBar(bluePlanCount)
				setDiscount(bluePlanCount * 10)
			} catch (error) {
				console.error('Error fetching user referrals:', error)
			}
		}

		fetchUserReferrals()
	}, [currentUser])

	function updateProgressBar(value: number) {
		const steps = document.querySelectorAll('.progress-step')
		steps.forEach((step, index) => {
			if (index < value) {
				step.classList.remove('empty')
			} else {
				step.classList.add('empty')
			}
		})

		switch (value) {
			case 0:
				setMotivationText('"It\'s never too late to start!"')
				break
			case 1:
				setMotivationText('"Congrats on Your First Referral! Keep Going!"')
				break
			case 2:
				setMotivationText('"Great Job! Two Referrals! You\'re on a Roll!"')
				break
			case 3:
				setMotivationText('"Fantastic! Three Referrals! Keep Up the Momentum!"')
				break
			case 4:
				setMotivationText('"Awesome! Four Referrals! You\'re Doing Great!"')
				break
			case 5:
				setMotivationText('"High Five! Five Referrals! Halfway There!"')
				break
			case 6:
				setMotivationText('"Impressive! Six Referrals! Keep It Up!"')
				break
			case 7:
				setMotivationText('"Superb! Seven Referrals! You\'re on Fire!"')
				break
			case 8:
				setMotivationText('"Amazing! Eight Referrals! Almost There!"')
				break
			case 9:
				setMotivationText('"Wow! Nine Referrals! Just One More!"')
				break
			case 10:
				setMotivationText('"You Did It! Ten Referrals! Congratulations!"')
				break

			default:
				if (value > 10) {
					setMotivationText('"You\'re dominating!"')
				} else {
					setMotivationText('"It\'s never too late to start!"')
				}
		}
	}

	function countBluePlans(referrals: EndUserReferral[]) {
		return referrals.reduce((count, referral) => {
			if (referral.licenseInformationList) {
				const hasBluePlan = referral.licenseInformationList.some((info) => info.plan === 'BLUE')
				return hasBluePlan ? count + 1 : count
			}
			return count
		}, 0)
	}

	return (
		<div className="flex flex-wrap" style={{ width: '100%' }}>
			<div className="col-12 col-md-8 ">
				<div className="col-12 border-thin border-radius__std p-20 mb-20 bg-color__adjust-5 flex flex-alignItems-center">
					<div className="flex-sm-down flex-column-sm-down">
						<div className="flex flex-alignItems-center border-bottom-thin flex-column-sm-down py-20-sm-down">
							<div>
								<FontAwesomeIcon icon={'file-invoice-dollar'} size={'3x'} color="#66A5B4" />
							</div>
							<div className="col-xs-12 ml-30 ml-0-sm-down mt-10-sm-down">
								<p>
									Access your referral link and track your progress with the signup meter below. Share
									the QR code to make agent sign-ups quick and easy.
								</p>
							</div>
							<LoKationButton
								variant="contained"
								label="Copy Referral Code"
								size={'sm'}
								onClick={() => {
									CopyToClipboard(
										`https://joinlokation.com/referral/?fname=${currentUser?.firstName}&lname=${currentUser?.lastName}&referral=${currentUser?.endUserId}`,
										'STRING',
										'Referral URL copied to your clipboard!',
									)
								}}
								className="ml-20 ml-0-sm-down"
								style={{ width: `200px` }}
							/>
						</div>
						<div className="mt-20">
							<div className="flex flex-justifyContent-spaceBetween flex-column-sm-down">
								<div className="col-12 col-md-8">
									<h4>Active Blue Plan Referrals</h4>
									<div>
										<div className="progress-container">
											<div className="progress-step empty" id="step-1"></div>
											<div className="progress-step empty" id="step-2"></div>
											<div className="progress-step empty" id="step-3"></div>
											<div className="progress-step empty" id="step-4"></div>
											<div className="progress-step empty" id="step-5"></div>
											<div className="progress-step empty" id="step-6"></div>
											<div className="progress-step empty" id="step-7"></div>
											<div className="progress-step empty" id="step-8"></div>
											<div className="progress-step empty" id="step-9"></div>
											<div className="progress-step empty" id="step-10"></div>
										</div>
										<div className="step-labels mb-20-sm-down">
											<span>0</span>
											<span>5</span>
											<span>10</span>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-4 flex flex-alignItems-center flex-column flex-justifyContent-center">
									<span>Current Discount</span>
									{referrals && <h4>${discount}/mo</h4>}
								</div>
							</div>
							{motivationText && <p>{motivationText}</p>}
						</div>
					</div>
				</div>
				<div>
					<h3>For July and August, here's how it works:</h3>

					<p>
						<strong>Blue Plan Referrals</strong>: For every agent who joins our Blue Plan through your
						referral, you'll earn a $20 credit each month.
					</p>
					<p>
						<strong>Silver Plan Referrals</strong>: If your referral joins the Silver Plan, you'll receive a
						$100 commission every time they close a transaction.
					</p>
					<p>
						As you expand your network and bring in new talent, these rewards will continue as long as your
						referred agent remains with the company.
					</p>
					<p>
						To participate, simply use the provided QR code and referral code to sign up new agents. The QR
						code makes it easy for potential recruits to access our sign-up page directly, ensuring a smooth
						and quick referral process.
					</p>
				</div>
				<div className="flex flex-justifyContent-center">
					<img src={CCGraphic} width={'100%'} />
				</div>
				<div className="mb-20-sm-down">
					<em>*Minimum 5 referrals needed to qualify for the 1st place</em>
				</div>
			</div>
			<div className="hub-view col-12 col-md-4 pl-20 pl-md-down-0">
				<Leaderboard />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const ReferralLeaderboard = connect(mapStateToProps)(ReferralLeaderboardPrototype)
