import { Paper } from '@components/paper/paper'

import { LoginCarouselEditor } from '../login-carousel-editor'
import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

export function DomainAdminLoginSection() {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	return (
		<>
			<h1 className="mb-10">Login</h1>
			<Paper className="p-20 mb-40">
				<h6>Carousel Text</h6>
				<div className="mb-10 max-width__text-block">
					Present text to prospective users on the login page. If more than one text block is added to the
					carousel, one block will be randomly presented to the user on each page refresh.
				</div>
				<LoginCarouselEditor
					className="mb-40"
					nodes={domainAdminState.modified.loginCarousel}
					updateCarousel={(updatedCarousel) => {
						domainAdminDispatch({ type: 'update-login-carousel', payload: updatedCarousel })
					}}
				/>
			</Paper>
		</>
	)
}
