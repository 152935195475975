import './paper.scss'

import React, { ReactNode } from 'react'

interface PaperProps {
	className?: string
	style?: React.CSSProperties
	elevation?: number
	interactive?: boolean
	children: ReactNode
	tabIndex?: number
	selected?: boolean
	onClick?: () => void
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	onKeyDown?: (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

/** A surface in which to render other components on top of */
export function Paper(props: PaperProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`paper`)

		if (props.interactive) {
			classes.push(`is-interactive`)
		}

		if (props.selected) {
			classes.push(`is-selected`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }
		const boxShadowLength = props.elevation ? props.elevation : 1
		style.boxShadow = `rgba(0, 0, 0, 0.1) 0px ${boxShadowLength}px ${
			boxShadowLength + 2
		}px 0px, rgba(0, 0, 0, 0.06) 0px ${boxShadowLength}px ${boxShadowLength + 1}px 0px`
		return style
	}

	return (
		<div
			tabIndex={props.interactive ? 0 : undefined}
			className={getClass()}
			style={getStyle()}
			onClick={(evt) => {
				if (props.onClick) {
					props.onClick()
				}
			}}
			onMouseEnter={() => {
				if (props.onMouseEnter) {
					props.onMouseEnter()
				}
			}}
			onMouseLeave={() => {
				if (props.onMouseLeave) {
					props.onMouseLeave()
				}
			}}
			onKeyDown={(evt) => {
				if (evt.key === 'Enter' && props.onClick) {
					props.onClick()
				}
			}}
		>
			{props.children}
		</div>
	)
}
