import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { RootState } from '@redux/store'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { StripePublicKey } from '../../../../../services/stripe/stripeConfig'
import { EndUserProps } from '../../../../../services/user/user.types'
import { StripeAddPaymentMethod } from './stripe-add-payment-method'

const stripePromise = loadStripe(StripePublicKey)

interface StripeAddPaymentMethodWrapperProps {
	dismissModal: () => void
	onPaymentMethodAddition: () => void
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function StripeAddPaymentMethodWrapperPrototype(props: StripeAddPaymentMethodWrapperProps) {
	const [clientSecret, setClientSecret] = useState('')
	const dismissModal = props.dismissModal
	const onPaymentMethodAddition = props.onPaymentMethodAddition
	const [endUserId, setEndUserId] = useState<number | undefined>()

	useEffect(() => {
		let isAdminEditing = false
		if (props.currentUser && props.userToEdit) {
			isAdminEditing = props.currentUser.endUserId !== props.userToEdit.endUserId
			const isAdmin = props.currentUser.roles.some((role) => role.roleType === 'ADMIN')

			if (isAdmin && isAdminEditing) {
				setEndUserId(props.userToEdit.endUserId)
			} else {
				setEndUserId(undefined)
			}
		}

		if (isAdminEditing && endUserId) {
			StripeAPI.getSetupIntent(endUserId).then((res) => {
				setClientSecret(res.clientSecret)
			})
		} else if (props.currentUser?.endUserId === props.userToEdit.endUserId) {
			StripeAPI.getSetupIntent().then((res) => {
				setClientSecret(res.clientSecret)
			})
		}
	}, [props.currentUser, props.userToEdit, endUserId])

	return (
		<>
			<Modal onClose={dismissModal} maxWidth={800} maxHeight={800} fixedHeight={false}>
				<ModalHeader title="Add payment method">
					<></>
				</ModalHeader>
				<ModalBody>
					{clientSecret ? (
						<Elements stripe={stripePromise} options={{ clientSecret }}>
							<StripeAddPaymentMethod
								dismissModal={dismissModal}
								onPaymentMethodAddition={onPaymentMethodAddition}
								userToEdit={props.userToEdit}
							/>
						</Elements>
					) : (
						<GenericContentLoader width={'fill'} height={250} className="mb-5" />
					)}
				</ModalBody>
			</Modal>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const StripeAddPaymentMethodWrapper = connect(mapStateToProps)(StripeAddPaymentMethodWrapperPrototype)
