import { Paper } from '@components/paper/paper'

type Ad__ToggleSelectorProps = {
	name: string
	description: string
	selected: boolean
	disabled: boolean
	onClick: () => void
}

export function Ad__ToggleSelector(props: Ad__ToggleSelectorProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`flex flex-alignItems-center p-10`)

		if (props.disabled) {
			classes.push('is-disabled')
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<Paper className={getClass()} interactive={true} selected={props.selected} onClick={props.onClick}>
			<div style={{ width: '125px', height: '75px' }} className="bg-color__adjust-alpha-10 flex-noShrink"></div>
			<div className="pl-20">
				<strong>{props.name}</strong>
				<div>{props.description}</div>
			</div>
		</Paper>
	)
}
