import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Tooltip } from '@components/tooltip/tooltip'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { StripeTypes } from '../../../../../services/stripe/stripe.typings'
import { StripePublicKey } from '../../../../../services/stripe/stripeConfig'
import { EndUserProps } from '../../../../../services/user/user.types'
import { CardBrands } from './cc-logos/card.typings'
import { getCardIcon } from './cc-logos/card-logos'
import { StripeAddPaymentMethod } from './stripe-add-payment-method'

const stripePromise = loadStripe(StripePublicKey)

interface ManagePaymentMethodsProps {
	defaultPaymentMethod?: string
	onPaymentMethodChange: (paymentMethodId: string) => void
	endUserId?: number
	userToEdit?: EndUserProps
}

function ManagePaymentMethods(props: ManagePaymentMethodsProps) {
	const [loading, setLoading] = useState(true)

	const [paymentMethods, setPaymentMethods] = useState<StripeTypes.PaymentMethod[]>([])
	const [newPaymentMethod, setNewPaymentMethod] = useState('')
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.defaultPaymentMethod)

	useEffect(() => {
		setLoading(true)
		StripeAPI.fetchCustomerPaymentMethods(props.endUserId)
			.then((res) => {
				const paymentMethodsArray = Object.values(res) as StripeTypes.PaymentMethod[]

				// Sort payment methods so the default one comes first
				paymentMethodsArray.sort((a, b) => {
					if (a.paymentMethodId === props.defaultPaymentMethod) {
						return -1
					}
					if (b.paymentMethodId === props.defaultPaymentMethod) {
						return 1
					}
					return 0
				})

				setPaymentMethods(paymentMethodsArray)
				setLoading(false)
			})
			.catch((error) => {
				console.log('Error fetching payment methods or default payment method:', error)
				setLoading(false)
			})
	}, [newPaymentMethod, props.endUserId])

	useEffect(() => {
		setSelectedPaymentMethod(props.defaultPaymentMethod)
	}, [props.defaultPaymentMethod])

	useEffect(() => {
		StripeAPI.getSetupIntent(props.endUserId).then((res) => {
			setClientSecret(res.clientSecret)
		})
	}, [])

	// const isPaymentMethodExpiring = (expMonth: number, expYear: number): boolean => {
	// 	const currentYear = new Date().getFullYear()
	// 	const currentMonth = new Date().getMonth() + 1 // Months are zero-indexed, so add 1

	// 	// Calculate remaining time until expiration
	// 	const remainingMonths = (expYear - currentYear) * 12 + expMonth - currentMonth

	// 	// Set the threshold for notifying the user (e.g., 1 month)
	// 	const expirationThreshold = 1

	// 	return remainingMonths <= expirationThreshold
	// }

	const [clientSecret, setClientSecret] = useState('')

	const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedPaymentMethodId = event.target.value
		console.log('Selected: ', selectedPaymentMethod)
		setSelectedPaymentMethod(selectedPaymentMethodId)
		props.onPaymentMethodChange(selectedPaymentMethodId)
	}

	const handlePaymentMethodAddition = (newPaymentMethodId: string) => {
		setNewPaymentMethod(newPaymentMethodId)
		setSelectedPaymentMethod(newPaymentMethodId)
		props.onPaymentMethodChange(newPaymentMethodId)
	}

	return (
		<div className="paper mt-10 p-20">
			<div className="edit-payment-method flex flex-column">
				<div className="flex flex-column">
					{loading ? (
						<GenericContentLoader width={'fill'} height={100} />
					) : (
						<>
							{paymentMethods &&
								paymentMethods.map((method) => (
									<div key={method.paymentMethodId} className="flex mb-5">
										{method.card ? (
											<>
												<input
													type="radio"
													id={method.paymentMethodId}
													name="payment-method"
													value={method.paymentMethodId}
													checked={selectedPaymentMethod === method.paymentMethodId}
													onChange={handlePaymentMethodChange}
													className="mr-10"
												/>
												<label
													htmlFor={method.paymentMethodId}
													style={{ width: '350px' }}
													className="payment-method-radio"
												>
													<div className="flex flex-justifyContent-spaceBetween">
														<div>
															<FontAwesomeIcon
																icon={
																	getCardIcon(
																		method.card.brand as CardBrands,
																	) as IconProp
																}
																className="mr-5"
															/>{' '}
															{method.card.brand} •••• {method.card.last4}
														</div>
														<div>
															Expires: {method.card.expMonth} / {method.card.expYear}
														</div>
													</div>
												</label>
											</>
										) : (
											<>
												<div className="default-wrapper">
													{method.paymentMethodId === props.defaultPaymentMethod && (
														<div className="default-indicator">
															<Tooltip
																icon="star"
																body="This is your default payment method"
															/>
														</div>
													)}
												</div>
												<div className="bank-name mb-10">{method.usBankAccount?.bankName}</div>
												<div>x{method.usBankAccount?.last4}</div>
												<div className="exp-date">Type: {method.usBankAccount?.type}</div>
											</>
										)}
									</div>
								))}
							<div className="mb-20">
								<input
									type="radio"
									id="add-new-payment"
									name="payment-method"
									value="add-new"
									checked={selectedPaymentMethod === 'add-new'}
									onChange={handlePaymentMethodChange}
									className="mr-10"
								/>
								<label htmlFor="add-new-payment" className="payment-method-radio">
									Add New Payment Method
								</label>
							</div>
							{selectedPaymentMethod === 'add-new' ? (
								<Elements stripe={stripePromise} options={{ clientSecret }}>
									<StripeAddPaymentMethod
										newPaymentMethodId={handlePaymentMethodAddition}
										userToEdit={props.userToEdit}
									/>
								</Elements>
							) : null}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default ManagePaymentMethods
