import './news-feed__story-list.scss'

import React from 'react'

import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { NewsFeedStory } from '../news-feed__story/news-feed__story'
import { NewsFeedStoryTimestamp } from '../news-feed__story/news-feed__story-timestamp'
import { NewsFeedStoryPreview } from '../news-feed__story-preview/news-feed__story-preview'
import { Paper } from '../paper/paper'

interface NewsFeedProps {
	stories: NewsFeed.Story[]
	style?: React.CSSProperties
	className?: string
	viewMode: 'FULL' | 'SMALL'
}

export function NewsFeedStoryList(props: NewsFeedProps) {
	return (
		<div
			style={props.style}
			className={`news-feed__story-list ${props.viewMode === 'FULL' ? 'full' : 'small'} ${props.className ? props.className : ''}`}
		>
			{props.stories
				.sort((a, b) => (b.createdTimestamp > a.createdTimestamp ? 1 : -1))
				.map((story, index) => {
					if (props.viewMode === 'SMALL') {
						return <NewsFeedStoryPreview {...story} key={story.newsStoryId} />
					}

					if (props.viewMode === 'FULL') {
						return (
							<Paper
								elevation={1}
								className="mb-50 bg-color__adjust-0 p-20 border-radius__std"
								key={story.newsStoryId}
							>
								<NewsFeedStoryTimestamp story={story} />
								<NewsFeedStory story={story} />
							</Paper>
						)
					}

					return <React.Fragment key={story.newsStoryId}></React.Fragment>
				})}
		</div>
	)
}
