import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { LeadCaptureTypes } from './lead-capture.types'

class LeadCaptureAPIPrototype {
	async capturelead(formData: LeadCaptureTypes.LeadCaptureInfo): Promise<LeadCaptureTypes.LeadCaptureResponse> {
		try {
			const params: any = {}

			// Conditionally add fields to params
			if (formData.email) params.email = formData.email.substring(0, 64)
			if (formData.firstName) params.firstName = formData.firstName.substring(0, 32)
			if (formData.lastName) params.lastName = formData.lastName.substring(0, 64)
			if (formData.state) params.state = formData.state.substring(0, 2)
			if (formData.phone) params.phone = formData.phone.substring(0, 64)
			if (formData.personalInfoCompleted !== undefined)
				params.personalInfoCompleted = formData.personalInfoCompleted
			if (formData.agentInfoCompleted !== undefined) params.agentInfoCompleted = formData.agentInfoCompleted
			if (formData.contractCompleted !== undefined) params.contractCompleted = formData.contractCompleted
			if (formData.optionalServiceCompleted !== undefined)
				params.optionalServiceCompleted = formData.optionalServiceCompleted
			if (formData.paymentCompleted !== undefined) params.paymentCompleted = formData.paymentCompleted
			if (formData.referrerId) params.referrerId = formData.referrerId.substring(0, 32)

			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/leads/`, params)

			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send lead information.')
		}
	}
}

export const LeadCaptureAPI = new LeadCaptureAPIPrototype()
