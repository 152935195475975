import { CollapsibleContent, CollapsibleContentStates } from '@components/collapsible-content/collapsible-content'
import { useState } from 'react'

import { FAQ } from '../../services/faq/faq.types'

export function HubFAQ(props: FAQ) {
	const [collapsibleState, setCollapsibleState] = useState<CollapsibleContentStates>('CLOSED')

	return (
		<CollapsibleContent
			state={collapsibleState}
			setState={setCollapsibleState}
			label={props.question}
			className="mb-10"
		>
			<div className="mb-230" dangerouslySetInnerHTML={{ __html: props.answer }} />
		</CollapsibleContent>
	)
}
