import './resource-card.scss'

import { ResourceCardResource } from '@components/resource-card-list/resource-card-list'
import { YouTubeEmbed } from '@components/youtube-embed/youtube-embed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RequestBaseURL } from 'src/services/axios/http-common.service'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Utilities } from '../../services/utilities.service'
import { FloatingActionButton } from '../floating-action-button/floating-action-button'
import { Modal } from '../modal/modal'
import { ModalBody } from '../modal/modal-body'
import { ModalHeader } from '../modal/modal-header'
import { Paper } from '../paper/paper'

export type ResourceCardStyle = 'LARGE' | 'SMALL' | 'SUBRESOURCE_1_COL' | 'SUBRESOURCE_2_COL' | 'LIST'

export interface ResourceCardProps {
	cardStyle: ResourceCardStyle
}

export function ResourceCard(props: ResourceCardProps & ResourceCardResource) {
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const [hasUserClicked, setHasUserClicked] = useState(false)
	const isExternalUrl = 'url' in props && typeof props.url === 'string' && props.url.includes(`http`)
	const isExternalUrlYouTubeVideo = 'url' in props && typeof props.url === 'string' && props.url.includes(`youtu`)

	const [showModal, setShowModal] = useState(false)

	function pushClickEventToAnalytics(): void {
		AnalyticsService().pushEvent({
			event_category: 'navigation',
			event_label: 'open_resource',
			value: {
				resource_id: props.resourceId,
				resource_title: props.title,
			},
		})
	}

	function openInternalResource(): void {
		if (typeof props.url !== 'string') {
			return
		}

		if (props.url.includes('/resources')) {
			searchParams.set('resource', String(props.url))
			setSearchParams(searchParams)
		} else {
			navigate(props.url)
		}
	}

	function handleExternalUrlClick() {
		setHasUserClicked(true)
		pushClickEventToAnalytics()

		if (props.onClick) {
			props.onClick()
		}

		if ('url' in props) {
			if (isExternalUrl && props.url && !isExternalUrlYouTubeVideo) {
				setTimeout(() => {
					window.open(props.url, '_blank')
				}, 1500)
			}

			if (isExternalUrl && props.url && isExternalUrlYouTubeVideo) {
				setShowModal(true)
			}

			if (!isExternalUrl && props.url && !isExternalUrlYouTubeVideo) {
				window.open(props.url, '_self')
			}
		}
	}

	function modalToDisplay(): JSX.Element {
		return (
			<>
				<Modal
					maxHeight={800}
					maxWidth={800}
					fixedHeight={false}
					className="flex flex-column"
					onClose={() => {
						setShowModal(false)
					}}
				>
					<>
						<ModalHeader title={props.title}>
							<></>
						</ModalHeader>
						<ModalBody>
							{isExternalUrlYouTubeVideo && props.url ? (
								<>
									<YouTubeEmbed videoUrl={props.url} />
									<div className="mt-20">{props.description}</div>
								</>
							) : (
								<>{props.description}</>
							)}
						</ModalBody>
					</>
				</Modal>
			</>
		)
	}

	/** Remove click scrim */
	useEffect(() => {
		if (setHasUserClicked) {
			setTimeout(() => {
				setHasUserClicked(false)
			}, 3000)
		}
	}, [hasUserClicked])

	function createCardElt(): JSX.Element {
		switch (props.cardStyle) {
			case 'LARGE':
				return (
					<div className="resource-card__top">
						{!props.published && (
							<div className="resource-card__type-icon unpublished" style={{ fontSize: '8pt' }}>
								UNPUBLISHED
							</div>
						)}
						{'url' in props && isExternalUrl && !isExternalUrlYouTubeVideo && !props.isSponsored && (
							<div className="resource-card__type-icon">
								<FontAwesomeIcon icon={['far', 'arrow-up-right']} />
							</div>
						)}
						{'url' in props && isExternalUrl && !isExternalUrlYouTubeVideo && props.isSponsored && (
							<div className="resource-card__type-icon" style={{ fontSize: '8pt' }}>
								SPONSORED
							</div>
						)}
						{'url' in props && isExternalUrl && isExternalUrlYouTubeVideo && (
							<div className="resource-card__type-icon">
								<FontAwesomeIcon icon={['far', 'play']} />
							</div>
						)}
						<div
							className={`resource-card__image ${props.published ? '' : 'unpublished-resource'}`}
							style={{
								backgroundImage: props.hasResourcePicture
									? `url(${RequestBaseURL}/api/v1/resources/${props.resourceId}/resourcePicture)`
									: `url(${props.bgImage})`,
							}}
						></div>
						<h5 className={`${props.published ? '' : 'unpublished-resource'}`}>{props.title}</h5>
						<div className={`${props.published ? '' : 'unpublished-resource'}`}>
							<div>
								<span>
									{props.description.length > 120
										? props.description.split(' ').reduce((prev, curr) => {
												return (prev + curr).length < 120 ? `${prev} ${curr}` : prev
											}, '')
										: props.description}
								</span>
								{props.description.length > 120 && (
									<FloatingActionButton
										className="bg-color__adjust-5 read-more-ellipsis"
										icon="ellipsis"
										onClick={(evt) => {
											evt.stopPropagation()
											pushClickEventToAnalytics()
											setShowModal(true)
										}}
									/>
								)}
							</div>
							{showModal && modalToDisplay()}
						</div>
					</div>
				)
			case 'SMALL':
				return (
					<>
						<div className="resource-card__description">
							<h6>{props.title}</h6>
							<div>
								<div>
									<span>
										{props.description.length > 120
											? props.description.split(' ').reduce((prev, curr) => {
													return (prev + curr).length < 120 ? `${prev} ${curr}` : prev
												}, '')
											: props.description}
									</span>
									{props.description.length > 120 && (
										<FloatingActionButton
											className="bg-color__adjust-5 read-more-ellipsis"
											icon="ellipsis"
											onClick={(evt) => {
												evt.stopPropagation()
												pushClickEventToAnalytics()
												setShowModal(true)
											}}
										/>
									)}
								</div>
								{showModal && modalToDisplay()}
							</div>
						</div>
					</>
				)
			case 'SUBRESOURCE_1_COL':
			case 'SUBRESOURCE_2_COL':
				return (
					<>
						<div
							className={`resource-card__image ${props.published ? '' : 'unpublished-resource'}`}
							style={{
								backgroundImage: props.hasResourcePicture
									? `url(${RequestBaseURL}/api/v1/resources/${props.resourceId}/resourcePicture)`
									: `url(${props.bgImage})`,
							}}
						></div>
						<div className="resource-card__description">
							<h6>{props.title}</h6>
							<div>{props.description}</div>
						</div>
					</>
				)
			case 'LIST':
				return (
					<>
						<div className="resource-card__description">
							<h6>{props.title}</h6>
							<div>{Utilities.truncateString(props.description, 200)}</div>
						</div>
						{showModal && modalToDisplay()}
					</>
				)
		}
	}

	let resourceCardClass = `resource-card`
	switch (props.cardStyle) {
		case 'LARGE':
			resourceCardClass = `resource-card large `
			break
		case 'SMALL':
			resourceCardClass = 'resource-card small'
			break
		case 'SUBRESOURCE_1_COL':
			resourceCardClass = `resource-card subresource col1`
			break
		case 'SUBRESOURCE_2_COL':
			resourceCardClass = `resource-card subresource col2 flex-column-xs-down`
			break
		case 'LIST':
			resourceCardClass = `resource-card search-results mb-10 ${props.published ? '' : 'unpublished-resource'}`
	}

	if (props.url && isExternalUrl === false) {
		return (
			<Paper
				elevation={1}
				tabIndex={0}
				className={resourceCardClass}
				onClick={() => {
					if (props.onClick) {
						props.onClick()
					}
					openInternalResource()
					pushClickEventToAnalytics()
				}}
			>
				{createCardElt()}
			</Paper>
		)
	}

	if (props.url && isExternalUrl === true) {
		return (
			<>
				<Paper
					elevation={1}
					tabIndex={0}
					className={resourceCardClass}
					onClick={handleExternalUrlClick}
					onKeyDown={(evt) => {
						if (evt.key === 'Enter') {
							handleExternalUrlClick()
						}
					}}
				>
					{createCardElt()}
				</Paper>
				{hasUserClicked && !isExternalUrlYouTubeVideo && (
					<>
						{ReactDOM.createPortal(
							<div className="modal__scrim">
								<div className="external-site-msg__wrapper">
									<FontAwesomeIcon
										icon={['far', 'globe']}
										size={`5x`}
										className={`mb-20 opacity-50`}
									/>
									<h5>Loading External Website</h5>
									<div className="mt-10">
										If you are not redirected within 5 seconds,{' '}
										<a href={props.url} target="_blank" rel="noreferrer">
											click here
										</a>
										.
									</div>
								</div>
							</div>,
							document.body,
						)}
					</>
				)}
			</>
		)
	}

	console.log(`Malformed resource. Resource card for '${props.title}' has no associated actions`)
	return (
		<div className={`${resourceCardClass}`}>
			<FontAwesomeIcon icon={['far', 'warning']} className="malformed-resource-icon" />
			{createCardElt()}
		</div>
	)
}
