import './news-feed__story-preview.scss'

import { useSearchParams } from 'react-router-dom'

import { DateService } from '../../services/date.service'
import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { Utilities } from '../../services/utilities.service'
import { Paper } from '../paper/paper'

export function NewsFeedStoryPreview(props: NewsFeed.Story) {
	const [searchParams, setSearchParams] = useSearchParams()

	function openStory(): void {
		searchParams.set('story', String(props.newsStoryId))
		setSearchParams(searchParams)
	}

	return (
		<>
			<Paper
				className={`news-feed__story-preview`}
				onClick={() => {
					openStory()
				}}
				onKeyDown={(evt) => {
					if (evt.key === 'Enter') {
						openStory()
					}
				}}
				tabIndex={0}
			>
				<div className="news-feed__story-header">
					<strong>{Utilities.truncateString(props.title, 100)}</strong> <span>·</span>{' '}
					<span className="datestamp">{DateService.getFormattedDateFromISO8601(props.createdTimestamp)}</span>
				</div>
				<div>{Utilities.truncateString(Utilities.stripHTMLTagsFromString(props.description), 100)}</div>
			</Paper>
		</>
	)
}
