import './messages.scss'

import { Badge } from '@components/badge/badge'
import { ListItem } from '@components/list-item/list-item'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { DateService } from '../../services/date.service'
import { MessagesAPI } from '../../services/messaging-service/messages.api'
import { MessagingServiceService } from '../../services/messaging-service/messaging-service.service'
import { MessagingService } from '../../services/messaging-service/messaging-service.types'
import { Utilities } from '../../services/utilities.service'

export function MessagesRoute() {
	const navigate = useNavigate()
	const [chatGroups, setChatGroups] = useState<MessagingService.ChatGroup[] | null>(null)
	const [chatMessages, setChatMessages] = useState<MessagingService.ChatMessage[] | null>(null)
	const [numUnreadMessages, setNumUnreadMessages] = useState<number>(0)
	const [updateTrigger, setUpdateTrigger] = useState(0)

	/** Get messages by sender */
	useEffect(() => {
		MessagesAPI()
			.getAllChatGroups({ page: 0, size: 50 })
			.then((chatGroupsRes) => {
				setChatGroups(chatGroupsRes.data.items)
			})
	}, [])

	useEffect(() => {
		if (chatGroups) {
			chatGroups.forEach((chatGroup) => {
				MessagesAPI()
					.getAllMessagesInChatGroup(chatGroup.chat.chatId, { page: 0, size: 50 })
					.then((res) => {
						setChatMessages(res.data.items)
					})
			})
		}
	}, [chatGroups])

	useEffect(() => {
		let messageCounter = 0
		if (chatMessages) {
			chatMessages.forEach((chatMessage) => {
				const sentTime = new Date(chatMessage.sentTimestamp)
				const lastReadTime = chatMessage.sentBy.lastRead ? new Date(chatMessage.sentBy.lastRead) : null
				if (lastReadTime === null || sentTime > lastReadTime) {
					messageCounter++
				}
			})
		}
		console.log('Message Counter: ', messageCounter)
		console.log('Trigger: ', updateTrigger)
		setNumUnreadMessages(messageCounter)
	}, [chatMessages, updateTrigger])

	/** ================================ */
	/** Methods */

	/** ================================ */
	/** Render Component */

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				className="pr-0 pb-0 flex flex-column"
				variant={'wide'}
				title={`Messages`}
				isCustom={true}
			>
				<div className="overflow-y__scroll flex-fillSpace">
					{chatGroups && chatGroups.length === 0 && (
						<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
							<FontAwesomeIcon
								icon={['far', 'mailbox']}
								size="4x"
								style={{ opacity: '0.75' }}
								className="mb-20"
							/>
							<h6>You have not received any messages</h6>
						</div>
					)}
					{chatGroups?.map((chatGroup) => {
						let isSelected = false

						return (
							<ListItem
								key={chatGroup.chat.chatId}
								avatar={{ size: 'lg' }}
								selected={isSelected}
								body={
									<div className="flex flex-alignItems-center flex-fillSpace">
										<div className="flex-fillSpace">
											<strong>
												{MessagingServiceService().getChatGroupMemberNames(
													chatGroup.chat.members,
												)}
											</strong>
											<div className="color__adjust-60">
												<span>
													{Utilities.truncateString(
														Utilities.stripHTMLTagsFromString(
															chatGroup.lastMessage.message,
														),
														50,
													)}
												</span>
												<div>
													<em>
														{DateService.getFormattedDateFromDateObj(
															new Date(chatGroup.lastMessage.sentTimestamp),
														)}
													</em>
												</div>
											</div>
										</div>
										<div>{numUnreadMessages > 0 && <Badge value={numUnreadMessages} />}</div>
									</div>
								}
								onClick={() => {
									MessagesAPI().markChatAsReadById(chatGroup.chat.chatId)
									setUpdateTrigger((oldTrigger) => oldTrigger + 1)
									navigate(`/messages/${chatGroup.chat.chatId}`)
								}}
							/>
						)
					})}
				</div>
			</RouteSubnavigation>

			<RouteBodyV2>
				<Outlet />
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
