import { IconButton } from '@components/icon-button/icon-button'
import { Popover } from '@components/popover/popover'
import { SideSheet__SectionProps } from '@components/side-sheet/side-sheet.types'
import { TooltipPopover } from '@components/tooltip/tooltip-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { a, useSpring } from '@react-spring/web'
import React, { useEffect, useRef, useState } from 'react'
import useMeasure from 'react-use-measure'
import { UsePopoverOnEvent } from 'src/services/hooks/use-popover-on-event'

function usePrevious<T>(value: T) {
	const ref = useRef<T>()
	useEffect(() => void (ref.current = value), [value])
	return ref.current
}

/** Building block of a side sheet. The Side Sheet Section encapsulates content inside of an accordian.
 * This component is intended to be used for the broadest level of organization within a side sheet.
 */
export function SideSheet__Section(props: SideSheet__SectionProps) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const popoverProps = UsePopoverOnEvent({ isPopoverOpen, setIsPopoverOpen })
	const sectionEltRef = useRef<HTMLDivElement>(null)

	const cachedSideSheetExpandedKey = `side-sheet__is-expanded__${
		props.mode === 'expandable' ? props.sectionKey : 'null'
	}`

	/** ================================== */
	/** Methods */

	function getInitialIsExpanded(): boolean {
		/** Used cached state as the initial state of this section, if possible */
		if (props.mode === 'expandable') {
			const cachedState = localStorage.getItem(cachedSideSheetExpandedKey)
			if (cachedState && typeof JSON.parse(cachedState) === 'boolean') {
				return JSON.parse(cachedState)
			}
		}

		/** If no cached state is available, attempt to get its default state from the default defined in component */
		if (props.mode === 'expandable' && typeof props.isExpanded === 'boolean') {
			return props.isExpanded
		}

		/** Fallback value */
		return true
	}

	function handleClick(): void {
		if (props.mode === 'addable') {
			props.onClick()
		} else {
			localStorage.setItem(cachedSideSheetExpandedKey, String(!isOpen))
			setOpen(!isOpen)
		}
	}

	function getIconStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}

		style.transition = 'transform 250ms ease'
		if (isOpen) {
			style.transform = 'rotate(180deg)'
		} else {
			style.transform = 'rotate(0deg)'
		}
		return style
	}

	/** ============================================================== */
	/** Animate Element */

	const [isOpen, setOpen] = useState(getInitialIsExpanded())
	const previous = usePrevious(isOpen)
	const [ref, { height: viewHeight }] = useMeasure()
	const { height, opacity } = useSpring({
		to: {
			height: isOpen ? viewHeight : 0,
			opacity: isOpen ? 1 : 0,
		},
		config: {
			tension: 120,
			friction: 14,
			clamp: true,
		},
	})

	/** ============================================================== */
	/** Render Element */

	return (
		<>
			<div className={`side-sheet__section ${isOpen ? 'is-open' : ''}`}>
				<div
					className="side-sheet__section__header"
					onClick={handleClick}
					ref={sectionEltRef}
					onMouseEnter={() => {
						setIsPopoverOpen(true)
					}}
					onMouseLeave={() => {
						setIsPopoverOpen(false)
					}}
				>
					<div className="flex flex-alignItems-center">
						{props.icon && <FontAwesomeIcon icon={['fas', props.icon]} className="opacity-50 mr-10" />}
						<strong>{props.label}</strong>
					</div>
					{props.headerBody && <div className="flex-fillSpace">{props.headerBody}</div>}
					{props.mode === 'expandable' && (
						<IconButton icon={'angle-up'} iconStyle={getIconStyle()} onClick={handleClick} />
					)}
					{props.mode === 'addable' && <IconButton icon={'plus'} onClick={handleClick} />}
				</div>
				{props.mode === 'expandable' && (
					<a.div
						style={{
							opacity,
							height: isOpen && previous === isOpen ? 'auto' : height,
						}}
						className="side-sheet__section__body"
					>
						<a.div
							ref={ref}
							className="side-sheet__section__body-inner"
							style={{
								padding: '10px',
								backgroundColor: props.bgColor ? props.bgColor : '',
								color: props.textColor ? props.textColor : '',
							}}
						>
							{props.children}
						</a.div>
					</a.div>
				)}
			</div>
			{sectionEltRef.current && (
				<Popover {...popoverProps} options={{}} refElement={sectionEltRef.current} escapeOnMouseOut={true}>
					<TooltipPopover>
						{props.label}
						{props.tooltip}
					</TooltipPopover>
				</Popover>
			)}
		</>
	)
}
