import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { IconSelectorModal } from '../../modals/icon-selector/icon-selector'
import { Paper } from '../paper/paper'

interface FontAwesomeIconSelectorTileProps {
	icon: IconName | null
	onSelect: (icon: IconName) => void
	onRemove: () => void
	className?: string
	style?: React.CSSProperties
}

export function FontAwesomeIconSelectorTile(props: FontAwesomeIconSelectorTileProps) {
	const [isMouseIn, setIsMouseIn] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<>
			<Paper style={props.style} className={`image-upload-tile ${props.className ? props.className : ''}`}>
				<div className="flex-fillSpace col-xs-12 flex flex-alignItems-center flex-column flex-justifyContent-center">
					<div
						className="image-upload-tile__image-wrapper"
						onMouseEnter={() => {
							setIsMouseIn(true)
						}}
						onMouseLeave={() => {
							setIsMouseIn(false)
						}}
						onClick={() => {
							setIsModalOpen(true)
						}}
					>
						{props.icon && <FontAwesomeIcon icon={['far', props.icon]} size="5x" />}
						{!props.icon && <FontAwesomeIcon icon={['far', 'image']} size="3x" />}
						{isMouseIn && <div className="image-upload__hover-bg">SELECT</div>}
					</div>
				</div>
			</Paper>
			{isModalOpen && (
				<IconSelectorModal
					dismissModal={() => {
						setIsModalOpen(false)
					}}
					handleSelect={(icon) => {
						props.onSelect(icon)
					}}
				/>
			)}
		</>
	)
}
