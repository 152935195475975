import { LoKationButton } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { RootState } from '@redux/store'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import { BackAtYouAPI } from '../../services/back-at-you/back-at-you.api'
import { BackAtYouTypes } from '../../services/back-at-you/back-at-you.typings'
import { EndUserProps } from '../../services/user/user.types'
import BackAtYouCoverImg from './back-at-you-cover.png'
import BackAtYouLandingPgImg from './back-at-you-landing-pg.png'
import BackAtYouListingImg from './back-at-you-listing.png'
import BackAtYouLogo from './back-at-you-logo.png'
import BackAtYouVideoImg from './back-at-you-video.png'

interface BackAtYouProps {
	currentUser: EndUserProps | null
}

function BackAtYouPrototype({ currentUser }: BackAtYouProps) {
	const [ssoInfo, setSsoInfo] = useState<BackAtYouTypes.GetBackAtYouTokenDTO | null>(null)

	useEffect(() => {
		const fetchSsoInfo = async () => {
			if (currentUser && currentUser.bayUserId) {
				const info = await BackAtYouAPI.getBackAtYouToken() // Fetch SSO info here
				if (info.bayUserId === currentUser.bayUserId) {
					console.log('SSO Info: ', info)
					setSsoInfo(info)
				}
			}
		}
		fetchSsoInfo()
	}, [currentUser])

	const formSubmitBtn = useRef<HTMLInputElement>(null)

	return (
		<div className="flex flex-column overflow-y__scroll px-40">
			<img src={BackAtYouCoverImg} className="mb-20" />

			<div className="text-center col-xs-12">
				<h1>Back at you</h1>
				<strong>
					Back At You is ready to set you up for long-term success on our social media platform. Let&apos;s
					get to it!
				</strong>
				<p className="text-center">
					You&apos;ll see pretty quickly why Back At You is a trusted partner of the National Association of
					REALTORS®, REALTOR Benefits® Program AND a badged Facebook Marketing Partner with ad Technology
					and FBX specialties.
				</p>
				{currentUser && currentUser.bayUserId && ssoInfo && (
					<form
						action="https://www.backatyou.com/sso/login"
						method="POST"
						style={{ display: 'none' }}
						target="_blank"
					>
						<input type="hidden" name="user_id" value={ssoInfo.bayUserId} />
						<input type="hidden" name="timestamp" value={ssoInfo.timestamp} />
						<input type="hidden" name="token" value={ssoInfo.token} />
						<input type="submit" value="Submit" ref={formSubmitBtn}></input>
					</form>
				)}

				<LoKationButton
					variant="contained"
					size="lg"
					label="Sign In"
					onClick={() => {
						if (formSubmitBtn.current) {
							formSubmitBtn.current.click()
						}
					}}
				/>

				<AddOnServiceList serviceIds={['BACK_AT_YOU']} className="text-left" />
			</div>

			<HorizontalRule className="mt-20" />

			<div className="flex-md mb-40 pt-20">
				<div className="col-xs-12 col-md-8 pr-40-md">
					<h6>PROFESSIONAL CONTENT & SCHEDULER</h6>
					<p>
						Did you know 45% of audiences watch at least an hour of Facebook videos a week? We&apos;re
						always on top of trends relevant to your brand and business, which is why you receive a diverse
						bank of video, local, and professionally written content pre-scheduled and auto-posted for you.
						We&apos;ll send you an email every month to view and edit any scheduled content. The Social
						Media Center also allows for easy to use targeting to promote pieces of content that you like!
					</p>
				</div>
				<div className="col-xs-12 col-md-4">
					<h6>DID YOU KNOW?</h6>
					<p>
						Facebook Marketing Partners are painstakingly vetted not only for excellence and expertise, but
						also for a solid track record of success?
					</p>
					<p>
						This gives you the confidence to move forward knowing that you&apos;re working with the best
						company to supercharge you on Facebook.
					</p>
				</div>
			</div>

			<div className="flex-md flex-alignItems-center mb-40">
				<div className="col-xs-12 col-md-4">
					<img src={BackAtYouListingImg} className="col-xs-12" />
				</div>
				<div className="col-xs-12 col-md-8 pl-40-md">
					<h6>FULL LISTING AUTOMATION</h6>
					<p>
						When you have a listing, our Social Media Center designs a Single Property Website to
						automatically post on your social media pages! Have a virtual tour you want to add to the Single
						Property Website? No problem! Adding a video is as simple as clicking a few buttons. When you
						advertise a listing using your Social Media Center, our system will generate a Listing Report
						full of analytics that you can share with your seller to show how hard you&apos;re working to
						promote their listing!
					</p>
				</div>
			</div>

			<div className="flex-md flex-alignItems-center mb-40">
				<div className="col-xs-12 col-md-8 pr-40-md">
					<h6>CUSTOM LANDING PAGES</h6>
					<p>
						Capturing leads on social media is made possible with an unlimited number of landing pages that
						are automatically posted to your social media platforms! Best of all, you can customize the
						language and imagery on your landing pages to reflect you and your market.
					</p>
					<p>
						Use these landing pages anywhere, and customize them for specific areas of your business such as
						single family homes, beach condos, rural properties, etc.
					</p>
				</div>
				<div className="col-xs-12 col-md-4">
					<img src={BackAtYouLandingPgImg} className="col-xs-12" />
				</div>
			</div>

			<div className="flex-md flex-alignItems-center mb-40">
				<div className="col-xs-12 col-md-4">
					<img src={BackAtYouVideoImg} className="col-xs-12" />
				</div>
				<div className="col-xs-12 col-md-8 pl-40-md">
					<h6>WANT TO ADD VIDEOS TO A LISTING?</h6>
					<p>
						{' '}
						Head to the Listings tab in your Social Media Center, find the listing you want and click Add
						Video. Next, paste the link of your video and click Add Video. That&apos;s it!
					</p>
				</div>
			</div>

			<div className="flex-md flex-alignItems-center mb-40">
				<div className="col-xs-12 col-md-4">
					<img src={BackAtYouLogo} className="col-xs-12" />
					<p>
						Available to LoKation Agents for just $35/month (normally $75/month + $149 set up fee) Overview
						and Training webinars: https://lokation.thinkific.com/courses/back-at-you Back At You support:
						support@backatyou.com Support Number: 800.660.7719 Ext 1
					</p>
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const BackAtYouRoute = connect(mapStateToProps)(BackAtYouPrototype)
