import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import { EmailNotificationAPI } from '../../../../../services/email-notification/email-notification.api'
import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { StripeTypes } from '../../../../../services/stripe/stripe.typings'
import { EndUserProps } from '../../../../../services/user/user.types'
import { CardBrands } from '../billing-components/cc-logos/card.typings'
import { getCardIcon } from '../billing-components/cc-logos/card-logos'
import ManagePaymentMethods from '../billing-components/manage-payment-methods'
interface ManageSubscriptionProps {
	modalId: string
	subscriptionId: string
	dismissModal: () => void
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function ManageSubscription(props: ManageSubscriptionProps) {
	const [subscriptionItems, setSubscriptionItems] = useState<StripeTypes.SubscriptionItem[]>([])
	const [paymentMethod, setPaymentMethod] = useState<StripeTypes.PaymentMethodData | null>(null)
	const [subscriptionStatus, setSubscriptionStatus] = useState<string>('')
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')
	const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] = useState('')
	const [showManagePaymentMethods, setShowManagePaymentMethods] = useState(false)
	const [showCancellationConfirmation, setShowCancellationConfirmation] = useState(false)
	const [endUserId, setEndUserId] = useState<number | undefined>()

	/** ============================== */
	/** Effects */

	useEffect(() => {
		fetchSubscriptionDetails()
	}, [])

	useEffect(() => {
		if (props.currentUser && props.userToEdit) {
			const isUserBeingEdited = props.currentUser.endUserId !== props.userToEdit.endUserId
			const isAdmin = props.currentUser.roles.some((roles) => roles.roleType === 'ADMIN')
			if (isUserBeingEdited && isAdmin) {
				setEndUserId(props.userToEdit.endUserId)
			}
		}
	}, [])

	/** ============================== */
	/** Methods */

	const handleEditPaymentMethods = () => {
		setShowManagePaymentMethods(true)
	}

	const fetchSubscriptionDetails = () => {
		StripeAPI.fetchSubscriptionDetails(props.subscriptionId)
			.then((res) => {
				setDefaultPaymentMethod(res.defaultPaymentMethod)

				if (res.defaultPaymentMethod != null) {
					setPaymentMethod(res.paymentMethodDetails)
				}

				setSubscriptionStatus(res.status)
				const subscriptionArray = res.items.data as StripeTypes.SubscriptionItem[]
				setSubscriptionItems(subscriptionArray)
			})
			.catch((error) => {
				console.log('Failed to fetch subscription details:', error)
			})
	}

	// Function to handle payment method change
	const handlePaymentMethodChange = (paymentMethodId: string) => {
		setNewDefaultPaymentMethod(paymentMethodId)
	}

	const emailConfirmation = (productName: string, endUserId?: number) => {
		EmailNotificationAPI.requestSubscriptionCancellation(productName, endUserId)
			.then((res) => {
				setShowCancellationConfirmation(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const updatePaymentMethod = async () => {
		try {
			const subscriptionId = props.subscriptionId
			if (defaultPaymentMethod === 'add-new' || !newDefaultPaymentMethod) {
				props.dismissModal()
			} else {
				await StripeAPI.updateSubscriptionPaymentMethod(newDefaultPaymentMethod, subscriptionId)
				props.dismissModal()
				console.log('Payment method updated successfully')
			}
		} catch (error) {
			console.log(error)
			console.log("There was an error updating this subscription's payment method")
		}
	}

	/** ============================== */
	/** Render Component */

	return (
		<>
			<Modal
				onClose={props.dismissModal}
				maxWidth={800}
				maxHeight={800}
				fixedHeight={false}
				className="flex flex-column"
			>
				<>
					<ModalHeader title="Manage Subscription">
						<></>
					</ModalHeader>
					<ModalBody>
						{subscriptionItems.map((subscriptionItem) => (
							<Paper elevation={1} className="p-20">
								<div key={subscriptionItem.id}>
									{showCancellationConfirmation ? (
										<div className="cancel-subscription-text">
											Your request to cancel {subscriptionItem.plan.productDetails.name} has been
											submitted. A member of our team will reach out shortly.
										</div>
									) : (
										<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-sm-down">
											<div className="flex flex-alignItems-center">
												<div>
													<div
														className={`subscription-status ${subscriptionStatus === 'active' ? 'active' : 'canceled'}`}
													>
														{subscriptionStatus === 'active' ? 'Active' : 'Canceled'}
													</div>
													<div className="product-name mb-10">
														{subscriptionItem.plan.productDetails.name} ($
														{(subscriptionItem.plan.amount / 100).toFixed(2)} x{' '}
														{subscriptionItem.quantity})
													</div>
													<div className="invoice-date mb-5">
														Billing interval:
														{subscriptionItem.plan.intervalCount === 1 &&
															subscriptionItem.plan.interval === 'year' &&
															' Yearly'}
														{subscriptionItem.plan.intervalCount === 3 &&
															subscriptionItem.plan.interval === 'month' &&
															' Quarterly'}
														{subscriptionItem.plan.intervalCount === 1 &&
															subscriptionItem.plan.interval === 'month' &&
															' Monthly'}
													</div>
												</div>
											</div>
											<div>
												<LoKationButton
													className="mt-10"
													variant="outlined"
													label="Request Cancellation"
													size={'sm'}
													// disabled={true}
													onClick={() => {
														const productName = subscriptionItem.plan.productDetails.name

														if (!productName) {
															// Handle the case when product name is undefined, maybe show an error message
															return
														}

														if (props.currentUser && props.userToEdit) {
															const isAdmin =
																props.currentUser.endUserId !==
																props.userToEdit.endUserId
															if (isAdmin) {
																const endUserId = props.userToEdit.endUserId
																emailConfirmation(productName, endUserId)
															} else {
																emailConfirmation(productName)
															}
														}
													}}
												/>
											</div>
										</div>
									)}
								</div>
							</Paper>
						))}
						{paymentMethod && (
							<Paper elevation={1} className="p-20 my-10">
								<div>
									<h6 className="mb-10">Payment Method</h6>
									{paymentMethod && paymentMethod.card && !showManagePaymentMethods ? (
										<div className="card-brand">
											<FontAwesomeIcon
												icon={getCardIcon(paymentMethod.card.brand as CardBrands) as IconProp}
											/>{' '}
											{paymentMethod.card.brand} •••• {paymentMethod.card.last4}
											<FontAwesomeIcon
												icon={faPencilAlt}
												className="ml-10"
												onClick={handleEditPaymentMethods}
											/>
										</div>
									) : (
										showManagePaymentMethods && (
											<ManagePaymentMethods
												defaultPaymentMethod={defaultPaymentMethod}
												onPaymentMethodChange={handlePaymentMethodChange}
												userToEdit={props.userToEdit}
												endUserId={endUserId}
											/>
										)
									)}

									{paymentMethod.type === 'link' && (
										<>
											<div className="mb-20">
												Your payment method is set to a Link Wallet. To manage your default
												payment method, please go click the button below. You will need to use
												the email you used when you enabled payments by Link.
											</div>

											<LoKationButton
												className="mt-10 mb-20"
												variant="outlined"
												label="Manage Link Wallet"
												size={'sm'}
												onClick={() => {
													window.open(`https://app.link.com/`, '_blank')
												}}
											/>
										</>
									)}
								</div>
							</Paper>
						)}
					</ModalBody>
					<ModalFooter>
						<div className="flex flex-justifyContent-end mt-20">
							<LoKationButton
								variant="outlined"
								size="sm"
								primary={false}
								label="Cancel"
								className="mr-10"
								onClick={props.dismissModal}
							/>

							{showCancellationConfirmation ? (
								<LoKationButton
									variant="contained"
									size="sm"
									primary={true}
									label="Close"
									onClick={() => props.dismissModal()}
								/>
							) : (
								<LoKationButton
									variant="contained"
									size="sm"
									primary={true}
									label="Close"
									onClick={() => updatePaymentMethod()}
								/>
							)}
						</div>
					</ModalFooter>
				</>
			</Modal>
		</>
	)
}

export default ManageSubscription
