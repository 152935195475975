import { httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { ListingGenerationTypes } from './listing-generation.typings'

class ListingGenerationPrototype {
	async GenerateListing(
		address: string,
		reference: string,
	): Promise<ListingGenerationTypes.ListingGenerationResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/generate-listing`,
				{},
				{
					params: {
						address,
						reference,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to generate listing based off the address submitted.')
		}
	}

	async cancelGenerateListing() {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/cancel-generation`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to cancel listing generation.')
		}
	}
}

export const ListingGeneration = new ListingGenerationPrototype()
