import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import Logo from './accessadoctor-logo.png'
import NurseImg from './nurse-bg.jpg'

export function LoKationResourceAccessadoctor() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-40-md-down pr-20-lg flex flex-column flex-alignItems-start overflow-y__scroll">
				<img src={Logo} style={{ width: '400px' }} />
				<p>
					Doctors can be hard to reach, illnesses can occur in the middle of the night, and sometimes you just
					have a question. In all of those circumstances — and many more — MyTelemedicine is a convenient and
					affordable solution. As a member you now receive access to a national network of U.S.
					board-certified doctors who are available 24/7/365 to treat many of your medical issues.
				</p>

				<div className="flex flex-wrap">
					<div className="col-xs-12 col-lg-4 pr-20-lg">
						<h5>
							<strong>Simple as 1, 2, 3...</strong>
						</h5>
						<ol>
							<li>
								<strong>Connect</strong>
								<br />
								<p>
									Patient calls 1.800.611.5601 or logs on to their member portal to schedule a
									constulation with a physician licensed in their state.
								</p>
							</li>
							<li>
								<strong>Triage</strong>
								<br />
								<p>
									Member speaks to a Care Coordinator who will triage and update the patient's
									Electronic Health Record (EHR) along with all symptoms.
								</p>
							</li>
							<li>
								<strong>Consult</strong>
								<br />
								<p>
									Member consults with Physician who recommends a treatment plan. If a prescription is
									necessary, it's sent to the pharmacyt of your choice.
								</p>
							</li>
						</ol>
					</div>

					<div className="col-xs-12 col-lg-4 pr-20-lg">
						<h5>
							<strong>Common Conditions</strong>
						</h5>
						<p>
							In some cases, a visit to the doctor's office can be avoided, saving time and money. Part of
							that effort involves the delivery of care for a growing list of common conditions by
							qualified physicians via video, phone or email.
						</p>
						<ul>
							<li>Cold & Flu Symptions</li>
							<li>Sinus Problems</li>
							<li>Ear infection</li>
							<li>Allergies</li>
							<li>Urinary Tract Infection</li>
							<li>Nausea</li>
							<li>Pink eye</li>
							<li>Stomach viruses</li>
							<li>Infections</li>
							<li>Rashes</li>
							<li>Sore Throat</li>
							<li>Acne</li>
							<li>Recommendation</li>
							<li>Second Opinions</li>
						</ul>
						<div>...and much more</div>
					</div>

					<div className="col-xs-12 col-lg-4 pr-20-lg">
						<h5>
							<strong>When to Use</strong>
						</h5>
						<p>
							MyTelemedicine does not replace your primary care physician. Our goal is to provide you with
							convenient, affordable healthcare, when you need it most.
						</p>
						<ul>
							<li>When you need care now</li>
							<li>If you need a second opinion on lab results</li>
							<li>If you have a health related question and just need professional guidance</li>
							<li>On vacation, on a business trip, or away from home</li>
							<li>If you're considering the ER or urgent care center for a non-emergency issue</li>
							<li>For short-term prescription refills</li>
						</ul>
					</div>
				</div>

				<p>
					We offer a very reduced rate for a convenient and affordable option for U.S. Board-Certified Doctors
					who are available 24/7/365. The service is provided by Access a Doctor and allows nationwide Doctor
					access via website or mobile app. Consult with specialist via Secure Messaging (telephone and/or
					video) for common ailments that may need a prescription. This saves you the time, effort, cost and
					exposure of going to your Primary.
				</p>

				<strong className="mb-10">Does not affect your existing insurance.</strong>
				<strong className="mb-10">No Co-Pay required.</strong>
				<strong className="mb-10">Add up to 7 dependents.</strong>
				<strong className="mb-10">Cancel anytime.</strong>
				<strong className="mb-10">Reduced from $34.95 to $12.95 per month.</strong>

				<AddOnServiceList serviceIds={['ACCESSADOCTOR']} />
			</div>
			<div
				className="col-xs-12 col-lg-4 show-lg border-radius__std"
				style={{ backgroundImage: `url(${NurseImg})`, backgroundSize: 'cover', overflow: 'hidden' }}
			>
				<div
					className="pl-20-sm flex flex-column flex-alignItems-center flex-justifyContent-center bg-color__adjust-alpha-60 height__100pct p-40 col-xs-12"
					style={{ color: '#fff' }}
				>
					<h4 className="mb-40 text-center">Access to care when you need it most</h4>

					<div className="col-xs-12 col-lg-6 text-center flex flex-column mb-40">
						<FontAwesomeIcon icon={['far', 'clock']} size="3x" />
						<strong>24/7/365 access to care</strong>
					</div>
					<div className="col-xs-12 col-lg-6 text-center flex flex-column mb-40">
						<FontAwesomeIcon icon={['far', 'flame']} size="3x" />
						<strong>It's fast</strong>
					</div>
					<div className="col-xs-12 col-lg-6 text-center flex flex-column mb-40">
						<FontAwesomeIcon icon={['far', 'check']} size="3x" />
						<strong>It's convenient</strong>
					</div>
					<div className="col-xs-12 col-lg-6 text-center flex flex-column">
						<FontAwesomeIcon icon={['far', 'money-bill']} size="3x" />
						<strong>It's affordable</strong>
					</div>
				</div>
			</div>
		</div>
	)
}
