import { RootState } from '@redux/store'
import { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { AgentSearchState } from 'src/lokation-routes/agent-search/state/agent-search__state.types'

import { EndUserProps } from '../../services/user/user.types'
import { GoogleMapAPI } from './google-map.api'
import { GoogleMapTypes } from './google-map.types'

declare const google: any

declare global {
	interface Window {
		initMap: () => void
		eqfeed_callback: (results: any) => void
	}
}

let pos
let map: any
let bounds: any
let infoWindow: any
// let currentInfoWindow: any
// let service: any
// let infoPane: any

interface GoogleMapUserProps {
	currentUser: EndUserProps | null
	params: AgentSearchState.SearchParams
}

/** https://developers.google.com/maps/documentation/geocoding/requests-geocoding#json */

function GoogleMapPrototype(
	props: GoogleMapTypes.Component & GoogleMapUserProps & { centerLat?: number | null; centerLng?: number | null },
) {
	const [searchParams, setSearchParams] = useSearchParams()
	const isInitialized = getIsScriptInitialized()

	/** ===================================================== */
	/** Effects */

	useEffect(() => {
		loadLibrary().then(() => {
			initMap()
		})
	}, [])

	/** ===================================================== */
	/** Hooks */
	const mapEltRef = useRef<HTMLDivElement>(null)

	/** ===================================================== */
	/** Methods */

	function getIsScriptInitialized(): boolean {
		/** Check if script is already initialized */
		const existingScript = document.body.querySelector(`#google-maps-script`)
		if (existingScript) {
			return true
		}
		return false
	}

	function loadLibrary(): Promise<void> {
		return new Promise((resolve) => {
			if (isInitialized) {
				resolve()
				return
			}

			GoogleMapAPI.loadGoogleMaps().then((res) => {
				if (res.status > 199 && res.status < 300) {
					const googleMapsScript = document.createElement('script')
					googleMapsScript.id = `google-maps-script`
					googleMapsScript.innerHTML = res.data
					document.body.appendChild(googleMapsScript)

					resolve()
					return
				}
			})

			// axios.get(`https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=places`).then((res) => {
			//     if (res.status > 199 && res.status < 300) {
			//         const googleMapsScript = document.createElement('script')
			//         googleMapsScript.id = `google-maps-script`
			//         googleMapsScript.innerHTML = res.data
			//         document.body.appendChild(googleMapsScript)

			//         resolve()
			//         return
			//     }
			// })

			// const script = document.createElement('script');
			// script.id = 'google-maps-script';
			// script.src = `https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=places`;
			// script.async = true;
			// script.defer = true;
			// document.head.appendChild(script);

			// script.onload = () => {
			//     resolve();
			// };
		})
	}

	// Loop through the results array and place a marker for each
	// set of coordinates.
	function addCustomMarkers(markerData: GoogleMapTypes.MarkerData) {
		for (let i = 0; i < markerData.features.length; i++) {
			const coords = markerData.features[i].geometry.coordinates
			const latLng = new google.maps.LatLng(coords[0], coords[1])

			let marker = new google.maps.Marker({
				position: latLng,
				map: map,
				title: markerData.features[i].properties.name,
				id: markerData.features[i].properties.endUserId,
			})

			/* TODO: Step 4B: Add click listeners to the markers */
			// Add click listener to each marker
			google.maps.event.addListener(marker, 'click', () => {
				searchParams.set('user', String(marker.id))
				setSearchParams(searchParams)
			})
		}
	}

	function initMap() {
		// Initialize variables
		bounds = new google.maps.LatLngBounds()
		infoWindow = new google.maps.InfoWindow()
		// currentInfoWindow = infoWindow
		/* TODO: Step 4A3: Add a generic sidebar */
		const mapElt = mapEltRef.current

		// Try HTML5 geolocation
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					}
					map = new google.maps.Map(mapElt, {
						center: pos,
						zoom: 14,
					})
					bounds.extend(pos)

					infoWindow.setPosition(pos)

					map.setCenter(pos)

					if (props.centerLat && props.centerLng) {
						const center = {
							lat: props.centerLat,
							lng: props.centerLng,
						}

						map = new google.maps.Map(mapElt, {
							center: center,
							zoom: 14,
						})
					}

					/* TODO: Step 3B2, Call the Places Nearby Search */
					// Call Places Nearby Search on user's location
					// getNearbyPlaces(pos);
					addCustomMarkers(props.markerData)
				},
				() => {
					// Browser supports geolocation, but user has denied permission
					handleLocationError(true, infoWindow)
				},
			)
		} else {
			// Browser doesn't support geolocation
			handleLocationError(false, infoWindow)
		}

		// Handle a geolocation error
		function handleLocationError(browserHasGeolocation: boolean, infoWindow: any) {
			// Set default location to Sydney, Australia
			console.log(infoWindow)

			if (props.params && props.params.licensedStateId) {
				let stateFilterMap
				switch (props.params.licensedStateId) {
					case 1:
						// Florida
						stateFilterMap = { lat: 26.2315099, lng: -80.1004711 }
						break
					case 2:
						// Georgia
						stateFilterMap = { lat: 34.0964594, lng: -84.3034489 }
						break
					case 3:
						// Colorado
						stateFilterMap = { lat: 39.5866775, lng: -104.9232329 }
						break
					case 4:
						// Alabama
						stateFilterMap = { lat: 33.4000863, lng: -86.705917 }
						break
					case 5:
						// Texas
						stateFilterMap = { lat: 33.1072536, lng: -96.8743731 }
						break
					case 6:
						// South Carolina
						stateFilterMap = { lat: 34.0033439, lng: -81.075404 }
						break
					default:
						stateFilterMap = { lat: 26.2315099, lng: -80.1004711 }
						break
				}

				map = new google.maps.Map(mapElt, {
					center: stateFilterMap,
					zoom: 8,
				})
			} else if (props.centerLat && props.centerLng) {
				const center = {
					lat: props.centerLat,
					lng: props.centerLng,
				}

				map = new google.maps.Map(mapElt, {
					center: center,
					zoom: 14,
				})
			} else {
				// Display an InfoWindow at the map center
				let pos
				if (props.currentUser && props.currentUser.addressLat && props.currentUser.addressLon) {
					pos = { lat: props.currentUser.addressLat, lng: props.currentUser.addressLon }
				} else {
					pos = { lat: 26.260779, lng: -80.1004711 }
					infoWindow.setPosition(pos)
					infoWindow.setContent(
						browserHasGeolocation
							? 'Geolocation permissions denied. Using default location.'
							: "Error: Your browser doesn't support geolocation.",
					)
					infoWindow.open(map)
				}
				map = new google.maps.Map(mapElt, {
					center: pos,
					zoom: 14,
				})

				// currentInfoWindow = infoWindow
			}

			/* TODO: Step 3B3, Call the Places Nearby Search */
			// Call Places Nearby Search on the default location
			//getNearbyPlaces(pos);
			addCustomMarkers(props.markerData)
		}
	}

	/** ===================================================== */
	/** Render Component */

	return (
		<>
			<div ref={mapEltRef} style={{ width: '100%', height: '100%' }}></div>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const GoogleMap = connect(mapStateToProps)(GoogleMapPrototype)
