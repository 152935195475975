import './collapsible-content.scss'

import { Caret } from '../caret/caret'

export interface CollapsibleContentProps {
	state: CollapsibleContentStates
	setState: (newState: CollapsibleContentStates) => void
	label: string
	children: React.ReactElement
	className?: string
}

export type CollapsibleContentStates = 'OPEN' | 'CLOSED'

export function CollapsibleContent(props: CollapsibleContentProps) {
	return (
		<div
			className={`collapsible-content__wrapper ${props.state === 'OPEN' ? 'open' : 'closed'} ${props.className ? props.className : ''}`}
		>
			<div
				className="collapsible-content__header"
				onClick={() => {
					if (props.state === 'CLOSED') {
						props.setState('OPEN')
					} else {
						props.setState('CLOSED')
					}
				}}
			>
				<strong className="color__accent">{props.label}</strong>
				<Caret state={props.state === 'OPEN' ? 'opened' : 'closed'} />
			</div>
			<div>{props.state === 'OPEN' && <>{props.children}</>}</div>
		</div>
	)
}
