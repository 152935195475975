import './search-filter-container.scss'

import { Paper } from '../paper/paper'

interface SearchFilterContainerProps {
	children: React.ReactNode
	className?: string
	width: SearchFilterWidth
}

type SearchFilterWidth = 'standard' | 'wide'

export function SearchFilterContainer(props: SearchFilterContainerProps) {
	return (
		<Paper
			elevation={0}
			className={`search-filter-container ${props.width === 'standard' ? 'width-standard' : 'width-wide'} ${props.className ? props.className : ''}`}
		>
			{props.children}
		</Paper>
	)
}
