import { ThinkificRoute } from '@components/thinkific/thinkific'
import { ThinkificRealEstateRoute } from '@components/thinkific/thinkific-real-estate'
import { ThinkificWritingOffersRoute } from '@components/thinkific/thinkific-writing-offers'
import { TitleCaptureRoute } from '@components/title-capture/title-capture'
import { useLocation } from 'react-router-dom'
import { ListingGenerationRoute } from 'src/lokation-routes/listing-generation/listing-generation'
import { QRCodePDFRoute } from 'src/lokation-routes/qr-code-pdf/qr-code-pdf'
import { ToolkitCMARoute } from 'src/lokation-routes/toolkit-cma/toolkit-cma'
import { ToolkitCMASignOnRoute } from 'src/lokation-routes/toolkit-cma/toolkit-cma-sign-on'
import { QrCodeRoute } from 'src/modals/qr-quick-add/qr-code'
import { RealEstateLeadsRoute } from 'src/modals/real-estate-leads/real-estate-leads'

import { LoKationResourceAccessadoctor } from '../lokation-routes/accessadoctor/accessadoctor'
import { BackAtYouRoute } from '../lokation-routes/back-at-you/back-at-you'
import { LoKationResourceBellhopRoute } from '../lokation-routes/bellhop/bellhop'
import { LoKationResourceBrandResources } from '../lokation-routes/brand-resources/brand-resources'
import BreakthroughBrokerRoute from '../lokation-routes/breakthrough-broker/breakthrough-broker'
import { LoKationResourceCloudCMA } from '../lokation-routes/cloud-cma'
import { CustomizableSignageTemplates } from '../lokation-routes/customizable-signage-templates/customizable-signage-templates'
import { LoKationResourceCynosure } from '../lokation-routes/cynosure/cynosure'
import { AlabamaDepartmentContacts } from '../lokation-routes/department-contacts/alabama-department-contacts'
import { ColoradoDepartmentContacts } from '../lokation-routes/department-contacts/colorado-department-contacts'
import { FloridaDepartmentContacts } from '../lokation-routes/department-contacts/florida-department-contacts'
import { GeorgiaDepartmentContacts } from '../lokation-routes/department-contacts/georgia-department-contacts'
import { SouthCarolinaDepartmentContacts } from '../lokation-routes/department-contacts/south-carolina-department-contacts'
import { TexasDepartmentContacts } from '../lokation-routes/department-contacts/texas-department-contacts'
import { LoKationResourceDotloopFl } from '../lokation-routes/dotloop/dotloop-fl'
import { FreeTransactionCoordinator } from '../lokation-routes/free-transaction-coordinator/free-transaction-coordinator'
import { LoKationResourceGoogleMyBusiness } from '../lokation-routes/google-my-business'
import { LoKationResourceHealthAdvantage } from '../lokation-routes/health-advantage/health-advantage'
import LokationResourceHomeSearchAppFl from '../lokation-routes/home-search-app-fl/home-search-app-fl'
import { LoKationResourceHomeWarrantyFl } from '../lokation-routes/home-warranty-fl/home-warranty-fl'
import { LoKationResourceKVCorePlatform } from '../lokation-routes/kvcore-platform/kvcore-platform'
import { LuxuryAssetsEmailBanners } from '../lokation-routes/luxury-assets-email-banners/luxury-assets-email-banners'
import { LuxuryBrandingColors } from '../lokation-routes/luxury-branding/luxury-branding-colors/luxury-branding-colors'
import { LuxuryBrandingFonts } from '../lokation-routes/luxury-branding/luxury-branding-fonts/luxury-branding-fonts'
import { LoKationResourceLuxuryMembershipBenefits } from '../lokation-routes/luxury-membership/luxury-membership-benefits'
import { LoKationResourceLuxuryMembershipDetails } from '../lokation-routes/luxury-membership/luxury-membership-details'
import { LoKationResourceLuxuryMembershipForm } from '../lokation-routes/luxury-membership/luxury-membership-form'
import { ManageTransactionsRoute } from '../lokation-routes/manage-transactions/manage-transactions'
import { LoKationResourceMortgagePreApprove } from '../lokation-routes/mortgage-preapprove/mortgage-preapprove'
import { LoKationResourceOpCity } from '../lokation-routes/opcity/opcity'
import { PaperlessPipelineRoute } from '../lokation-routes/paperless-pipeline/paperless-pipeline'
import { LoKationResourcePhotographerRoute } from '../lokation-routes/photographer/photographer'
import { PolicyProceduresRoute } from '../lokation-routes/policy-procedures/policy-procedures'
import { PropertyMgmtLicenseAgreementRoute } from '../lokation-routes/property-mgmt-license-agreement/property-mgmt-license-agreement'
import { QuickSocial } from '../lokation-routes/quick-social/quick-social'
import { LoKationReferAgent } from '../lokation-routes/refer-agent/refer-agent'
import { LoKationResourceZipYourFlyer } from '../lokation-routes/zip-your-flyer/zip-your-flyer'
import { InternalResourceModal } from '../modals/internal-resource/internal-resource'
import { NewsFeedStoryModal } from '../modals/news-feed-story/news-feed-story'
import { UserProfileModal } from '../modals/user-profile/user-profile'

/** This component handles the hiding and showing of all globally accessible modals */
export function ModalsWrapper() {
	let location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	const newsFeedStoryId = urlParams.get('story')
	const userProfileId = urlParams.get('user')
	const resourceId = urlParams.get('resource')

	function getResourceRoute(resourceId: string): React.ReactNode {
		switch (resourceId) {
			case '/resources/back-at-you':
				return (
					<InternalResourceModal title="Back at You!">
						<BackAtYouRoute />
					</InternalResourceModal>
				)
			case '/resources/breakthrough-broker':
				return (
					<InternalResourceModal title="LoKation Marketing Center">
						<BreakthroughBrokerRoute />
					</InternalResourceModal>
				)
			case '/resources/photographer':
				return (
					<InternalResourceModal title="HomeVisit">
						<LoKationResourcePhotographerRoute />
					</InternalResourceModal>
				)
			case '/resources/bellhop':
				return (
					<InternalResourceModal title="Bellhop">
						<LoKationResourceBellhopRoute />
					</InternalResourceModal>
				)
			case '/resources/cloud-cma':
				return (
					<InternalResourceModal title="Cloud CMA">
						<LoKationResourceCloudCMA />
					</InternalResourceModal>
				)
			case '/resources/google-my-business':
				return (
					<InternalResourceModal title="Google My business">
						<LoKationResourceGoogleMyBusiness />
					</InternalResourceModal>
				)
			case '/resources/opcity':
				return (
					<InternalResourceModal title="Opcity">
						<LoKationResourceOpCity />
					</InternalResourceModal>
				)
			case '/resources/health-advantage':
				return (
					<InternalResourceModal title="Health Advantage">
						<LoKationResourceHealthAdvantage />
					</InternalResourceModal>
				)
			case '/resources/home-warranty-fl':
				return (
					<InternalResourceModal title="Complete Home Protection Plan">
						<LoKationResourceHomeWarrantyFl />
					</InternalResourceModal>
				)
			case '/resources/dotloop-fl':
				return (
					<InternalResourceModal title="Dotloop">
						<LoKationResourceDotloopFl />
					</InternalResourceModal>
				)
			case '/resources/home-search-fl':
				return (
					<InternalResourceModal title="Home Search">
						<LokationResourceHomeSearchAppFl />
					</InternalResourceModal>
				)
			case '/resources/accessadoctor':
				return (
					<InternalResourceModal title="Accessadoctor">
						<LoKationResourceAccessadoctor />
					</InternalResourceModal>
				)
			case '/resources/cynosure':
				return (
					<InternalResourceModal title="Cynosure">
						<LoKationResourceCynosure />
					</InternalResourceModal>
				)
			case '/resources/kvcore-platform':
				return (
					<InternalResourceModal title="kvCore">
						<LoKationResourceKVCorePlatform />
					</InternalResourceModal>
				)
			case '/resources/zip-your-flyer':
				return (
					<InternalResourceModal title="Zip Your Flyer">
						<LoKationResourceZipYourFlyer />
					</InternalResourceModal>
				)
			case '/resources/brand-resources':
				return (
					<InternalResourceModal title="Brand Resources">
						<LoKationResourceBrandResources />
					</InternalResourceModal>
				)
			case '/resources/mortgage-preapprove':
				return (
					<InternalResourceModal title="Mortgage Pre-approval">
						<LoKationResourceMortgagePreApprove />
					</InternalResourceModal>
				)
			case '/resources/luxury-membership-benefits':
				return (
					<InternalResourceModal title="Luxury Membership Benefits">
						<LoKationResourceLuxuryMembershipBenefits />
					</InternalResourceModal>
				)
			case '/resources/luxury-membership-details':
				return (
					<InternalResourceModal title="Luxury Membership Details">
						<LoKationResourceLuxuryMembershipDetails />
					</InternalResourceModal>
				)
			case '/resources/luxury-membership-form':
				return (
					<InternalResourceModal title="Luxury Membership Form">
						<LoKationResourceLuxuryMembershipForm />
					</InternalResourceModal>
				)
			case '/resources/property-mgmt-license':
				return (
					<InternalResourceModal title="Property Management License Agreement">
						<PropertyMgmtLicenseAgreementRoute />
					</InternalResourceModal>
				)
			case '/resources/policy-procedures':
				return (
					<InternalResourceModal title="Policy and Procedures">
						<PolicyProceduresRoute />
					</InternalResourceModal>
				)
			case '/resources/paperless-pipeline':
				return (
					<InternalResourceModal title="Paperless Pipeline">
						<PaperlessPipelineRoute />
					</InternalResourceModal>
				)
			case '/resources/refer-an-agent':
				return (
					<InternalResourceModal title="Refer an Agent">
						<LoKationReferAgent />
					</InternalResourceModal>
				)
			case '/resources/free-transaction-coordinator':
				return (
					<InternalResourceModal title="Free Transaction Coordinator">
						<FreeTransactionCoordinator />
					</InternalResourceModal>
				)
			case '/resources/florida/department-contacts':
				return (
					<InternalResourceModal title="Florida Department Contacts">
						<FloridaDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/georgia/department-contacts':
				return (
					<InternalResourceModal title="Georgia Department Contacts">
						<GeorgiaDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/south-carolina/department-contacts':
				return (
					<InternalResourceModal title="South Carolina Department Contacts">
						<SouthCarolinaDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/texas/department-contacts':
				return (
					<InternalResourceModal title="Texas Department Contacts">
						<TexasDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/colorado/department-contacts':
				return (
					<InternalResourceModal title="Colorado Department Contacts">
						<ColoradoDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/alabama/department-contacts':
				return (
					<InternalResourceModal title="Alabama Department Contacts">
						<AlabamaDepartmentContacts />
					</InternalResourceModal>
				)
			case '/resources/manage-transactions-and-forms':
				return (
					<InternalResourceModal title="Manage Transactions">
						<ManageTransactionsRoute />
					</InternalResourceModal>
				)
			case '/resources/thinkific':
				return (
					<InternalResourceModal title="Thinkific">
						<ThinkificRoute />
					</InternalResourceModal>
				)
			case '/resources/thinkific/real-estate-101':
				return (
					<InternalResourceModal title="Thinkific">
						<ThinkificRealEstateRoute />
					</InternalResourceModal>
				)
			case '/resources/thinkific/writing-offers':
				return (
					<InternalResourceModal title="Thinkific">
						<ThinkificWritingOffersRoute />
					</InternalResourceModal>
				)
			case '/resources/title-capture':
				return (
					<InternalResourceModal title="Title Capture">
						<TitleCaptureRoute />
					</InternalResourceModal>
				)
			case '/resources/quick-social':
				return (
					<InternalResourceModal title="Quick Social">
						<QuickSocial />
					</InternalResourceModal>
				)
			case '/resources/luxury-branded-fonts':
				return (
					<InternalResourceModal title="Lokation Luxury Approved Font">
						<LuxuryBrandingFonts />
					</InternalResourceModal>
				)
			case '/resources/luxury-branding-colors':
				return (
					<InternalResourceModal title="Lokation Luxury Approved Colors">
						<LuxuryBrandingColors />
					</InternalResourceModal>
				)
			case '/resources/luxury-customizable-signage-templates':
				return (
					<InternalResourceModal title="Luxury Customizable Signage Templates">
						<CustomizableSignageTemplates />
					</InternalResourceModal>
				)
			case '/resources/luxury-assets-email-banners':
				return (
					<InternalResourceModal title="Luxury Assets and Email Banners">
						<LuxuryAssetsEmailBanners />
					</InternalResourceModal>
				)
			case '/resources/listing-generation':
				return (
					<InternalResourceModal title="AI-Powered Real Estate Listing Generator">
						<ListingGenerationRoute />
					</InternalResourceModal>
				)
			case '/resources/toolkit-cma':
				return (
					<InternalResourceModal title="Toolkit CMA">
						<ToolkitCMARoute />
					</InternalResourceModal>
				)
			case '/resources/toolkit-cma-sign-on':
				return (
					<InternalResourceModal title="Toolkit CMA Sign-On">
						<ToolkitCMASignOnRoute />
					</InternalResourceModal>
				)
			case '/resources/referral-quick-add':
				return (
					<InternalResourceModal title="Referral Quick Add">
						<QrCodeRoute />
					</InternalResourceModal>
				)

			case '/resources/real-estate-leads':
				return (
					<InternalResourceModal title="Direct Mail Marketing">
						<RealEstateLeadsRoute />
					</InternalResourceModal>
				)

			case '/resources/referral-qr-pdf-template':
				return (
					<InternalResourceModal title="Referral PDF with QR Code">
						<QRCodePDFRoute />
					</InternalResourceModal>
				)

			default:
				return <div>Resource not found</div>
		}
	}

	return (
		<>
			{newsFeedStoryId && <NewsFeedStoryModal storyId={parseFloat(newsFeedStoryId)} />}

			{userProfileId && <UserProfileModal userId={parseFloat(userProfileId)} />}
			{resourceId && <>{getResourceRoute(resourceId)}</>}
		</>
	)
}
