import { EventCalendar } from '@components/event-calendar/event-calendar'
import { GetSupportSection } from '@components/get-support/get-support'
import { HubHeader } from '@components/hub-header/hub-header'
import { HubHeaderV2 } from '@components/hub-header-v2/hub-header-v2'
import { Paper } from '@components/paper/paper'
import { ReferralLeaderboard } from '@components/referral-leaderboard/referral-contest-hub-section'
import { ResourceCardLoader } from '@components/resource-card/resource-card-loader'
import { ResourceCardList } from '@components/resource-card-list/resource-card-list'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useLoaderData, useLocation } from 'react-router-dom'
import { AdvertisementAPI } from 'src/services/advertising/advertisement.api'
import { AdvertisementImageAPI } from 'src/services/advertising/advertisement-image.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Calendar } from '../../services/calendar/calendar.types'
import { CalendarEventAPI } from '../../services/calendar/calendar-event.api'
import { DateService } from '../../services/date.service'
import { FAQAPI } from '../../services/faq/faq.api'
import { FAQ, FAQSearchParams } from '../../services/faq/faq.types'
import { Hub } from '../../services/hubs/hubs.types'
import { ResourcesAPI } from '../../services/resources/resources.api'
import { LokationResource, LokationResourceSearchParams } from '../../services/resources/resources.types'
import { DomainTheme } from '../../services/theme/theme.types'
import { EndUserProps } from '../../services/user/user.types'
import { useItemAdminHook } from '../administration/use-item-admin-hook'
import { HubHeaderAd } from './hub__header-ad'
import { HubFAQ } from './hub-faq'
import { HubFAQLoader } from './hub-faq-loader'

type ConnectedProps = {
	user: EndUserProps | null
	theme: DomainTheme.CompleteTheme
}

function HubRoutePrototype(props: ConnectedProps) {
	const location = useLocation()
	const hubProps = useLoaderData() as Hub | null
	const hubLoadFailed = hubProps === null
	const [isFAQLoadPending, setIsFAQLoadPending] = useState(true)
	const [isResourceLoadPending, setIsResourceLoadPending] = useState(true)
	const [isCalendarLoadPending, setIsCalendarLoadPending] = useState(true)
	const [sponsoredResourceToShow, setSponsoredResourceToShow] = useState<LokationResource | null>(null)
	const [sponsoredResourceAd, setSponsoredResourceAd] = useState<AdvertisingTypes.FindAdsResponse | null>(null)
	const userSuperAdmin = props.user?.roles.some((role) => role.roleId === 4)

	/** Load an ad */
	useEffect(() => {
		const currentHubId = hubProps ? [hubProps.hubId] : []

		AdvertisementAPI.findAds('SPONSORED_RESOURCE', currentHubId, 'DESKTOP').then((res) => {
			if (res.data) {
				setSponsoredResourceAd(res.data)
				formatAdAsResource(res.data).then((formattedResource) => {
					setSponsoredResourceToShow(formattedResource)

					if (res.data && res.data.advertisementImage) {
						AnalyticsService().pushEvent({
							event_category: 'navigation',
							event_label: 'ad_view',
							value: {
								account_id: res.data.advertisement.accountId,
								ad_id: res.data.advertisement.advertisementId,
								ad_name: res.data.advertisement.name,
								ad_image_id: res.data.advertisementImage.advertisementImageId,
								ad_target_url: res.data.advertisementImage.link,
								ad_type: 'sponsored_resource',
							},
						})
					}
				})
			}
		})
	}, [hubProps?.hubId])

	useEffect(() => {
		if (!hubProps) {
			return
		}

		AnalyticsService().pushEvent({
			event_category: 'navigation',
			event_label: 'open_hub',
			value: {
				hub_id: hubProps.hubId,
				hub_name: hubProps.title,
			},
		})
	}, [hubProps?.hubId])

	/** Get hub info */
	useEffect(() => {
		if (hubProps) {
			resourceAdminHook.reset()
			faqAdminHook.reset()
		}
	}, [location.pathname])

	function getLicensedStateIds(): number[] | undefined {
		const userProps = props.user
		if (userProps && userProps.licenseInformation.length > 0) {
			return userProps.licenseInformation.map((license) => {
				return license.licensedState.licensedStateId
			})
		} else {
			return undefined
		}
	}

	const resourceAdminHook = useItemAdminHook<LokationResource, LokationResourceSearchParams>({
		getItemsFunction: (page, size, sort, hubSearchParams) => {
			return ResourcesAPI.findResources(
				{ page, size, sort },
				{
					tags: hubSearchParams.tags,
					licensedStateIds: hubSearchParams.licensedStateIds,
					specialtyIds: hubSearchParams.specialtyIds,
				},
			)
		},
		searchParams: getResourceSearchParams(),
		itemIdKey: 'resourceId',
		itemTitleKey: 'title',
		pageSize: 40,
		setIsPending: (state) => {
			setIsResourceLoadPending(state)
		},
		sortParams: [
			{ property: 'starred', direction: 'desc' },
			{ property: 'title', direction: 'asc' },
		],
	})

	function getResourceSearchParams(): LokationResourceSearchParams {
		const searchParams: LokationResourceSearchParams = {}

		searchParams['tags'] = hubProps
			? hubProps.tags
					.map((tag) => tag.tagId)
					.sort((a, b) => {
						return a > b ? 1 : -1
					})
			: []
		searchParams['licensedStateIds'] = getLicensedStateIds()
		searchParams['specialtyIds'] = props.user
			? props.user.specialties.map((specialty) => specialty.specialtyId)
			: []

		/** This is done to guarantee that we are sending something in the 'specialtyIds' param. If the param were empty, the results would not filter out any resources
		 * according to their specialties
		 */
		if (searchParams.specialtyIds.length === 0) {
			searchParams.specialtyIds.push(-1)
		}

		return searchParams
	}

	const faqAdminHook = useItemAdminHook<FAQ, FAQSearchParams>({
		getItemsFunction: (page, size, sort, hubSearchParams) => {
			return FAQAPI.findFAQs(
				{ page, size, sort },
				{ tags: hubSearchParams.tags, licensedStateIds: hubSearchParams.licensedStateIds },
			)
		},
		searchParams: getFAQSearchParams(),
		itemIdKey: 'faqId',
		itemTitleKey: 'question',
		setIsPending: (state) => {
			setIsFAQLoadPending(state)
		},
	})

	const calendarAdminHook = useItemAdminHook<Calendar.Event, Calendar.EventSearchParams>({
		getItemsFunction: (page, size, sort, hubSearchParams) => {
			return CalendarEventAPI.findCalendarEvents(
				{ page, size, sort },
				{
					tags: hubSearchParams.tags,
					startDate: hubSearchParams.startDate,
					endDate: hubSearchParams.endDate,
					licensedStateIds: hubSearchParams.licensedStateIds,
				},
			)
		},
		searchParams: {
			tags: hubProps
				? hubProps.tags
						.map((tag) => tag.tagId)
						.sort((a, b) => {
							return a > b ? 1 : -1
						})
				: [],
			startDate: DateService.getCurrentDateString(0),
			endDate: DateService.getCurrentDateString(2),
			licensedStateIds: getLicensedStateIdForCurrentUser(),
		},
		itemIdKey: 'calendarEventId',
		itemTitleKey: 'title',
		setIsPending: (state) => {
			setIsCalendarLoadPending(state)
		},
	})

	const futureEvents = calendarAdminHook.items.filter((event) => {
		const eventEndDate = new Date(event.endDate)
		const filterDate = new Date(DateService.getCurrentDateString(0))

		return eventEndDate >= filterDate
	})

	function getLicensedStateIdForCurrentUser(): number[] {
		if (!props.user) {
			return []
		}

		return props.user.licenseInformation.map((license) => {
			return license.licensedState.licensedStateId
		})
	}

	function getFAQSearchParams(): FAQSearchParams {
		const faqSearchParams: FAQSearchParams = {}

		faqSearchParams['tags'] = hubProps
			? hubProps.tags
					.map((tag) => tag.tagId)
					.sort((a, b) => {
						return a > b ? 1 : -1
					})
			: []
		faqSearchParams['licensedStateIds'] = props.user
			? props.user.licenseInformation.map((license) => license.licensedState.licensedStateId)
			: []

		return faqSearchParams
	}

	function formatAdAsResource(ad: AdvertisingTypes.FindAdsResponse): Promise<LokationResource | null> {
		return new Promise((resolve) => {
			if (ad.advertisement.placement === 'HUB_HEADER' || !ad.advertisementImage) {
				resolve(null)
				return
			}

			const desciption = ad.advertisement.sponsoredResourceAdvertisement.description
			const title = ad.advertisement.sponsoredResourceAdvertisement.resourceTitle
			let url = ad.advertisementImage.link

			if (!ad.advertisementImage.link.includes(`http`)) {
				url = `http://${ad.advertisementImage.link}`
			}

			AdvertisementImageAPI.getAdvertisementImage(
				ad.advertisement.advertisementId,
				ad.advertisementImage.advertisementImageId,
			).then((res) => {
				const sponsoredResource: LokationResource = {
					createdTimestamp: String(Date.now()),
					description: desciption ? desciption : '',
					bgImage: res.data,
					tags: [],
					title: title ? title : '',
					licensedStates: [],
					resourceId: 0,
					stateAgnostic: true,
					specialties: [],
					specialtyAgnostic: true,
					url,
					starred: false,
					hasResourcePicture: false,
					published: true,
				}

				resolve(sponsoredResource)
			})
		})
	}

	if (hubLoadFailed) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2 omitPadding={['top']}>
					<HubHeader
						title={'Hub not found'}
						description={''}
						imageUrl={''}
						backgroundColor={props.theme.colorAccent}
						bodyContent=""
					/>
				</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	function getSupport(): React.ReactNode {
		return (
			<>
				<div className="mb-10">
					<h4 className="color__accent mr-20">Get Support</h4>
				</div>
				<Paper elevation={1} className="p-20 mb-40">
					<GetSupportSection />
				</Paper>
			</>
		)
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 omitPadding={['top']} id="hub__route-body">
				<HubHeaderV2 hub={hubProps} />
				<HubHeader
					title={hubProps.title}
					description={hubProps.description}
					imageUrl={hubProps.headerBgImage}
					backgroundColor={hubProps.color}
					bodyContent={hubProps.body}
					isPublished={hubProps.published}
				/>
				{hubProps.slug === 'connect-and-collect' && <ReferralLeaderboard />}

				<div className="flex-lg">
					<div className="hub__column__main">
						{<HubHeaderAd hub={hubProps} />}
						<div dangerouslySetInnerHTML={{ __html: hubProps.description }} className="mb-20" />
						<div dangerouslySetInnerHTML={{ __html: hubProps.body }} className="mb-40" />
						{isResourceLoadPending && (
							<>
								<ResourceCardLoader className="mr-10 mb-10" />
								<ResourceCardLoader className="mr-10 mb-10" />
								<ResourceCardLoader className="mr-10 mb-10" />
							</>
						)}
						{resourceAdminHook.items.length > 0 && (
							<div id="resources">
								<InfiniteScroll
									dataLength={resourceAdminHook.items.length}
									next={resourceAdminHook.loadNextPageOfItems}
									hasMore={resourceAdminHook.hasMoreItems}
									scrollableTarget={`hub__route-body`}
									loader={
										<>
											<ResourceCardLoader className="mr-10 mb-10" />
											<ResourceCardLoader className="mr-10 mb-10" />
											<ResourceCardLoader className="mr-10 mb-10" />
										</>
									}
								>
									<ResourceCardList
										resources={
											sponsoredResourceToShow
												? [
														{
															...sponsoredResourceToShow,
															isSponsored: true,
															onClick: () => {
																if (
																	!sponsoredResourceAd ||
																	!sponsoredResourceAd.advertisementImage
																) {
																	return
																}
																AnalyticsService().pushEvent({
																	event_category: 'navigation',
																	event_label: 'ad_click',
																	value: {
																		account_id:
																			sponsoredResourceAd.advertisement.accountId,
																		ad_id: sponsoredResourceAd.advertisement
																			.advertisementId,
																		ad_name: sponsoredResourceAd.advertisement.name,
																		ad_image_id:
																			sponsoredResourceAd.advertisementImage
																				.advertisementImageId,
																		ad_target_url:
																			sponsoredResourceAd.advertisementImage.link,
																		ad_type: 'sponsored_resource',
																	},
																})
															},
														},
														...resourceAdminHook.items,
													]
												: resourceAdminHook.items
										}
										cardStyle={'LARGE'}
										title={`Resources`}
										groupByTag={hubProps.groupByTag}
										includeStyleToggle={true}
										id={`generic-hub`}
										showHiddenResources={userSuperAdmin}
									/>
								</InfiniteScroll>
							</div>
						)}
					</div>
					<div className="hub__column__sidebar">
						{getSupport()}
						{hubProps.sidebar && (
							<Paper className="mb-20 p-20">
								<div dangerouslySetInnerHTML={{ __html: hubProps.sidebar }} />
							</Paper>
						)}
						{futureEvents.length > 0 && (
							<>
								<h5 className="mb-10">Upcoming Events</h5>
								<Paper className="mb-20">
									{isCalendarLoadPending && (
										<>
											<HubFAQLoader className="mb-10" />
											<HubFAQLoader className="mb-10" />
										</>
									)}
									{!isCalendarLoadPending && (
										<EventCalendar
											initialView="listMonth"
											style={{ height: '400px' }}
											calendarId={'all'}
											filterByTags={hubProps.tags}
											filterByLicensedStates={getLicensedStateIdForCurrentUser()}
											onEventClick="open-details"
										/>
									)}
								</Paper>
							</>
						)}
						{faqAdminHook.items.length > 0 && (
							<>
								<h5 className="mb-10">Frequently asked questions</h5>
								<Paper className="p-20 mb-20">
									{isFAQLoadPending && (
										<>
											<HubFAQLoader className="mb-10" />
											<HubFAQLoader className="mb-10" />
										</>
									)}
									<InfiniteScroll
										dataLength={faqAdminHook.items.length}
										next={faqAdminHook.loadNextPageOfItems}
										hasMore={faqAdminHook.hasMoreItems}
										loader={
											<>
												<HubFAQLoader className="mb-10" />
												<HubFAQLoader className="mb-10" />
											</>
										}
									>
										{faqAdminHook.items.map((faq) => {
											return <HubFAQ {...faq} key={faq.faqId} />
										})}
									</InfiniteScroll>
								</Paper>
							</>
						)}
					</div>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
		theme: state.theme,
		ui: state.ui,
	}
}

export const HubRoute = connect(mapStateToProps)(HubRoutePrototype)
