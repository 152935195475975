import { currentSessionSlice } from '@redux/reducers/current-session-reducer'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState, store } from '@redux/store'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ToastService } from '../toast/toast.service'
import { TokenProps, TokenService } from '../token/token.service'
import { EndUserProps } from '../user/user.types'

export function useUserAuthentication(props: { token: TokenProps | null }) {
	const user = useSelector((state: RootState) => state.user)
	const [userIsLoggedIn, setUserIsLoggedIn] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (user && props.token) {
			// User is already logged in
			setUserIsLoggedIn(true)
			console.info('User is already logged in')
		} else if (!props.token) {
			console.log('No token')
			// User is not logged in
			setUserIsLoggedIn(false)
			console.info('User is not logged in')
		} else {
			// Attempt to log in the user
			const userId = props.token.id
			store
				.dispatch(getUserProps({ userId }))
				.then((res) => {
					if (res.meta.requestStatus === 'rejected') {
						TokenService().clear()
						store.dispatch(currentSessionSlice.actions.clearToken())
						navigate('/')
					}

					const payload = res.payload as EndUserProps
					if (payload.roles.some((role) => role.roleType === 'USER')) {
						navigate('/hubs')
					} else if (payload.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
						navigate('/registration')
					}
				})
				.catch((err) => {
					console.info(`User authentication failed`)
					console.log(err)
					ToastService().create({ type: 'ERROR', body: `Please log in to access this page` })
					navigate('/')
				})
		}
	}, [user, props.token])

	return {
		userIsLoggedIn,
	}
}
