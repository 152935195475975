import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function MessagesRouteNoSelectedChatPlaceholder() {
	return (
		<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace height__100pct">
			<FontAwesomeIcon icon={['far', 'message']} size="4x" style={{ opacity: '0.75' }} className="mb-20" />
			<h6>Select a message</h6>
		</div>
	)
}
