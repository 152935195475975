import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { LicensedState } from '../../../../services/licensed-states/licenses-states.types'
import { usStatesList } from '../../../../services/state-list'
import { Utilities } from '../../../../services/utilities.service'
import { useFormValidation } from '../../../../services/validation/use-form-validation'
import { Registration } from '../../registration.types'
import { useRegistration, useRegistrationDispatch } from '../../state/registration__state'

function RegistrationPersonalInfoPrototype(props: { licensedStates: LicensedState[] }) {
	/** ================================================= */
	/** Hooks */
	const regState = useRegistration()
	const regDispatch = useRegistrationDispatch()
	const { getField, isFormValid } = useFormValidation<keyof Registration.Form>({
		fields: [
			{
				name: 'firstName',
				validation: (value) => {
					return validateSimpleString(value)
				},
				message: 'Cannot be empty',
				value: regState.fields.firstName,
			},
			{
				name: 'lastName',
				validation: (value) => {
					return validateSimpleString(value)
				},
				message: 'Cannot be empty',
				value: regState.fields.lastName,
			},
			{
				name: 'email',
				validation: (value) => {
					return validateEmail(value)
				},
				message: 'Must be a valid email address',
				value: regState.fields.email,
			},
			{
				name: 'phone',
				validation: (value) => {
					return validatePhone(value)
				},
				message: 'Must be a valid phone number',
				value: regState.fields.phone,
			},
			{
				name: 'address1',
				validation: (value) => {
					return validateSimpleString(value)
				},
				message: 'Cannot be empty',
				value: regState.fields.address1,
			},
			{
				name: 'city',
				validation: (value) => {
					return validateSimpleString(value)
				},
				message: 'Cannot be empty',
				value: regState.fields.city,
			},
			{
				name: 'state',
				validation: (value) => {
					return value.length > 0
				},
				message: 'A state must be selected',
				value: regState.fields.state,
			},
			{
				name: 'zip',
				validation: (value) => {
					return validateZip(value)
				},
				message: 'Cannot be empty',
				value: regState.fields.zip,
			},
			{
				name: 'password',
				validation: (value) => {
					return validateOriginalPassword(value)
				},
				message: 'Must contain a password',
				value: regState.fields.password,
			},
			{
				name: 'repeatPassword',
				validation: (value) => {
					return validateRepeatPassword(value.original, value.new)
				},
				message: 'This does not match password',
				value: { original: regState.fields.password, new: regState.fields.repeatPassword },
			},
			{
				name: 'notificationsEmail',
				validation: (value) => {
					return value === true
				},
				message: 'You must consent to receive notifications',
				value: regState.fields.notificationsEmail,
			},
		],
	})

	/** ================================================= */
	/** Props and State */
	const [showPassword, setShowPassword] = useState(false)
	const [showRepeatPassword, setShowRepeatPassword] = useState(false)

	/** ================================================= */
	/** Effects */

	/** Whenever a user interacts with a field, state 'invalidFields' is updated. Here, we push the overall validation of the tab to the registration route */
	useEffect(() => {
		if (isFormValid) {
			regDispatch({ type: 'set-validation-state', payload: true })
		} else {
			regDispatch({ type: 'set-validation-state', payload: false })
		}
	}, [isFormValid])

	/** ================================================= */
	/** Methods */

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	function validatePhone(value: number | null): boolean {
		if (value === null) {
			return false
		}
		return Utilities.isPhoneNumberValid(value, 'us')
	}

	function validateEmail(value: string): boolean {
		return Utilities.isEmailValid(value)
	}

	function validateOriginalPassword(value: string): boolean {
		return value.length > 0
	}

	function validateRepeatPassword(originalPassword: string, newPassword: string): boolean {
		return newPassword === originalPassword && newPassword.length > 0
	}

	function getMailingStateOptions(): DropdownOptionProps<string>[] {
		return usStatesList.map((state) => {
			return {
				value: state.abbreviation,
				label: state.name,
			}
		})
	}

	function validateZip(value: string | null): boolean {
		if (value === null) {
			return false
		}
		return Utilities.isZipValid(value)
	}

	/** ================================================= */
	/** Render Component */

	return (
		<div className="registration__field-wrapper">
			<div className="col-xs-12" style={{ gridColumn: '1 / span 2' }}>
				<h4>Personal Info</h4>
			</div>

			<TextInput
				width={`100%`}
				label="First name"
				placeholder="First name"
				value={regState.fields.firstName}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'firstName', value: updatedValue }] })
				}}
				dataType="text"
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('firstName').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},

					message: getField('firstName').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="given-name"
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Middle name or initial"
				placeholder="Middle name or initial"
				value={regState.fields.middleName}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'middleName', value: updatedValue }] })
				}}
				autoComplete="additional-name"
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Last name"
				placeholder="Last name"
				value={regState.fields.lastName}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'lastName', value: updatedValue }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('lastName').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('lastName').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="family-name"
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Email"
				placeholder="Email"
				value={regState.fields.email}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'email', value: updatedValue }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('email').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('email').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="email"
			/>

			<PhoneInput
				width={`100%`}
				dataType={'number'}
				label="Phone number"
				placeholder="Phone number"
				value={regState.fields.phone}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'phone', value: updatedValue }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('phone').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('phone').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="tel"
			/>

			<div className="col-xs-12 mt-40" style={{ gridColumn: '1 / span 2' }}>
				<h4>Address</h4>
			</div>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Address 1"
				placeholder="Address"
				value={regState.fields.address1}
				onChange={(updateField) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'address1', value: updateField }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('address1').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('address1').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="street-address"
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Address 2"
				placeholder="Address"
				value={regState.fields.address2 ?? ''}
				onChange={(updateField) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'address2', value: updateField }] })
				}}
				autoComplete={'street-address'}
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="City"
				placeholder="City"
				value={regState.fields.city}
				onChange={(updateField) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'city', value: updateField }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('city').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('city').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="address-level2"
			/>

			<Dropdown<string>
				value={regState.fields.state ? [regState.fields.state] : []}
				label="State"
				searchable={true}
				options={getMailingStateOptions()}
				onSelect={(selectedValues) => {
					if (selectedValues.length > 0) {
						regDispatch({ type: 'update-fields', payload: [{ key: 'state', value: selectedValues[0] }] })
					}
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('state').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('state').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="address-level1"
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="ZIP Code"
				placeholder="ZIP Code"
				align="left"
				value={regState.fields.zip ? regState.fields.zip : ''}
				onChange={(updateField) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'zip', value: updateField }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('zip').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('zip').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete="postal-code"
			/>

			<div className="col-xs-12 mt-40" style={{ gridColumn: '1 / span 2' }}>
				<h4>Password</h4>
			</div>

			<TextInput
				width={`100%`}
				dataType={'text'}
				label="Password"
				placeholder="Password"
				value={regState.fields.password}
				type={showPassword ? 'text' : 'password'}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'password', value: updatedValue }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('password').validation(value),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('password').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				autoComplete={'new-password'}
				icon={{
					name: !showPassword ? 'eye' : 'eye-slash',
					onClick: () => {
						setShowPassword(!showPassword)
					},
				}}
			/>

			<TextInput
				width={`100%`}
				dataType={'text'}
				type={showRepeatPassword ? 'text' : 'password'}
				label="Confirm password"
				placeholder="Confirm password"
				value={regState.fields.repeatPassword}
				onChange={(updatedValue) => {
					regDispatch({ type: 'update-fields', payload: [{ key: 'repeatPassword', value: updatedValue }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('repeatPassword').validation({
								original: regState.fields.password,
								new: value,
							}),
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},
					message: getField('repeatPassword').message,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
				icon={{
					name: !showRepeatPassword ? 'eye' : 'eye-slash',
					onClick: () => {
						setShowRepeatPassword(!showRepeatPassword)
					},
				}}
			/>

			<div className="flex flex-column flex-alignItems-start mt-40" style={{ gridColumn: '1 / span 2' }}>
				<Checkbox
					checked={regState.fields.notificationsEmail}
					className="mr-20"
					onChange={(updatedValue) => {
						regDispatch({
							type: 'update-fields',
							payload: [
								{ key: 'notificationsEmail', value: updatedValue },
								{ key: 'notificationsText', value: updatedValue },
							],
						})
					}}
					validation={{
						isValid: (value, isUserInteracted) => {
							return {
								isValid: getField('notificationsEmail').validation(value),
								showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
							}
						},
						message: getField('notificationsEmail').message,
					}}
					forceRevalidation={regState.hasUserClickedNavButton}
				>
					You consent to receive SMS notifications and automated transactional messages. You can review our
					Terms and Privacy Policy. Standard text and data rates may apply. To unsubscribe, simply reply with
					STOP.
				</Checkbox>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const RegistrationPersonalInfo = connect(mapStateToProps)(RegistrationPersonalInfoPrototype)
