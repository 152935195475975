import { AgentLicenseCard } from '@components/agent-license-card/agent-license-card'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { LoKationButton } from '../../../../components/button/button'
import { Paper } from '../../../../components/paper/paper'
import { UserService } from '../../../../services/user/user.service'
import { AgentLicense, EndUserProps } from '../../../../services/user/user.types'
import { useUserProfile, useUserProfileDispatch } from '../state/user-profile__state'
import { AddNewAgentLicenseModal } from './add-new-license/add-new-agent-license'

function AgentLicenses(props: { userToEdit: EndUserProps; currentUser: EndUserProps | null }) {
	const userProfileState = useUserProfile()
	const userProfileDispatch = useUserProfileDispatch()
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const [addLicenseModal, setAddLicenseModal] = useState<'ADD' | 'UPDATE' | null>(null)
	const [licenseToEdit, setLicenseToEdit] = useState<number | null>(null)
	const [modifiedUserState, setModifiedUserState] = useState<AgentLicense.CompleteEntry[]>([])

	/** ==================================== */
	/** Effects */

	useEffect(() => {
		if (!_.isEqual(props.userToEdit.licenseInformation, modifiedUserState)) {
			setModifiedUserState(props.userToEdit.licenseInformation)
		}
	}, [props.userToEdit.licenseInformation])

	/** ==================================== */
	/** Methods */

	function dismissModal() {
		setAddLicenseModal(null)
	}

	function sortLicenses(): AgentLicense.Entry[] {
		return _.cloneDeep(userProfileState).agentLicenses.sort((a, b) => {
			if (!a.licenseInformationId || !b.licenseInformationId) {
				return -1
			}

			return a.licenseInformationId > b.licenseInformationId ? 1 : -1
		})
	}

	/** ==================================== */
	/** Render Component */

	return (
		<>
			<h2 className="mb-10">Agent Licenses</h2>

			<div className="flex flex-wrap mt-10">
				{userProfileState.agentLicenses.length === 0 && (
					<div className="mt-10" style={{ width: `100%` }}>
						<em>No licenses have been added</em>
					</div>
				)}
				{userProfileState.agentLicenses.length > 0 && (
					<>
						{sortLicenses().map((license) => {
							return (
								<Paper className="p-20 mb-20 col-xs-12" style={{ maxWidth: `800px` }}>
									<AgentLicenseCard
										key={license.licenseInformationId}
										license={license}
										canUserEdit={canUserEdit}
										userToEdit={props.userToEdit}
										onUpdateLicense={(license) => {
											userProfileDispatch({
												type: 'update-license',
												payload: { licenseId: license.licenseInformationId, license },
											})
										}}
										onDeleteLicense={() => {
											userProfileDispatch({
												type: 'remove-license',
												payload: license.licenseInformationId,
											})
										}}
										userEditPlan={(license: AgentLicense.CompleteEntry) => {
											setLicenseToEdit(license.licenseInformationId)
											setAddLicenseModal('UPDATE')
										}}
									/>
								</Paper>
							)
						})}
					</>
				)}
			</div>

			{/* <LoKationButton icon={'plus'} size="sm" variant={'outlined'} disabled={!canUserEdit} label="Add Additional License" className='mt-10' onClick={() => {
                    userProfileDispatch({ type: 'add-license', payload: null })
                }} /> */}

			<LoKationButton
				icon={'plus'}
				size="sm"
				variant={'outlined'}
				label="Add Additional License"
				className="mt-10 mb-20"
				onClick={() => {
					setAddLicenseModal('ADD')
				}}
			/>

			{addLicenseModal === 'ADD' && (
				<>
					<AddNewAgentLicenseModal
						dismissModal={dismissModal}
						canUserEdit={canUserEdit}
						userToEdit={props.userToEdit}
						currentUser={props.currentUser}
						existingLicenses={userProfileState.agentLicenses}
						onAddLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService().create({
								type: 'SUCCESS',
								body: `New license has been added`,
							})
							// setAddLicenseModal(null)
						}}
						onUpdateLicense={(license) => {
							/** EMPTY */
							// setAddLicenseModal(null)
						}}
					/>
				</>
			)}

			{addLicenseModal === 'UPDATE' && licenseToEdit && (
				<>
					<AddNewAgentLicenseModal
						dismissModal={dismissModal}
						canUserEdit={canUserEdit}
						userToEdit={props.userToEdit}
						currentUser={props.currentUser}
						existingLicenses={userProfileState.agentLicenses}
						onAddLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService().create({
								type: 'SUCCESS',
								body: `Your license has been updated`,
							})
							// setAddLicenseModal(null)
						}}
						onUpdateLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService().create({
								type: 'SUCCESS',
								body: `Your license has been updated`,
							})
							// setAddLicenseModal(null)
						}}
						licenseToEdit={licenseToEdit}
					/>
				</>
			)}
		</>
	)
}

export default AgentLicenses
