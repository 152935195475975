import './user-dropdown-menu.scss'

import { useAppDispatch } from '@redux/hooks'
import { currentSessionSlice } from '@redux/reducers/current-session-reducer'
import { userSlice } from '@redux/reducers/user-reducer'
import { RootState } from '@redux/store'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { QrCodeQuickAdd } from 'src/modals/qr-quick-add/qr-quick-add__modal'
import { ToastService } from 'src/services/toast/toast.service'

import FeedbackModal from '../../modals/feedback/feedback__modal'
import { settingsRoutePath } from '../../routes/routes.constant'
import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { TokenService } from '../../services/token/token.service'
import { UserAPI } from '../../services/user/user.api'
import { UserService } from '../../services/user/user.service'
import { EndUserProps } from '../../services/user/user.types'
import { Avatar } from '../avatar/avatar'
import { Popover } from '../popover/popover'
import { UserDropdownMenuTypes } from './user-dropdown-menu.types'
import { UserDropdownMenuOption } from './user-dropdown-menu__option'

interface ConnectedProps {
	currentUser: EndUserProps | null
	userProfilePhoto: string
}

function UserDropdownMenuPrototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const [userMenuOpen, setUserMenuOpen] = useState(false)
	const userMenuBtn = useRef<HTMLDivElement>(null)
	const [showFeedbackModal, setShowFeedbackModal] = useState(false)
	const [showQrCode, setShowQrCode] = useState(false)

	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: userMenuOpen,
		setIsPopoverOpen: setUserMenuOpen,
		delay: 0,
	})

	/** =================================== */
	/** Methods */

	function getUserMenuOptions(): UserDropdownMenuTypes.Option[] {
		const menuOptions: UserDropdownMenuTypes.Option[] = []

		if (props.currentUser) {
			menuOptions.push({
				label: 'User Profile',
				desc: `Your licenses, social media links, subscriptions, notification preferences`,
				onClick: () => {
					setUserMenuOpen(false)
					navigate(`${settingsRoutePath}/users/${props.currentUser?.endUserId}`)
				},
				icon: 'person',
			})

			if (UserService.isUserAdmin(props.currentUser)) {
				menuOptions.push({
					label: 'Administration',
					// desc: `Manage content in hubs, create stories in the news feed, send messages to users`,
					desc: `Manage content in hubs, create resources, create stories in the news feed`,
					onClick: () => {
						setUserMenuOpen(false)
						navigate(`${settingsRoutePath}`)
					},
					icon: 'gear',
				})
			}
		}

		menuOptions.push({
			prependDivider: true,
			label: 'News Feed',
			icon: 'newspaper',
			onClick: () => {
				setUserMenuOpen(false)
				navigate(`/news-feed`)
			},
		})

		menuOptions.push({
			label: 'FAQs',
			icon: 'question-circle',
			onClick: () => {
				setUserMenuOpen(false)
				navigate(`/faqs`)
			},
		})

		menuOptions.push({
			label: 'Help and Support',
			icon: 'message-text',
			onClick: () => {
				setUserMenuOpen(false)
				navigate(`/support`)
			},
		})
		menuOptions.push({
			label: 'Feedback',
			icon: 'megaphone',
			onClick: () => {
				setUserMenuOpen(false)
				setShowFeedbackModal(true)
			},
		})

		menuOptions.push({
			label: 'Referral Quick Add',
			icon: 'qrcode',
			onClick: () => {
				setUserMenuOpen(false)
				setShowQrCode(true)
			},
		})

		menuOptions.push({
			prependDivider: true,
			label: 'Log Out',
			icon: 'door-open',
			onClick: () => {
				UserAPI.logout().then(() => {
					dispatch(userSlice.actions.logout())
					TokenService().clear()
					dispatch(currentSessionSlice.actions.clearToken())
					navigate(`/`)
					ToastService().create({ type: 'SUCCESS', body: `You have been logged out` })
				})
			},
		})
		return menuOptions
	}

	/** =================================== */
	/** Render Component */

	return (
		<>
			<div ref={userMenuBtn}>
				<Avatar
					size={'md'}
					className="ml-10"
					tooltip="Account"
					tooltipPlacement="bottom"
					id="desktop-app-header__user__btn"
					onClick={() => {
						setUserMenuOpen(true)
					}}
					imageUrl={props.userProfilePhoto}
					fallbackCharacter={props.currentUser ? props.currentUser.firstName[0] : 'X'}
				/>
			</div>
			{userMenuBtn.current && (
				<Popover
					{...popoverProps}
					refElement={userMenuBtn.current}
					escapeOnMouseOut={false}
					setShowPopover={(newState) => {
						setUserMenuOpen(newState)
					}}
					options={{}}
				>
					<div className="context-menu__popover user-dropdown-menu__wrapper">
						<div className="flex flex-alignItems-center p-10">
							<Avatar
								size={'lg'}
								className="mr-10"
								imageUrl={props.userProfilePhoto}
								fallbackCharacter={props.currentUser ? props.currentUser.firstName[0] : 'X'}
							/>
							<h6>
								{props.currentUser?.firstName} {props.currentUser?.lastName}
							</h6>
						</div>

						{/* <UserDropdownMenuAlert
							icon="list"
							desc="You have N tasks remaining to complete onboarding"
							onClick={() => {
								dispatch(uiSlice.actions.setShowUserOnboardingModal(true))
								setUserMenuOpen(false)
							}}
						/> */}

						<div>
							{getUserMenuOptions().map((option) => {
								return <UserDropdownMenuOption {...option} key={option.label} />
							})}
						</div>
					</div>
				</Popover>
			)}

			{showFeedbackModal === true && <FeedbackModal dismissModal={() => setShowFeedbackModal(false)} />}
			{showQrCode === true && <QrCodeQuickAdd dismissModal={() => setShowQrCode(false)} />}
		</>
	)
}

function mapStateToProps(state: RootState, ownProps: {}) {
	return {
		currentUser: state.user,
		userProfilePhoto: state.userProfilePhoto,
	}
}

export const UserDropdownMenu = connect(mapStateToProps)(UserDropdownMenuPrototype)
