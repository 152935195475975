import { LoKationButton } from '@components/button/button'
import { ContextMenu, ContextMenuOption } from '@components/context-menu/context-menu'
import { useRef, useState } from 'react'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'

import { apiVersionURL, RequestBaseURL } from '../../../../services/axios/http-common.service'
import AppleLogo from './brand-img/apple_logo.svg'
import GoogleCalLogo from './brand-img/google_cal_logo.svg'

interface DownloadCalendarButtonProps {
	selectedCalendarId: number | null
}

export function DownloadCalendarButton(props: DownloadCalendarButtonProps) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const fieldPropsBtnRef = useRef<HTMLDivElement>(null)
	const googleCalUrl = 'https://calendar.google.com/calendar/u/0/r/settings/addbyurl?cid='

	function getOptions(): ContextMenuOption[] {
		const options: ContextMenuOption[] = []
		let calUrl: string
		if (props.selectedCalendarId == null) {
			calUrl = `${RequestBaseURL}${apiVersionURL}/calendars/calendar.ics`
		} else {
			calUrl = `${RequestBaseURL}${apiVersionURL}/calendars/${props.selectedCalendarId}/calendar.ics`
		}

		const handleCalendarClick = (url: string, calendarType: string) => {
			const webCalFormattedUrl = url.replace('http://', 'webcal://').replace('https://', 'webcal://')
			switch (calendarType) {
				case 'Apple':
					window.open(webCalFormattedUrl, '_blank')
					break
				case 'Google':
					window.open(googleCalUrl + webCalFormattedUrl, '_blank')
					break
				case 'default':
					CopyToClipboard(calUrl, 'STRING', 'Calendar URL copied to your clipboard!')
					break
			}
		}

		options.push({
			label: 'Apple',
			image: AppleLogo,
			onClick: () => handleCalendarClick(`${calUrl}`, 'Apple'),
		})

		options.push({
			label: 'Google Calendar',
			image: GoogleCalLogo,
			onClick: () => handleCalendarClick(`${calUrl}`, 'Google'),
		})

		options.push({
			label: 'Copy link to clipboard',
			icon: 'copy',
			onClick: () => handleCalendarClick(`${calUrl}`, 'default'),
		})

		return options
	}

	return (
		<>
			<div ref={fieldPropsBtnRef} className="mt-5">
				<LoKationButton
					variant="text"
					size="sm"
					label="Sync with your calendar"
					onClick={() => {
						setIsDropdownOpen(true)
					}}
				/>
			</div>
			{fieldPropsBtnRef.current && (
				<ContextMenu
					header={``}
					isOpen={isDropdownOpen}
					options={getOptions()}
					referenceElement={fieldPropsBtnRef.current}
					onDismiss={() => {
						setIsDropdownOpen(false)
					}}
				/>
			)}
		</>
	)
}
