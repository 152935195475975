import './popover.scss'

import { Options } from '@popperjs/core'
import React from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

export interface PopoverProps {
	children: React.ReactElement
	escapeOnMouseOut: boolean
	/** ParentRef allows you to render the tooltip somewhere other than the document body. This is not recommended for most implementations */
	parentRef?: HTMLElement
	options: Partial<Options>
	/** Reference to the element this will be rendered next to */
	refElement: HTMLElement
	setShowPopover: (state: boolean) => void
	showPopover: boolean
	style?: React.CSSProperties
	zIndex?: number
}

const DISMISS_DELAY = 500

/** Generic component used to simplify implementation of other components that use popovers */
export function Popover(props: PopoverProps) {
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
	const [popoverTimer, setPopoverTimer] = useState<NodeJS.Timeout | null>(null)

	function getMaxHeight(cssProps: React.CSSProperties): number {
		if ('transform' in cssProps && typeof cssProps.transform === 'string') {
			const yAxisPos = parseFloat(cssProps.transform.split(',')[1].split('px')[0])
			return Math.min(window.innerHeight - yAxisPos - 50, 400)
		} else {
			return 400
		}
	}

	const { styles, attributes } = usePopper(props.refElement, popperElement, {
		placement: props.options.placement ? props.options.placement : 'bottom',
		modifiers: [
			{
				name: 'preventOverflow',
				options: {
					boundary: document.body,
					mainAxis: true,
				},
			},
		],
	})

	const popoverStyle: React.CSSProperties = {
		...styles.popper,
		...(props.style ? props.style : {}),
		...{ maxHeight: getMaxHeight(styles.popper), pointerEvents: 'auto' },
	}

	if (props.showPopover) {
		return (
			<>
				{' '}
				{ReactDOM.createPortal(
					<div
						className="popover__scrim"
						style={{
							pointerEvents: props.escapeOnMouseOut ? 'none' : 'auto',
							zIndex: props.zIndex ? props.zIndex : undefined,
						}}
						onClick={() => {
							props.setShowPopover(false)
						}}
						onMouseMove={() => {
							if (props.escapeOnMouseOut) {
								const timer = setTimeout(() => {
									props.setShowPopover(false)
								}, DISMISS_DELAY)
								setPopoverTimer(timer)
							}
						}}
					>
						<div
							ref={setPopperElement}
							style={popoverStyle}
							{...attributes.popper}
							onClick={(evt) => {
								evt.stopPropagation()
							}}
							onMouseMove={(evt) => {
								evt.stopPropagation()
							}}
							onMouseLeave={() => {
								if (props.escapeOnMouseOut) {
									const timer = setTimeout(() => {
										props.setShowPopover(false)
									}, DISMISS_DELAY / 2)
									setPopoverTimer(timer)
								}
							}}
							onMouseEnter={() => {
								props.setShowPopover(true)
								if (popoverTimer) {
									clearTimeout(popoverTimer)
									setPopoverTimer(null)
								}
							}}
						>
							{props.children}
						</div>
					</div>,
					props.parentRef ? props.parentRef : document.body,
				)}
			</>
		)
	} else {
		return <></>
	}
}
