import { Paper } from '@components/paper/paper'

export function AdvertisingAccount__Route__Billing() {
	return (
		<>
			<h2 className="mb-10">Billing</h2>
			<Paper className="p-20 mb-40 col-xs-12" style={{ maxWidth: `800px` }}>
				[STRIPE INTEGRATION GOES HERE]
			</Paper>
		</>
	)
}
