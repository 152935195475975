import './resource-card-list.scss'

import { LoKationButton } from '@components/button/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import { ResourceService } from '../../services/resources/resources.service'
import { LokationResource } from '../../services/resources/resources.types'
import { ResourceCard, ResourceCardStyle } from '../resource-card/resource-card'
import { ToggleButton, ToggleButtonOption } from '../toggle-button/toggle-button'
import { ResourceListSectionHeader } from './section-selector/section-header'

export interface ResourceCardListProps {
	resources: ResourceCardResource[]
	className?: string
	style?: React.CSSProperties
	cardStyle: ResourceCardStyle
	title?: string
	includeStyleToggle: boolean
	groupByTag: boolean
	id?: string
	showHiddenResources?: boolean
}

export type ResourceCardResource = LokationResource & {
	isSponsored?: boolean
	onClick?: () => void
}

export function ResourceCardList(props: ResourceCardListProps) {
	const cachedCardStyle = localStorage.getItem(`resource-list__${props.id}__style`) as ResourceCardStyle | undefined
	const [selectedCardStyle, setSelectedCardStyle] = useState<ResourceCardStyle>(
		cachedCardStyle ? cachedCardStyle : props.cardStyle,
	)

	// const [highlightedSection, setHighlightedSection] = useState<ResourcesOrganizedByTagDTO | null>(null)
	const resourcesGroupedByTag = ResourceService.organizeByTag(props.resources)

	function getCardStyleOptions(): ToggleButtonOption<ResourceCardStyle>[] {
		return [
			{
				value: 'LARGE',
				label: <FontAwesomeIcon icon={['far', 'grid-2']} className="color__accent" />,
			},
			{
				value: 'LIST',
				label: <FontAwesomeIcon icon={['far', 'bars']} className="color__accent" />,
			},
		]
	}

	function returnListByGroup(): React.ReactNode {
		return (
			<>
				{resourcesGroupedByTag.map((group) => {
					return (
						<div className="pt-40" id={`section-${group.value}`} key={group.value}>
							{resourcesGroupedByTag.length > 1 && (
								<ResourceListSectionHeader
									label={group.label}
									value={group.value}
									onBecomeSticky={() => {
										// setHighlightedSection(group)
									}}
								/>
							)}
							<div className="resource-card-list">
								{group.resources.map((resource) => {
									if (!resource.published && !props.showHiddenResources) {
										return <></>
									} else {
										return (
											<ResourceCard
												{...resource}
												cardStyle={selectedCardStyle}
												key={resource.title}
											/>
										)
									}
								})}
							</div>
						</div>
					)
				})}
			</>
		)
	}

	function scrollToAnchor(anchorPoint: string) {
		const anchor = document.getElementById(anchorPoint)
		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	}

	function returnListByGroupLinks(): React.ReactNode {
		return (
			<>
				{resourcesGroupedByTag.map((group) => {
					return (
						<div className="group-by-links mr-10">
							<LoKationButton
								size="sm"
								variant="text"
								label={group.label}
								onClick={() => scrollToAnchor(`resource-${group.value}`)}
								className="mr-10"
							/>
						</div>
					)
				})}
			</>
		)
	}

	return (
		<div>
			{props.title && (
				<>
					<div className="route__header">
						<div className="flex flex-justifyContent-between flex-alignItems-center">
							<div className="flex flex-alignItems-center flex-fillSpace pr-20">
								<h4 className="color__accent mr-20">{props.title}</h4>
							</div>

							{props.includeStyleToggle && (
								<ToggleButton<ResourceCardStyle>
									options={getCardStyleOptions()}
									selectedOption={selectedCardStyle}
									onSelect={(newState) => {
										setSelectedCardStyle(newState)
										if (props.id) {
											localStorage.setItem(`resource-list__${props.id}__style`, newState)
										}
									}}
								/>
							)}
						</div>
					</div>

					{props.groupByTag && (
						<>
							<div className="route__quick-links">
								<h4 className="color__accent pb-20">Quick Links</h4>
								<div className="flex flex-wrap flex-column-sm-down">{returnListByGroupLinks()}</div>
							</div>
						</>
					)}
				</>
			)}

			<div className={props.className ? props.className : ''} style={props.style}>
				{props.resources.length === 0 && (
					<div>
						<h6>No resources found</h6>
					</div>
				)}
				{props.groupByTag && <>{returnListByGroup()}</>}
				{!props.groupByTag && (
					<div className="resource-card-list">
						{props.resources.map((resource) => {
							if (!resource.published && !props.showHiddenResources) {
								return <></>
							} else {
								return <ResourceCard {...resource} cardStyle={selectedCardStyle} key={resource.title} />
							}
						})}
					</div>
				)}
			</div>
		</div>
	)
}
