import _ from 'lodash'
import { ToastService } from 'src/services/toast/toast.service'

import { DateService } from '../../../services/date.service'
import { LicensedStateEnums } from '../../../services/licensed-states/licenses-states.types'
import { PDFGenerationService } from '../../../services/pdf-generation/pdf-generation.service'
import { LicenseInformationAPI } from '../../../services/user/license-information.api'
import { AgentLicense, EndUserProps } from '../../../services/user/user.types'
import { RegistrationContractsService } from '../../registration/routes/contracts/contracts.service'

export function UserProfileService() {
	function applyModifiedStateToOriginalProps(
		modifiedState: Partial<EndUserProps>,
		originalState: EndUserProps,
	): EndUserProps {
		return { ...originalState, ...modifiedState }
	}

	function validateLicenses(licenses: AgentLicense.Entry[]): boolean {
		return licenses.every((license) => {
			if (!license.licenseNumber) {
				return false
			}
			if (license.licensedState === null) {
				return false
			}
			return true
		})
	}

	function getCompleteLicenses(licenses: AgentLicense.Entry[]): AgentLicense.CompleteEntry[] {
		const completeLicenses: AgentLicense.CompleteEntry[] = []

		licenses.forEach((license) => {
			if (!license.licenseNumber) {
				return
			}
			if (license.licensedState === null) {
				return
			}
			completeLicenses.push(license as AgentLicense.CompleteEntry)
		})

		return completeLicenses
	}

	function reconcileLicenseChangesAndCommit(
		userId: number,
		originalLicenses: AgentLicense.Entry[],
		updatedLicenses: AgentLicense.Entry[],
	): Promise<void> {
		return new Promise((resolve) => {
			const changePromises: Promise<unknown>[] = []

			const licensesToRemove: number[] = []
			const licensesToAdd: AgentLicense.CompleteEntry[] = []
			const licensesToUpdate: AgentLicense.CompleteEntry[] = []

			/** Get licenses to remove */
			const licenseIdsInUpdatedState = updatedLicenses.map((license) => license.licenseInformationId)
			originalLicenses.forEach((originalLicense) => {
				if (!licenseIdsInUpdatedState.includes(originalLicense.licenseInformationId)) {
					licensesToRemove.push(originalLicense.licenseInformationId)
				}
			})

			/** Get licenses to add */
			const licenseIdsInOriginalState = originalLicenses.map((license) => license.licenseInformationId)
			updatedLicenses.forEach((updatedLicense) => {
				if (!licenseIdsInOriginalState.includes(updatedLicense.licenseInformationId)) {
					if (updatedLicense.licenseNumber && updatedLicense.licensedState !== null) {
						licensesToAdd.push(updatedLicense as AgentLicense.CompleteEntry)
					}
				}
			})

			/** Get licenses to update */
			updatedLicenses.forEach((updatedLicense) => {
				if (licenseIdsInOriginalState.includes(updatedLicense.licenseInformationId)) {
					const originalLicenseProps = originalLicenses.find(
						(license) => license.licenseInformationId === updatedLicense.licenseInformationId,
					)
					if (originalLicenseProps && !_.isEqual(originalLicenseProps, updatedLicense)) {
						const { licenseAgreement, ...strippedLicense } = updatedLicense
						licensesToUpdate.push(strippedLicense as AgentLicense.CompleteEntry)
					}
				}
			})

			/** Execute all changes */
			licensesToRemove.forEach((licenseToRemove) => {
				changePromises.push(LicenseInformationAPI.deleteLicense(userId, licenseToRemove))
			})
			// licensesToAdd.forEach((licenseToAdd) => {
			//     changePromises.push(LicenseInformationAPI.addLicense(userId, licenseToAdd))
			// })
			licensesToUpdate.forEach((licenseToUpdate) => {
				changePromises.push(LicenseInformationAPI.patchLicense(userId, licenseToUpdate))
			})

			Promise.all(changePromises).then(() => {
				resolve()
			})
		})
	}

	function base64_arraybuffer(data: Uint8Array): Promise<string> {
		return new Promise((resolve) => {
			const reader = new FileReader()
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					//const base64 = reader.result.substring(reader.result.indexOf(',') + 1)
					resolve(reader.result)
				}
			}
			reader.readAsDataURL(new Blob([data]))
		})
	}

	function attachContractToLicense(
		license: AgentLicense.CompleteEntry,
		signature: string,
	): Promise<AgentLicense.CompleteEntry> {
		function generateContract(abbreviation: LicensedStateEnums, plan: AgentLicense.PlanType): Promise<string> {
			return new Promise((resolve) => {
				fetch(signature).then((res) => {
					res.blob().then((signatureBlob) => {
						if (!signatureBlob) {
							ToastService().create({
								type: 'ERROR',
								body: `Contract could not be generated`,
							})
							return
						}

						const userName = `TEST`
						const todaysDate = DateService.getCurrentDateString(0)

						const template = RegistrationContractsService().getContractTemplate(
							abbreviation,
							plan,
							userName,
							todaysDate,
							signatureBlob,
						)

						if (!template) {
							ToastService().create({ type: 'ERROR', body: `Could not generate PDF for contract` })
							return
						}

						PDFGenerationService()
							.modifyPDF(template.basePDF, template.additions)
							.then((uintArray) => {
								base64_arraybuffer(uintArray).then((res) => {
									resolve(res)
								})
							})
							.catch((err) => {
								ToastService().create({
									type: 'ERROR',
									body: `Contract could not be generated`,
								})
							})
					})
				})
			})
		}

		return new Promise((resolve, reject) => {
			if (!license.licensedState) {
				console.log('no license state')
				reject()
				return
			}

			if (!license.plan) {
				console.log('no license plan')
				reject()
				return
			}

			generateContract(license.licensedState.abbreviation, license.plan)
				.then((contractBase64) => {
					license.licenseAgreement = contractBase64
					resolve(license)
				})
				.catch((error) => {
					console.log('Error generating contract:', error)
					reject()
				})
		})
	}

	return {
		applyModifiedStateToOriginalProps,
		validateLicenses,
		getCompleteLicenses,
		reconcileLicenseChangesAndCommit,
		attachContractToLicense,
	}
}
