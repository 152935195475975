import './listing-generation.scss'

import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { TextInput } from '@components/text-input/text-input'
import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { RootState } from '@redux/store'
import { Client } from '@stomp/stompjs'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { webSocketBaseURL } from 'src/services/axios/http-common.service'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'
import { ListingGeneration } from 'src/services/listing-generation/listing-generation.api'
import { ToastService } from 'src/services/toast/toast.service'

interface ListingGenerationRouteProps {
	currentSession: CurrentSessionSliceProps
}

export function ListingGenerationRoutePrototype({ currentSession }: ListingGenerationRouteProps) {
	const [address, setAddress] = useState<string>('')
	const [reference, setReference] = useState<string>('')
	const [generatedListing, setGeneratedListing] = useState<string>('')
	const [loading, setLoading] = useState(true)
	const [isSubmitted, setIsSubmitted] = useState(false)

	useEffect(() => {
		const stompClient = new Client({
			brokerURL: `${webSocketBaseURL}/ws/register-websocket?Token=${currentSession.token?.jwt}`, // Adjust this URL to your WebSocket endpoint

			debug: function (str) {
				console.log('STOMP: ' + str)
			},
			onConnect: () => {
				console.log('Connected to STOMP')

				stompClient.subscribe(`/ws/topic/generate_listing/${currentSession.token?.id}`, (message) => {
					const messageBody = JSON.parse(message.body)

					const newContent = messageBody.choices[0].delta.content

					if (newContent) {
						setGeneratedListing((prevContent) => {
							return prevContent + newContent
						})
					}
				})
			},
		})

		stompClient.activate()

		// Disconnect on cleanup
		return () => {
			stompClient.deactivate()
		}
	}, [currentSession.token]) // Reconnect if the token changes

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors

		formErrors = validateSimpleString(address) ? '' : 'Address is required'
		formErrors = validateSimpleString(reference) ? '' : 'Property description is required'

		return Object.values(formErrors).every((x) => x === '')
	}

	async function GenerateListingOffAddress() {
		setGeneratedListing('')
		// setLoading(true)
		if (!validateFormFields()) {
			setIsSubmitted(false)
			setLoading(true)
			ToastService().create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}
		ListingGeneration.GenerateListing(address, reference)
	}

	async function cancelListingGeneration() {
		ListingGeneration.cancelGenerateListing()
	}
	return (
		<>
			{/* <div>
				<p>WebSocket state: {readyState === 1 ? 'Open' : 'Closed/Connecting'}</p>
			</div> */}
			<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down py-20 overflow-y__scroll">
				<div className="col-12 flex flex-justifyContent-center">
					<div className="col-12 col-md-8 ">
						<h2 className="mb-20">Revolutionize Property Listings with AI-Powered Descriptions</h2>
						<p>
							In today's fast-paced real estate market, standing out with compelling property listings is
							more important than ever. Our latest feature harnesses the power of ChatGPT, a cutting-edge
							AI language model, to transform basic property addresses into detailed, captivating property
							descriptions that capture the essence of each home.
						</p>
						<div className="">
							<div>
								<TextInput
									label={'Enter Address'}
									dataType="text"
									value={address}
									width="100%"
									className="mb-20"
									onChange={setAddress}
								/>
								<TextInput
									label={'Enter Description'}
									dataType="text"
									rows={2}
									value={reference}
									width="100%"
									className="mb-20"
									onChange={setReference}
								/>
								<div>
									<LoKationButton
										variant="contained"
										size="lg"
										primary={true}
										label={'Generate Listing'}
										onClick={GenerateListingOffAddress}
										className="mb-20 mr-20"
										disabled={loading && isSubmitted}
									/>
									<LoKationButton
										variant="contained"
										size="lg"
										primary={false}
										label={'Cancel'}
										onClick={cancelListingGeneration}
										className="mb-20"
										disabled={loading && isSubmitted}
									/>
								</div>

								{loading && isSubmitted && (
									<div className="mb-10">
										<GenericContentLoader width={'fill'} height={200} />
									</div>
								)}

								{generatedListing !== '' && (
									<div className="pb-20">
										<Paper elevation={1} className="p-20">
											<div className="flex flex-justifyContent-end mb-10">
												<IconButton
													icon={'copy'}
													onClick={() => {
														CopyToClipboard(generatedListing, 'HTML')
													}}
												/>
											</div>
											<div dangerouslySetInnerHTML={{ __html: generatedListing }} />
										</Paper>
									</div>
								)}

								<div className="disclaimer-text">
									Disclaimer: The information provided herein has been automatically generated by an
									AI system and is intended solely for informational purposes. We cannot assure the
									accuracy or completeness of the data presented. Any of this information used in any
									way is undertaken at your own risk. It is strongly advised to conduct verification
									and complete necessary revisions to satisfy fair housing guidelines and MLS rules
									and regulations prior utilizing this information. Lokation Real Estate does not
									warranty or guarantee any information obtained through AI to be true and correct.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
function mapStateToProps(state: RootState) {
	return {
		currentSession: state.currentSession,
	}
}

export const ListingGenerationRoute = connect(mapStateToProps)(ListingGenerationRoutePrototype)
