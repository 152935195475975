import './notifications-button.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { DateService } from '../../../../services/date.service'
import { UsePopoverOnEvent } from '../../../../services/hooks/use-popover-on-event'
import { MessagesAPI } from '../../../../services/messaging-service/messages.api'
import { MessagingService } from '../../../../services/messaging-service/messaging-service.types'
import { DomainTheme } from '../../../../services/theme/theme.types'
import { Badge } from '../../../badge/badge'
import { LoKationButton } from '../../../button/button'
import { IconButton } from '../../../icon-button/icon-button'
import { ListItem } from '../../../list-item/list-item'
import { Popover } from '../../../popover/popover'

interface ConnectedProps {
	theme: DomainTheme.CompleteTheme
}

function NotificationsButtonPrototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const [chatGroups, setChatGroups] = useState<MessagingService.ChatGroup[] | null>(null)
	const popoverRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isPopoverOpen,
		setIsPopoverOpen: setIsPopoverOpen,
		delay: 0,
	})
	const [chatMessages, setChatMessages] = useState<MessagingService.ChatMessage[] | null>(null)
	const [numUnreadMessages, setNumUnreadMessages] = useState<number>(0)

	/** ========================================= */
	/** Effects */

	/** Get messages by sender */
	useEffect(() => {
		MessagesAPI()
			.getAllChatGroups({ page: 0, size: 50 })
			.then((chatGroupsRes) => {
				setChatGroups(chatGroupsRes.data.items)
			})
	}, [])

	useEffect(() => {
		if (chatGroups) {
			chatGroups.forEach((chatGroup) => {
				MessagesAPI()
					.getAllMessagesInChatGroup(chatGroup.chat.chatId, { page: 0, size: 50 })
					.then((res) => {
						setChatMessages(res.data.items)
					})
			})
		}
	}, [chatGroups])

	useEffect(() => {
		let messageCounter = 0
		if (chatMessages) {
			chatMessages.forEach((chatMessage) => {
				const sentTime = new Date(chatMessage.sentTimestamp)
				const lastReadTime = chatMessage.sentBy.lastRead ? new Date(chatMessage.sentBy.lastRead) : null
				if (lastReadTime === null || sentTime > lastReadTime) {
					messageCounter++
				}
			})
		}
		setNumUnreadMessages(messageCounter)
	}, [chatMessages])
	/** ========================================= */
	/** Render Component */

	return (
		<div>
			<IconButton
				ref={popoverRef}
				icon={'bell'}
				color={props.theme.appHeaderTextColor}
				badge={numUnreadMessages > 0 ? { value: numUnreadMessages } : undefined}
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen)
				}}
			/>
			{popoverRef.current && (
				<Popover
					{...popoverProps}
					refElement={popoverRef.current}
					escapeOnMouseOut={false}
					setShowPopover={(newState) => {
						setIsPopoverOpen(newState)
					}}
					options={{}}
				>
					<div className="notifications-popover">
						<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween p-20 border-bottom-thin flex-noShrink">
							<h6>Unread Messages</h6>
							<LoKationButton
								label="Mark all as read"
								variant="text"
								size="sm"
								onClick={() => {
									return new Promise((resolve) => {
										// store.dispatch(setAllMessagesRead())
										resolve()
									})
								}}
							/>
						</div>
						<div className="overflow-y__scroll flex-fillSpace flex flex-column">
							{chatGroups === null && (
								<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
									<FontAwesomeIcon
										icon={['far', 'spinner']}
										spin={true}
										size="4x"
										style={{ opacity: '0.75' }}
									/>
								</div>
							)}
							{chatGroups && chatGroups.length === 0 && (
								<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
									<FontAwesomeIcon
										icon={['far', 'mailbox']}
										size="4x"
										style={{ opacity: '0.75' }}
										className="mb-20"
									/>
									<h6>You have no unread messages</h6>
								</div>
							)}

							{chatGroups?.map((chatGroup) => {
								return (
									<ListItem
										key={chatGroup.chat.chatId}
										avatar={{ size: 'md' }}
										body={
											<div className="flex flex-alignItems-center flex-fillSpace">
												<div className="flex-fillSpace">
													<div>
														{chatGroup.lastMessage.sentBy.firstName}{' '}
														{chatGroup.lastMessage.sentBy.lastName} has sent you a message
													</div>
													<em>
														{DateService.getFormattedDateFromDateObj(
															new Date(chatGroup.lastMessage.sentTimestamp),
														)}
													</em>
												</div>
												<div>
													{numUnreadMessages > 0 && <Badge value={numUnreadMessages} />}
												</div>
											</div>
										}
										onClick={() => {
											setIsPopoverOpen(false)
											navigate(`/messages/${chatGroup.chat.chatId}`)
										}}
									/>
								)
							})}
						</div>
						<div
							className="notifications-popover__view-all-btn"
							onClick={() => {
								setIsPopoverOpen(false)
								navigate(`/messages`)
							}}
						>
							View all messages
						</div>
					</div>
				</Popover>
			)}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		theme: state.theme,
	}
}

export const NotificationsButton = connect(mapStateToProps)(NotificationsButtonPrototype)
