import { httpRequest, httpRequestNoErrors, RequestBaseURL } from '../axios/http-common.service'
import { EmailNotificationTypes } from './email-notification.typings'

class EmailNotificationAPIPrototype {
	async cynosureEmailNotification(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/cynosure/register`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Cynosure notification.')
		}
	}

	async opcityEmailNotification(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/opcity/register`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Opcity notification.')
		}
	}

	async mortgagePreApprovalNotification(
		formData: EmailNotificationTypes.EmailMortgagePreApproval,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/mortgage-preapproval/register`,
				{},
				{
					params: {
						askingPrice: formData.askingPrice,
						amountToFinance: formData.amountToFinance,
						applicantName: formData.applicantName,
						applicantPhone: formData.applicantPhone,
						applicantEmail: formData.applicantEmail,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Mortgage pre-approval notification.')
		}
	}

	async cloudCMANotification(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/cloud-cma/register`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Cloud CMA notification.')
		}
	}

	async propertyManagementNotification(
		propertyManagementPDF: Blob,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const formData = new FormData()
			formData.append('file', propertyManagementPDF, 'filename.pdf')

			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/property-management/register`, formData)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Property Management notification.')
		}
	}

	async referAgentNotification(
		formData: EmailNotificationTypes.EmailReferAnAgent,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/refer-an-agent`,
				{},
				{
					params: {
						referredName: formData.referredName,
						referredPhone: formData.referredPhone,
						referredEmail: formData.referredEmail,
						referredState: formData.referredState,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Refer an Agent notification.')
		}
	}

	async freeTransactionCoordinator(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/free-transaction-coordinator/register`,
				{},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Free Transaction Coordinator notification.')
		}
	}

	async feedbackNotification(
		formData: EmailNotificationTypes.EmailSubmitFeedback,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/user-feedback`,
				{},
				{
					params: {
						requestType: formData.requestType,
						priorityLevel: formData.priorityLevel,
						moreInformation: formData.moreInformation,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Feedback notification.')
		}
	}

	async manageTransactionsNotification(
		closingTeam: string,
		financed: string,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const params = {
				closingTeam: closingTeam,
				financed: financed,
			}
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/manage-transactions-notification`, null, {
				params: params,
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Free Transaction Coordinator notification.')
		}
	}

	async emailSignatureNotification(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/email-signature-notification`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Email Signature notification.')
		}
	}

	async requestSubscriptionCancellation(
		productName: string,
		endUserId?: number,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const params = {
				productName: productName,
			}

			let url = `${RequestBaseURL}/api/v1/request-subscription-cancellation`

			if (endUserId != null) {
				url += `/${endUserId}`
			}

			const response = await httpRequest.post(url, null, { params: params })
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send subscription cancellation notification')
		}
	}

	async newUserSignUp(
		formData: EmailNotificationTypes.EmailNewSignUp,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequestNoErrors.post(
				`${RequestBaseURL}/api/v1/new-user-signup`,
				{},
				{
					params: {
						firstName: formData.firstName,
						lastName: formData.lastName,
						email: formData.email,
						phone: formData.phone,
						state: formData.state,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send notification for new user sign up.')
		}
	}

	async existingEmailNotification(
		existUserData: EmailNotificationTypes.EmailExistingUser,
	): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(
				`${RequestBaseURL}/api/v1/existing-email-notification`,
				{},
				{
					params: {
						firstName: existUserData.firstName,
						lastName: existUserData.lastName,
						email: existUserData.email,
						phone: existUserData.phone,
						address1: existUserData.address1,
						address2: existUserData.address2,
						city: existUserData.city,
						state: existUserData.state,
						zip: existUserData.zip,
						notificationType: existUserData.notificationType,
					},
				},
			)
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send Refer an Agent notification.')
		}
	}

	async offboardingRequest(): Promise<EmailNotificationTypes.EmailSubmitResponse> {
		try {
			const response = await httpRequest.post(`${RequestBaseURL}/api/v1/offboarding-request`, {})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to send offboarding request.')
		}
	}
}

export const EmailNotificationAPI = new EmailNotificationAPIPrototype()
