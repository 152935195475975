import { useAppDispatch } from '@redux/hooks'
import { getCalendars } from '@redux/reducers/calendar-reducer'
import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { getHubs } from '@redux/reducers/hubs-reducer'
import { uiSlice, UIState } from '@redux/reducers/ui-reducer'
import { getUserProfilePhoto } from '@redux/reducers/user-profile-photo-reducer'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState, store } from '@redux/store'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { ToastService } from 'src/services/toast/toast.service'
import { TokenTimeoutNotificationService } from 'src/services/token-timeout-notifier/token-timeout-notifier'

import { NewUserOnboardingModal } from '../../modals/new-user-onboarding/new-user-onboarding__provider'
import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Domain } from '../../services/domain/domain.types'
import { EndUserProps } from '../../services/user/user.types'
import { AuthenticatedRouteChrome, RouteSection } from './authenticated-route-chrome'

interface ConnectedProps {
	currentSession: CurrentSessionSliceProps
	ui: UIState
	user: EndUserProps | null
	domain: Domain.Properties
}

function AuthenticatedRootRoutePrototype(
	props: {
		routeSection: RouteSection
	} & ConnectedProps,
) {
	const navigate = useNavigate()
	const token = props.currentSession.token
	const appDispatch = useAppDispatch()

	/** Attempt to log the user in */
	useEffect(() => {
		if (props.user) {
			console.info(`User is already logged in`)
			console.log('props.user: ', props.user.roles)
			if (props.user.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
				console.info(`User role includes PENDING_PAYMENT`)
				navigate('/registration')
			} else {
				loadDataForAuthenticatedUser(props.user)
				identifyUserForAnalytics(props.user)
			}
		} else if (!token) {
			console.info(`User is not logged in`)
			ToastService().create({ type: 'ERROR', body: `Please log in to access this page` })
			localStorage.setItem('redirectAfterLogin', window.location.pathname) // Save current URL for redirect after logging in
			navigate('/')
		} else {
			const userId = token.id
			store
				.dispatch(getUserProps({ userId }))
				.then((res) => {
					const payload = res.payload as EndUserProps
					if (payload.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
						navigate('/registration')
					} else {
						console.info(`User authentication successful`)
						loadDataForAuthenticatedUser(payload)
						identifyUserForAnalytics(payload)
					}
				})
				.catch((err) => {
					console.info(`User authentication failed`)
					ToastService().create({ type: 'ERROR', body: `Please log in to access this page` })
					navigate('/')
				})
		}
	}, [])

	function loadDataForAuthenticatedUser(user: EndUserProps): void {
		store.dispatch(getHubs())
		store.dispatch(getCalendars())

		if (user.hasProfilePicture) {
			store.dispatch(getUserProfilePhoto({ userId: user.endUserId }))
		}
	}

	function identifyUserForAnalytics(user: EndUserProps): void {
		const domainId = props.domain.domainId

		AnalyticsService().identifyUser(domainId, {
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			endUserId: user.endUserId,
		})
	}

	/** Remove scrollbar when mobile app tray is open */
	useEffect(() => {
		const body = document.body
		if (props.ui.isMobileTrayOpen && props.ui.viewMode === 'MOBILE') {
			body.style.overflow = 'hidden'
		} else {
			body.style.overflow = ''
		}
	}, [props.ui.isMobileTrayOpen, props.ui.viewMode])

	return (
		<AuthenticatedRouteChrome routeSection={props.routeSection}>
			<TokenTimeoutNotificationService />
			<Outlet />

			{props.ui.showUserOnboardingModal && (
				<NewUserOnboardingModal
					onClose={() => {
						appDispatch(uiSlice.actions.setShowUserOnboardingModal(false))
					}}
				/>
			)}
		</AuthenticatedRouteChrome>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
		user: state.user,
		domain: state.domain,
		currentSession: state.currentSession,
	}
}

export const AuthenticatedRootRoute = connect(mapStateToProps)(AuthenticatedRootRoutePrototype)
