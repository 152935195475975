import './context-menu.scss'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement } from 'react'

import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Popover } from '../popover/popover'

export type PopperPlacement =
	| 'auto'
	| 'auto-start'
	| 'auto-end'
	| 'top'
	| 'top-start'
	| 'top-end'
	| 'bottom'
	| 'bottom-start'
	| 'bottom-end'
	| 'right'
	| 'right-start'
	| 'right-end'
	| 'left'
	| 'left-start'
	| 'left-end'

export interface ContextMenuProps {
	header?: string
	options: Array<ContextMenuOption>
	maxWidth?: number
	placement?: PopperPlacement
	referenceElement: HTMLElement
	onDismiss: () => void
	isOpen: boolean
	style?: React.CSSProperties
}

export interface ContextMenuOption {
	label: string
	icon?: IconName
	image?: string
	onClick?: (evt: React.MouseEvent<HTMLElement>) => void
}

export function ContextMenu(props: ContextMenuProps) {
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: props.isOpen,
		setIsPopoverOpen: (newState) => {
			if (!newState) {
				props.onDismiss()
			}
		},
		delay: 0,
	})

	function createOption(option: ContextMenuOption): ReactElement {
		if (option.onClick) {
			return (
				<div
					key={option.label}
					className="context-menu__option flex flex-alignItems-center"
					onClick={(evt) => {
						if (option.onClick) {
							option.onClick(evt)
						}
						props.onDismiss()
					}}
				>
					{option.icon && <FontAwesomeIcon icon={['far', option.icon]} className="mr-10" fixedWidth />}
					{option.image && <img src={option.image} height={'23px'} className="menu-img mr-10" />}
					{option.label}
				</div>
			)
		} else {
			return (
				<div key={option.label} className="context-menu__header">
					{option.label}
				</div>
			)
		}
	}

	const popoverStyle: React.CSSProperties = {
		...props.style,
		...(props.maxWidth !== undefined && { maxWidth: `${props.maxWidth}px` }),
	}

	return (
		<Popover
			{...popoverProps}
			refElement={props.referenceElement}
			style={popoverStyle}
			escapeOnMouseOut={false}
			showPopover={props.isOpen}
			setShowPopover={(newState) => {
				if (!newState) {
					props.onDismiss()
				}
			}}
			options={{}}
		>
			<div className="context-menu__popover">
				{props.header && <div className="bg-color__adjust-10 rel-p-2 text-bold">{props.header}</div>}
				<div className="py-5">
					{props.options.map((option) => {
						return createOption(option)
					})}
				</div>
			</div>
		</Popover>
	)
}
