import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { HubTile } from '@components/hub-tile/hub-tile'
import { useEffect, useState } from 'react'

import { DOMAIN_ID } from '../../../../services/domain/domain.api'
import { HubsAPI } from '../../../../services/hubs/hubs.api'
import { Hub } from '../../../../services/hubs/hubs.types'
import { LandingPageAPI } from '../../../../services/landing-page/landing-page.api'

export function FeaturedHubs() {
	const [hubsToShow, setHubsToShow] = useState<Hub[] | null>(null)

	useEffect(() => {
		LandingPageAPI.getHubs(DOMAIN_ID).then((res) => {
			if (res.data.items.length > 0) {
				const sortedResults = res.data.items.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
				setHubsToShow(sortedResults.map((hubRef) => hubRef.hub))
			} else {
				HubsAPI.getAllHubs({ page: 0, size: 6 }).then((res) => {
					setHubsToShow(res.data.items)
				})
			}
		})
	}, [])

	return (
		<>
			{!hubsToShow && (
				<>
					<GenericContentLoader width={150} height={150} className="mr-10" />
					<GenericContentLoader width={150} height={150} className="mr-10" />
					<GenericContentLoader width={150} height={150} className="mr-10" />
				</>
			)}
			{hubsToShow && hubsToShow.length === 0 && <h6>No hubs found</h6>}
			{hubsToShow &&
				hubsToShow.map((hub) => {
					return <HubTile {...hub} key={hub.hubId} />
				})}
		</>
	)
}
