import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContextMenu, ContextMenuOption } from '../../context-menu/context-menu'
import { AppTrayTypes } from '../app-tray.types'
import { DesktopAppTrayButton } from './app-tray__button'

interface DesktopAppTrayOverflowHubsButtonProps {
	isTrayExpanded: boolean
	isTrayDocked: boolean
	hubLinks: AppTrayTypes.Link[]
}

export function DesktopAppTrayOverflowHubsButton(props: DesktopAppTrayOverflowHubsButtonProps) {
	const overflowHubWrapper = useRef<HTMLDivElement>(null)
	const [showOverflowHubs, updateShowOverflowHubs] = useState<boolean>(false)
	const navigate = useNavigate()

	function getOverflowContextMenuOptions(): ContextMenuOption[] {
		return props.hubLinks.map((link) => {
			return {
				label: link.label,
				onClick: () => {
					if ('path' in link) {
						navigate(link.path)
					}
				},
			}
		})
	}

	return (
		<div ref={overflowHubWrapper}>
			<DesktopAppTrayButton
				icon={'ellipsis'}
				label={'More'}
				expanded={props.isTrayExpanded || props.isTrayDocked}
				onClick={() => {
					updateShowOverflowHubs(true)
				}}
			/>
			{overflowHubWrapper.current && (
				<ContextMenu
					isOpen={showOverflowHubs}
					options={getOverflowContextMenuOptions()}
					referenceElement={overflowHubWrapper.current}
					onDismiss={() => {
						updateShowOverflowHubs(false)
					}}
				/>
			)}
		</div>
	)
}
