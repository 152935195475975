import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import { hubRoutePath } from '../../routes/routes.constant'
import { Hub } from '../../services/hubs/hubs.types'
import { Paper } from '../paper/paper'

export function HubTile(props: Hub) {
	const navigate = useNavigate()

	return (
		<Paper
			interactive={true}
			style={{ width: '150px', height: '150px', backgroundColor: props.color, color: '#fff' }}
			className="mb-10 flex flex-column flex-justifyContent-center flex-alignItems-center text-center p-10 mr-10 mr-0-xs-down"
			onClick={() => {
				navigate(`${hubRoutePath}/${props.slug}`)
			}}
		>
			<FontAwesomeIcon icon={['fas', props.icon as IconName]} size="2x" />
			<div className="mt-10" style={{ fontSize: '90%' }}>
				{props.title}
			</div>
		</Paper>
	)
}
