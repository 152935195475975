import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { RootState } from '../../redux/store'
import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from '../../services/regions/regions.service'
import { LicensedRegion } from '../../services/regions/regions.types'
import { LicenseInformationAPI } from '../../services/user/license-information.api'
import { UserService } from '../../services/user/user.service'
import { AgentLicense, EndUserProps } from '../../services/user/user.types'
import { LoKationButton } from '../button/button'
import { Dropdown } from '../dropdown/dropdown'
import { DropdownOptionProps } from '../dropdown/dropdown.types'
import { Paper } from '../paper/paper'
import { TextInput } from '../text-input/text-input'
import { AgentLicenseCardTypes } from './agent-license-card.types'

interface ConnectedProps {
	licensedStates: LicensedState[]
	currentUser: EndUserProps | null
	userEditPlan: (license: AgentLicense.CompleteEntry) => void
}

function AgentLicenseCardPrototype(props: AgentLicenseCardTypes.Component & ConnectedProps) {
	/** ==================================================== */
	/** Props and State */
	const disableIcaButton = props.userToEdit.legacySubscriber
	const licensedState = getStateForLicense()
	const regionsUnderSelectedState = licensedState
		? LicensedRegionsService.getRegionsOfState(licensedState.licensedStateId)
		: []

	/** ==================================================== */
	/** Methods */

	function getStateForLicense(): LicensedState | null {
		return props.license.licensedState
		//return props.licensedStates.find((licensedState) => licensedState.licensedStateId === props.license.licensedStateId)
	}

	function getLicensedStateOptions(): DropdownOptionProps<LicensedState>[] {
		return props.licensedStates.map((state) => {
			return {
				value: state,
				label: state.stateName,
			}
		})
	}
	function getRegionOptions(): DropdownOptionProps<LicensedRegion>[] {
		return regionsUnderSelectedState.map((region) => {
			return {
				value: region,
				label: region.regionName,
			}
		})
	}

	function getPlanOptions(): DropdownOptionProps<AgentLicense.PlanType>[] {
		return [
			{ value: 'BLUE', label: 'BLUE' },
			{ value: 'SILVER', label: 'SILVER' },
		]
	}

	function downloadPdf(data: ArrayBuffer, filename: string) {
		let blob = new Blob([data], { type: 'application/pdf' })
		let link = document.createElement('a')
		link.href = window.URL.createObjectURL(blob)
		link.download = filename
		link.click()
	}

	const handleDownload = (licenseInformationId: number, licenseState: string) => {
		LicenseInformationAPI.getLicenseAgreement(licenseInformationId)
			.then((res) => {
				let data = new Uint8Array(res.data)
				let filename = `${props.userToEdit.firstName}_${props.userToEdit.lastName}_${licenseState}_license_agreement.pdf`
				downloadPdf(data, filename)
			})
			.catch((error) => {
				toast.error(`This user has no Independent Contractor's Agreement on file`)
			})
	}

	function renderMLSBoard(): React.ReactNode {
		const matchingBoards = props.userToEdit.mlsBoards.filter(
			(board) =>
				board.licensedState &&
				props.license.licensedState &&
				board.licensedState.licensedStateId === props.license.licensedState.licensedStateId,
		)

		if (matchingBoards.length === 0) {
			return <em>No Boards</em>
		}

		return (
			<div>
				{matchingBoards.map((board) => (
					<Paper className="p-10 bg-color__adjust-5 mb-10" key={board.mlsBoardId}>
						<div>{board.displayValue}</div>
					</Paper>
				))}
			</div>
		)
	}

	function updateLicense<T>(key: keyof AgentLicense.CompleteEntry, value: T): void {
		const updatedLicense = { ...props.license, [key]: value } as AgentLicense.CompleteEntry

		props.onUpdateLicense(updatedLicense)
	}

	/** ==================================================== */
	/** Render Component */

	return (
		<>
			<div className="col-12">
				{!props.paymentFlow && (
					<div className="flex flex-alignItems-start flex-justifyContent-spaceBetween mb-20">
						<h3>{licensedState?.stateName}</h3>
						<LoKationButton
							size="sm"
							variant={'outlined'}
							label="Download License Agreement"
							className="mt-10"
							onClick={() =>
								handleDownload(
									props.license.licenseInformationId,
									props.license.licensedState?.stateName || 'NA',
								)
							}
							disabled={disableIcaButton}
						/>
					</div>
				)}
				<Paper className="p-10 mb-20 col-xs-12 flex-md flex-alignItems-start flex-column bg-color__adjust-5">
					<div className="flex-md flex-wrap">
						<div className="mr-10 mb-20" style={{ width: '200px' }}>
							<TextInput
								width={`100%`}
								dataType="text"
								disabled={!props.canUserEdit || props.updateFlow}
								label="License number"
								placeholder="License number"
								value={props.license.licenseNumber}
								onChange={(updatedValue) => {
									updateLicense<string>('licenseNumber', updatedValue)
								}}
							/>
						</div>

						<div className="mr-10 mb-20" style={{ width: '150px' }}>
							<Dropdown<LicensedState>
								label="Licensed State ID"
								disabled={!props.canUserEdit || props.updateFlow}
								value={licensedState ? [licensedState] : []}
								options={getLicensedStateOptions()}
								onSelect={(selectedValues) => {
									updateLicense<LicensedState>('licensedState', selectedValues[0])
								}}
							/>
						</div>

						{regionsUnderSelectedState.length > 0 && (
							<div className="mr-10 mb-20" style={{ width: '175px' }}>
								<Dropdown<LicensedRegion>
									label="Region"
									disabled={!props.canUserEdit || props.updateFlow}
									value={props.license.region ? [props.license.region] : []}
									options={getRegionOptions()}
									onSelect={(selectedValues) => {
										updateLicense<LicensedRegion>('region', selectedValues[0])
									}}
								/>
							</div>
						)}
						<div className="mr-10 mb-20" style={{ width: '125px' }}>
							<Dropdown<AgentLicense.PlanType>
								label="License Plan"
								disabled={!props.canUserEdit}
								value={props.license.plan ? [props.license.plan] : []}
								options={getPlanOptions()}
								onSelect={(selectedValues) => {
									updateLicense<AgentLicense.PlanType>('plan', selectedValues[0])
								}}
								tooltip={
									<div style={{ textAlign: 'left' }}>
										<strong>Blue Plan</strong>
										<ul>
											<li>$99 / transaction</li>
											<li>100% Commission, Always</li>
											<li>$99 per Month</li>
											<li>$99 per Rental Transaction</li>
											<li>$0 Annual Fee</li>
											<li>$50 Quarterly Compliance Fee</li>
										</ul>
										<p>
											<strong>Silver Plan</strong>
											<ul>
												<li>$499 / transaction</li>
												<li>100% Commission, Always</li>
												<li>$0 per Month</li>
												<li>$99 per Rental Transaction</li>
												<li>$99 Annual Fee</li>
												<li>$50 Quarterly Compliance Fee</li>
											</ul>
										</p>
									</div>
								}
							/>
						</div>

						{props.currentUser &&
						UserService.isUserAdmin(props.currentUser) &&
						!props.paymentFlow &&
						!props.updateFlow ? (
							<div className="mr-10 mb-20" style={{ width: '200px' }}>
								<TextInput
									width={`100%`}
									dataType="text"
									label="Paperless Pipeline ID"
									placeholder=""
									value={props.license.ppUserId}
									onChange={(updatedValue) => {
										updateLicense<string>('ppUserId', updatedValue)
									}}
								/>
							</div>
						) : null}
					</div>

					<div className="col-12 flex flex-justifyContent-end">
						{!props.paymentFlow && props.license.planChangedTimestamp && props.currentUser && (
							<>
								{/* <LoKationButton
									variant={'text'}
									label={'Change Plan'}
									size={'lg'}
									onClick={() => {
										props.userEditPlan(props.license as AgentLicense.CompleteEntry)
									}}
									disabled={
										DateService.checkPlanChangeDate(props.license.planChangedTimestamp) &&
										!UserService.isUserAdmin(props.currentUser)
									}
								/>
								{DateService.checkPlanChangeDate(props.license.planChangedTimestamp) && (
									<Tooltip
										className="ml-5"
										body={
											!UserService.isUserAdmin(props.currentUser)
												? 'Your plan was created or changed within the last 6 months. If you have any questions, please reach out to support@lokationre.com.'
												: 'This user has had their plan changed within the last 6 months'
										}
									/>
								)} */}
							</>
						)}

						{!props.paymentFlow && props.currentUser && UserService.isUserAdmin(props.currentUser) ? (
							<>
								<div className="mx-10">|</div>
								<LoKationButton
									variant={'text'}
									label={'Remove License'}
									size={'lg'}
									onClick={() => {
										props.onDeleteLicense()
									}}
								/>
							</>
						) : null}
					</div>
				</Paper>
			</div>

			{!props.paymentFlow && (
				<div className="col-12">
					<h6 className="mb-10">MLS Boards</h6>
					{renderMLSBoard()}
				</div>
			)}
		</>
	)
}

function mapStateToProps(state: RootState, ownProps: AgentLicenseCardTypes.Component) {
	return {
		licensedStates: state.licensedStates,
		currentUser: state.user,
	}
}

export const AgentLicenseCard = connect(mapStateToProps)(AgentLicenseCardPrototype)
