import { faEnvelope, faMessage, faPhone } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { EndUserProps } from '../../services/user/user.types'
import { LoKationButton } from '../button/button'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'

interface GetSupportProps {
	currentUser: EndUserProps | null
}

export function GetSupportSectionPrototype({ currentUser }: GetSupportProps) {
	const navigate = useNavigate()
	const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
	const [displayLuxury, setDisplayLuxury] = useState(false)
	const [luxuryOpen, setLuxuryOpen] = useState(false)

	useEffect(() => {
		if (currentUser !== null && currentUser.specialties) {
			const isLuxury = currentUser.specialties.some((specialty) => specialty.specialtyName.includes('Luxury'))
			setDisplayLuxury(isLuxury)
		}
	}, [currentUser])

	const handleToggleCollapsible = (itemId: number) => {
		if (selectedItemId === itemId) {
			setSelectedItemId(null)
			setLuxuryOpen(false)
		} else {
			setSelectedItemId(itemId)
			setLuxuryOpen(false)
		}
	}

	const handleToggleLuxury = () => {
		setLuxuryOpen(!luxuryOpen)
		setSelectedItemId(null)
	}

	const handleClick = (stateAbbreviation: string) => {
		navigate(`/support`, {
			state: { selectedState: stateAbbreviation },
		})
	}

	const licenseInfoArray = currentUser ? [...currentUser.licenseInformation] : []

	// Sort the array alphabetically, keeping Florida at the top
	licenseInfoArray.sort((a, b) => {
		if (a.licensedState.abbreviation === 'FL') return -1
		if (b.licensedState.abbreviation === 'FL') return 1
		return a.licensedState.stateName.localeCompare(b.licensedState.stateName)
	})

	function getLuxuryInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="mailto:support@lokationluxury.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> support@lokationluxury.com
					</a>
					<a
						href="mailto:marketing@lokationluxury.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> marketing@lokationluxury.com
					</a>
					<a
						href="mailto:info@lokationluxury.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> info@lokationluxury.com
					</a>
					<a
						href="mailto:broker@lokationluxury.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> broker@lokationluxury.com
					</a>
				</div>
			</>
		)
	}

	function getAlabamaInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/dir-of-operations-se-region-ga-al-sc"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<a
						href="tel:404-348-0420"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faPhone} className="mr-10" /> 404-348-0420
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('AL')
						}}
					/>
				</div>
			</>
		)
	}

	function getColoradoInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/colorado-employing-broker"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<a
						href="tel:720-370-3878"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faPhone} className="mr-10" /> 720-370-3878
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('CO')
						}}
					/>
				</div>
			</>
		)
	}

	function getFloridaInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/agent-support-session"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<a
						href="tel:954-743-4981"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faPhone} className="mr-10" /> 954-743-4981
					</a>
					<a
						href="sms:954-231-1377"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faMessage} className="mr-10" /> 954-231-1377
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('FL')
						}}
					/>
				</div>
			</>
		)
	}

	function getGeorgiaInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/dir-of-operations-se-region-ga-al-sc"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<a
						href="tel:404-348-0420"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faPhone} className="mr-10" /> 404-348-0420
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('GA')
						}}
					/>
				</div>
			</>
		)
	}

	function getSouthCarolinaInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/dir-of-operations-se-region-ga-al-sc"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<a
						href="tel:404-348-0420"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faPhone} className="mr-10" /> 404-348-0420
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('SC')
						}}
					/>
				</div>
			</>
		)
	}

	function getTexasInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="https://lokationsupport.com/"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Live Zoom Support
					</a>
					<a
						href="https://appt.link/texas-employing-brokerr"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						Set an Appointment
					</a>
					<LoKationButton
						className="mb-10"
						variant="outlined"
						label="Department Contacts"
						size={'sm'}
						onClick={() => {
							handleClick('TX')
						}}
					/>
				</div>
			</>
		)
	}

	function getDefaultInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="mailto:support@lokationre.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> support@lokationre.com
					</a>
				</div>
			</>
		)
	}

	function getStateComponent(abbreviation: LicensedStateEnums) {
		switch (abbreviation) {
			case 'AL':
				return getAlabamaInfo
			case 'CO':
				return getColoradoInfo
			case 'FL':
				return getFloridaInfo
			case 'GA':
				return getGeorgiaInfo
			case 'SC':
				return getSouthCarolinaInfo
			case 'TX':
				return getTexasInfo
			default:
				return getDefaultInfo
		}
	}

	return (
		<>
			{displayLuxury && (
				<div>
					<CollapsibleContent
						label={'Luxury'}
						state={luxuryOpen ? 'OPEN' : 'CLOSED'}
						setState={handleToggleLuxury}
						className="mt-10 px-10"
					>
						{getLuxuryInfo()}
					</CollapsibleContent>
				</div>
			)}
			{licenseInfoArray.length > 0 ? (
				licenseInfoArray.map((license) => {
					const stateAbbreviation = license.licensedState.abbreviation
					const stateName = license.licensedState.stateName
					const stateComponent = getStateComponent(stateAbbreviation)
					const isOpen = selectedItemId === license.licenseInformationId

					return (
						<div key={license.licenseInformationId}>
							<CollapsibleContent
								label={stateName}
								state={isOpen ? 'OPEN' : 'CLOSED'}
								setState={() => handleToggleCollapsible(license.licenseInformationId)}
								className="mt-10 px-10"
							>
								<>{stateComponent && stateComponent()}</>
							</CollapsibleContent>
						</div>
					)
				})
			) : (
				<div className="mt-10 px-10">{getDefaultInfo()}</div>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const GetSupportSection = connect(mapStateToProps)(GetSupportSectionPrototype)
