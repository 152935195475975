import './api-status.scss'

import { LoKationButton } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
// import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ItemSearchStatusCodeFilter } from '@components/item-search__status-code-filter.tsx/item-search__status-code-filter'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import Switch from '@components/switch/switch'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
// import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { ApiStatusAPI } from 'src/services/api-status/api-status.api'
import { ApiStatus } from 'src/services/api-status/api-status.types'
import { ExternalApiDetailsTypes } from 'src/services/external-api-details/external-api.types'
import { RealEstateLeadsAPI } from 'src/services/real-estate-leads/real-estate-leads.api'
import { DomainTheme } from 'src/services/theme/theme.types'
import { ToastService } from 'src/services/toast/toast.service'

import { UserService } from '../../../services/user/user.service'
import { EndUserProps } from '../../../services/user/user.types'
// import { useItemAdminHook } from '../use-item-admin-hook'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
	theme: DomainTheme.Theme
}

function ApiStatusAdministrationPrototype(props: ConnectedProps) {
	const statusCodes: ExternalApiDetailsTypes.StatusCode[] = [
		{ id: 200, label: '200 - OK' },
		{ id: 400, label: '400 - Bad Request' },
		{ id: 404, label: '404 - Not Found' },
		{ id: 500, label: '500 - Internal Server Error' },
	]

	const navigate = useNavigate()
	const [selectedApiStatus, updateSelectedApiStatus] = useState<ApiStatus | null>(null)
	// const [isPending, setIsPending] = useState(true)

	// const [searchApiNames, setSearchApiNames] = useState<string[] | undefined>(undefined)
	const [searchStatusCodes, setSearchStatusCodes] = useState<ExternalApiDetailsTypes.StatusCode[]>(statusCodes)

	const [viewMode, setViewMode] = useState<'APIs' | 'API STATUSES'>('APIs')

	// const apiStatusAdminHook = useItemAdminHook<ApiStatus, FindApiStatusInDomainQueryFilters>({
	// 	getItemsFunction: (page, size, sort, searchParams) => {
	// 		console.log(searchParams)
	// 		return ApiStatusAPI.findApiStatusInDomainAdmin({
	// 			query: {
	// 				apiNames: searchParams.apiNames ? searchParams.apiNames : undefined,
	// 				statusCodes: searchParams.statusCodes ? searchParams.statusCodes : undefined,
	// 			},
	// 			page,
	// 			size,
	// 			sort: [{ property: 'timestamp', direction: 'desc' }],
	// 		})
	// 	},
	// 	searchParams: {
	// 		apiNames: searchApiNames,
	// 		statusCodes: searchStatusCodes.map((statusCode) => statusCode.id),
	// 	},
	// 	itemIdKey: 'apiStatusId',
	// 	itemTitleKey: 'apiDetails',
	// 	setIsPending: (state) => {
	// 		setIsPending(state)
	// 	},
	// })

	const isUserAdmin = props.userProfile ? UserService.isUserAdmin(props.userProfile) : false

	useEffect(() => {
		if (!isUserAdmin) {
			navigate('/hubs')
			// setSearchApiNames([])
		}
	}, [isUserAdmin])

	const getStatusColor = (statusCode: number): string => {
		switch (statusCode) {
			case 200:
				return 'green'
			case 400:
				return 'orange'
			case 404:
				return 'red'
			case 500:
				return 'purple'
			default:
				return 'gray'
		}
	}

	function dismissModal() {
		updateSelectedApiStatus(null)
	}

	const ELEMENT_TYPE_TOGGLE = (
		<div className="flex flex-alignItems-center mb-15">
			<strong>APIs</strong>
			<Switch
				className="mx-5"
				checked={viewMode === 'API STATUSES'}
				onChange={() => {
					if (viewMode === 'APIs') {
						setViewMode('API STATUSES')
					} else {
						setViewMode('APIs')
					}
				}}
				disabled={true}
			/>
			<strong>API Statuses</strong>
		</div>
	)

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`API Status Management`}
					isCustom={true}
					backButton={{
						label: `Administration`,
						onClick: () => {
							navigate('/administration')
						},
					}}
				>
					<>
						{viewMode === 'API STATUSES' && (
							<ItemSearchStatusCodeFilter
								selectedStatusCodes={searchStatusCodes}
								onChange={(value) => {
									setSearchStatusCodes(value)
								}}
							/>
						)}
					</>
				</RouteSubnavigation>

				<RouteBodyV2 id="form-admin__route-body">
					{ELEMENT_TYPE_TOGGLE}
					{/* <div className="mb-20 opacity-50">{apiStatusAdminHook.totalElements} Results</div> */}
					<div className="flex flex-column-md-down">
						<div className="flex flex-column flex-fillSpace mt-20-md-down">
							{viewMode === 'APIs' && (
								<>
									<div className="form__card">
										<strong>Real Estate Leads Sync</strong>
										<IconButton
											icon={'refresh'}
											color={props.theme.appHeaderBgColor}
											onClick={() => {
												RealEstateLeadsAPI.syncUsers()
													.then((res) => {
														ToastService().create({ type: 'SUCCESS', body: res })
													})
													.catch((error) => {
														ToastService().create({ type: 'ERROR', body: error })
													})
											}}
										/>
									</div>
								</>
							)}
							{/* {viewMode === 'API STATUSES' && apiStatusAdminHook.items.length > 0 && (
								<>
									{!isPending &&
										!apiStatusAdminHook.hasMoreItems &&
										apiStatusAdminHook.items.length === 0 && (
											<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
												<FontAwesomeIcon icon={['far', 'users']} size="4x" className="mb-20" />
												<h3>We cannot find any users matching your criteria</h3>
											</div>
										)}

									<InfiniteScroll
										dataLength={apiStatusAdminHook.items.length}
										next={apiStatusAdminHook.loadNextPageOfItems}
										hasMore={apiStatusAdminHook.hasMoreItems}
										scrollableTarget={
											props.ui.viewMode === 'FULL' ? `user-admin__route-body` : undefined
										}
										loader={
											<>
												<GenericContentLoader width={'fill'} height={70} className="mb-10" />
												<GenericContentLoader width={'fill'} height={70} className="mb-10" />
												<GenericContentLoader width={'fill'} height={70} className="mb-10" />
											</>
										}
									>
										{apiStatusAdminHook.items &&
											apiStatusAdminHook.items.map((apiStatus) => {
												return (
													<div
														className="form__card"
														onClick={() => {
															updateSelectedApiStatus(apiStatus)
														}}
													>
														<strong>{apiStatus.apiDetails.apiName}</strong>
														<div>
															Status Code:{' '}
															<span
																className={`status ${getStatusColor(apiStatus.statusCode)}`}
															></span>
															{apiStatus.statusCode}
														</div>
													</div>
												)
											})}
									</InfiniteScroll>
								</>
							)} */}
						</div>
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedApiStatus && (
				<Modal
					maxWidth={750}
					maxHeight={500}
					fixedHeight={true}
					className="flex flex-column pb-0"
					onClose={dismissModal}
				>
					<>
						<ModalBody>
							<Paper elevation={1} className="mt-40 p-20">
								<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center mb-20">
									<h3>{selectedApiStatus.apiDetails.apiName}</h3>
									<div style={{ fontSize: '.8em', fontStyle: 'italic' }}>
										{new Date(selectedApiStatus.timestamp).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'long',
											day: 'numeric',
										})}
										{' at '}
										{new Date(selectedApiStatus.timestamp).toLocaleTimeString('en-US', {
											hour: '2-digit',
											minute: '2-digit',
											hour12: true,
										})}
									</div>
								</div>

								<div className="mb-20">
									<div>
										Status Code:{' '}
										<span
											className={`status ${getStatusColor(selectedApiStatus.statusCode)}`}
										></span>
										{selectedApiStatus.statusCode}
									</div>
								</div>
								<div className="mb-20">
									<strong>Details:</strong>
									<p>{selectedApiStatus.details}</p>
								</div>
								<div className="mb-20">
									<strong>Additional Information:</strong>
									<p>{selectedApiStatus.statusMessage}</p>
								</div>
							</Paper>
						</ModalBody>

						<ModalFooter>
							<LoKationButton
								variant="outlined"
								size="sm"
								primary={false}
								label="Close"
								className="mr-10"
								onClick={dismissModal}
							/>
						</ModalFooter>
					</>
				</Modal>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
		theme: state.theme,
	}
}

export const ApiStatusAdministrationRoute = connect(mapStateToProps)(ApiStatusAdministrationPrototype)
