import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'

// import Thumbnail from './cc_thumbnail.png'
// import Cruise from './cruise.png'
import Double from './double_rewards.png'

interface MortgageInsightModalProps {
	dismissModal: () => void
	// currentUser: EndUserProps | null
}

function HomepageModal({ dismissModal }: MortgageInsightModalProps) {
	return (
		<>
			<Modal
				onClose={dismissModal}
				maxWidth={800}
				maxHeight={825}
				fixedHeight={false}
				className="primary overflow-y__scroll"
			>
				<ModalHeader title="Connect & Collect!">
					<></>
				</ModalHeader>
				<ModalBody>
					<div>
						<>
							<div className="col-12 flex flex-wrap mb-20 flex-alignItems-center">
								<img src={Double} width={'100%'} className="mb-20" />
								{/* <div className="col-12 flex flex-justifyContent-center mb-20 responsive-video-container">
									<video controls autoPlay poster={Thumbnail}>
										<source src="/videos/Connect_Collect.mp4" type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div> */}
								<div className="col-12 flex flex-column flex-alignItems-center">
									<div className="mb-10">
										<p>
											Jump on board the Connect & Collect Express! This summer, earn double
											rewards for referring agents to join LoKation Real Estate during July and
											August:
										</p>
										<ul>
											<li>Blue plan referrals earn you $20 monthly.</li>
											<li>Silver plan referrals earn you $100 per closed deal.</li>
										</ul>

										<p>
											Grow our LoKation family and ride the Express to success together. Don’t
											miss out! Click the link below to learn more.
										</p>
									</div>
									<LoKationButton
										className="mt-10 mb-20"
										variant="outlined"
										label="Connect & Collect Now!"
										size={'sm'}
										onClick={() => {
											dismissModal()
											window.location.href = `/hubs/connect-and-collect`
										}}
									/>
								</div>
							</div>
						</>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default HomepageModal
