import './icon-selector.scss'

import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { OptionList } from '@components/option-list/option-list'
import { OptionListProps } from '@components/option-list/option-list.types'
import { Paper } from '@components/paper/paper'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useState } from 'react'
import React from 'react'

import { IconSelector } from './icon-selector.types'
import { IconSelectorOption } from './icon-selector__option'
import { iconSelectorOptions } from './icon-selector-options'

export function IconSelectorModal(props: IconSelector.ModalProps) {
	const [selectedIcon, setSelectedIcon] = useState<IconName | null>(null)
	const [selectedCategory, setSelectedCategory] = useState<string>('all')

	function selectIcon(): void {
		if (selectedIcon) {
			props.handleSelect(selectedIcon)
			props.dismissModal()
		}
	}

	function getCategories(): OptionListProps.Option<string>[] {
		const categories: OptionListProps.Option<string>[] = []
		categories.push({
			label: 'All',
			value: 'all',
		})
		iconSelectorOptions
			.sort((a, b) => (a.label > b.label ? 1 : -1))
			.forEach((category) => {
				categories.push({
					label: category.label,
					value: category.label,
				})
			})
		return categories
	}

	function getIconCategoriesByLabel(category: string): IconSelector.Category[] | undefined {
		if (selectedCategory === 'all') {
			return iconSelectorOptions
		}

		const matchingCategory = iconSelectorOptions.find((thisCategory) => thisCategory.label === category)
		return matchingCategory ? [matchingCategory] : undefined
	}

	const selectedIconCategory = getIconCategoriesByLabel(selectedCategory)

	return (
		<Modal
			maxWidth={1000}
			maxHeight={1000}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<ModalHeader title="Select Icon">
					<></>
				</ModalHeader>

				<ModalBody className="flex">
					<Paper
						className="mr-20 flex flex-column flex-alignItems-start p-10 flex-noShrink overflow-y__scroll"
						style={{ width: '250px', position: 'sticky', top: '0px' }}
					>
						<OptionList<string>
							persistent={true}
							value={selectedCategory}
							options={getCategories()}
							onClick={(option) => {
								setSelectedCategory(option)
							}}
						/>
					</Paper>

					<div className="flex-fillSpace">
						{selectedIconCategory && (
							<>
								{selectedIconCategory.map((category) => {
									return (
										<React.Fragment key={category.label}>
											<h5 className="mb-5">{category.label}</h5>
											<div className="flex flex-wrap mb-20">
												{category.icons.map((icon) => {
													return (
														<IconSelectorOption
															key={icon}
															isSelected={icon === selectedIcon}
															iconName={icon}
															onClick={() => {
																setSelectedIcon(icon)
															}}
														/>
													)
												})}
											</div>
										</React.Fragment>
									)
								})}
							</>
						)}
					</div>
				</ModalBody>

				<ModalFooter>
					<LoKationButton
						variant="outlined"
						size="sm"
						primary={false}
						label="Cancel"
						className="mr-10"
						onClick={props.dismissModal}
					/>
					<LoKationButton variant="contained" size="sm" label="Save" onClick={selectIcon} />
				</ModalFooter>
			</>
		</Modal>
	)
}
