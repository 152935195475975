import './signature-field.scss'

import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { IconButton } from '../icon-button/icon-button'
import { Paper } from '../paper/paper'
import { ToggleButton } from '../toggle-button/toggle-button'

interface SignatureFieldProps {
	signature?: string
	fullName?: string
	onChange: (imgData: string) => void
	isValid?: boolean
}

type SignatureFieldMode = 'DRAW' | 'TYPE'

export function SignatureField(props: SignatureFieldProps) {
	const [mode, setMode] = useState<SignatureFieldMode>('DRAW')
	const signatureRef = useRef<SignatureCanvas>(null)
	const isEditable = mode === 'DRAW'

	/** ================================= */
	/** Effects */

	/** Load a new signature into the canvas whenever the user changes their name or switches modes */
	useEffect(() => {
		if (mode === 'DRAW' && props.signature) {
			signatureRef.current?.fromDataURL(props.signature)
		}
		if (mode === 'TYPE' && props.fullName && signatureRef.current) {
			const canvas = signatureRef.current.getCanvas()
			const context = canvas.getContext('2d')

			if (context && canvas) {
				signatureRef.current.clear()
				context.fillStyle = '#000'
				context.font = '52pt Hurricane'
				context.textAlign = 'center'
				context.textBaseline = 'middle'
				context.fillText(props.fullName, canvas.width / 2, canvas.height / 2)
				props.onChange(signatureRef.current.toDataURL())
			}
		}
	}, [props.signature, mode, props.fullName])

	/** ================================= */
	/** Methods */

	function clearCanvas(): void {
		if (signatureRef.current) {
			signatureRef.current.clear()
			props.onChange('')
		}
	}

	/** ================================= */
	/** Render Component */

	return (
		<Paper className="signature-field__wrapper">
			<div className="flex flex-alignItems-center flex-justifyContent-center pt-10">
				<ToggleButton<SignatureFieldMode>
					options={[
						{
							label: 'Draw',
							value: 'DRAW',
						},
						{
							label: 'Type',
							value: 'TYPE',
						},
					]}
					selectedOption={mode}
					onSelect={(newState) => {
						if (newState === 'DRAW') {
							clearCanvas()
						}
						setMode(newState)
					}}
				/>

				<IconButton
					icon="eraser"
					className="signature-field__clear-btn"
					disabled={!isEditable}
					onClick={() => {
						clearCanvas()
					}}
				/>
			</div>

			<div className="col-xs-12" style={{ pointerEvents: isEditable ? 'auto' : 'none' }}>
				<SignatureCanvas
					ref={signatureRef}
					penColor="black"
					onEnd={(signature) => {
						const signatureCanvasElt = signature.target as HTMLCanvasElement
						props.onChange(signatureCanvasElt.toDataURL())
					}}
					clearOnResize={false}
					canvasProps={{ style: { width: '100%', height: '150px' } }}
				/>
			</div>
		</Paper>
	)
}
