import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { ReferralAPI } from 'src/services/referrals/referral.api'
import { LeaderboardList } from 'src/services/referrals/referral.types'

export function Leaderboard() {
	const [leaderboard, setLeaderboard] = useState<LeaderboardList[] | undefined>(undefined)
	const [showCount, setShowCount] = useState(5)

	useEffect(() => {
		async function fetchLeaderboard() {
			try {
				const res = await ReferralAPI.referralLeaderboard('2024-07-01 00:00:00')
				setLeaderboard(res.data)
			} catch (error) {
				console.log('Error fetching leaderboard ', error)
			}
		}

		fetchLeaderboard()
	}, [])

	const toggleShowCount = () => {
		setShowCount((prevShowCount) => (prevShowCount === 5 ? 10 : 5))
	}

	return (
		<div>
			<div className="leaderboard-container col-12 border-thin border-radius__std p-20 mb-20 bg-color__adjust-5">
				<h2>Referral Leaderboard</h2>
				<ul className="leaderboard">
					{leaderboard &&
						leaderboard.slice(0, showCount).map((endUser, index) => (
							<li className="leaderboard-item" key={endUser.endUserId}>
								<span className="leaderboard-rank">
									{index === 0 && <FontAwesomeIcon icon="crown" style={{ color: 'gold' }} />}
									{index === 1 && <FontAwesomeIcon icon="medal" style={{ color: 'silver' }} />}
									{index === 2 && <FontAwesomeIcon icon="medal" style={{ color: 'brown' }} />}
									{index >= 3 && <span>{index + 1}</span>}
								</span>
								<span className="leaderboard-name">
									{endUser.referringFirstName} {endUser.referringLastName}
								</span>
								<span className="leaderboard-referrals">{endUser.referralCount} active referrals</span>
							</li>
						))}
				</ul>
				<div className="flex flex-justifyContent-end mt-15">
					{leaderboard && leaderboard.length > 5 && (
						<button onClick={toggleShowCount}>{showCount === 5 ? 'Show More' : 'Show Less'}</button>
					)}
				</div>
			</div>
		</div>
	)
}
