import './user__card.scss'

import { Paper } from '@components/paper/paper'
import { Tooltip } from '@components/tooltip/tooltip'
import { faGavel, faGlobe, faPersonCane } from '@fortawesome/pro-regular-svg-icons'
import { faBuilding, faChalkboardTeacher, faGem, faKey } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setUserProps } from '@redux/reducers/user-reducer'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UserAPI } from '../../../services/user/user.api'
import { FindUserDTO } from '../../../services/user/user.types'
import lokationPin from './../../../images/logo/lokation__mark.svg'

export function UserCard(props: FindUserDTO) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [showPhoto, setShowPhoto] = useState<boolean>(false)

	useEffect(() => {
		UserAPI.getUserDetails(props.endUserId).then((res) => {
			setUserProps(res.data)
			setShowPhoto(res.data.hasProfilePicture)
		})
	}, [])

	return (
		<Paper elevation={1} className="mb-10">
			<div
				className="user__card flex flex-justifyContent-spaceBetween"
				onClick={() => {
					searchParams.set('user', String(props.endUserId))
					setSearchParams(searchParams)
				}}
			>
				<div className="flex flex-alignItems-center">
					<div className="user__card__img sm">
						{!showPhoto && (
							<img
								src={lokationPin}
								style={{ maxWidth: '50%', maxHeight: '50%', filter: 'saturate(0)', opacity: '0.25' }}
							/>
						)}
						{showPhoto && (
							<div
								className="user-profile__photo"
								style={{
									backgroundImage: `url(${UserAPI.getUserPhotoURL(props.endUserId)})`,
									maxWidth: '100%',
									maxHeight: '100%',
									borderRadius: '4px',
								}}
							></div>
						)}
					</div>

					<div>
						<strong>
							{props.firstName} {props.lastName}
						</strong>
					</div>
				</div>
				{props.specialties.length > 0 && (
					<div className="flex flex-column">
						<strong className="mb-5 flex flex-justifyContent-end">Specialties:</strong>
						<div className="flex flex-justifyContent-end">
							{props.specialties
								.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName))
								.map((specialty, index) => {
									let icon

									switch (specialty.specialtyName) {
										case 'Luxury':
											// Luxury
											icon = faGem
											break
										case 'Commercial':
											// Commercial
											icon = faBuilding
											break
										case 'Property Management':
											// Property Management
											icon = faKey
											break
										case 'Mentor':
											// Mentor
											icon = faChalkboardTeacher
											break
										case '55+/HOPA':
											// 55+
											icon = faPersonCane
											break
										case 'Foreign Buyers/Sellers':
											// Foreign Buyer/Seller
											icon = faGlobe
											break
										case 'Probate':
											// Probate
											icon = faGavel
											break
										default:
											icon = null
									}

									const isLast = index === props.specialties.length - 1
									const className = isLast ? '' : 'mr-15 mr-10-sm-down'
									const iconName = icon?.iconName

									return icon ? (
										<>
											{/* Tooltip icons visible on medium screens and up */}
											<Tooltip
												body={specialty.specialtyName}
												icon={iconName}
												className={`${className} show-lg`}
												size="lg"
											/>

											{/* FontAwesome icons visible on small screens */}
											<FontAwesomeIcon
												key={specialty.specialtyId}
												icon={['far', iconName ?? 'question']}
												className={`${className} hide-lg`}
												size="lg"
											/>
										</>
									) : null
								})}
						</div>
					</div>
				)}
			</div>
		</Paper>
	)
}
