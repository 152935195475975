import './image-upload-tile.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { FileManagementService } from '../../services/file-management/file-management.service'
import { Paper } from '../paper/paper'

interface ImageUploadTileProps {
	image: string
	onUpload: (image: string) => void
	onRemove: () => void
	className?: string
	style?: React.CSSProperties
	fileTypes?: string
}

export function ImageUploadTile(props: ImageUploadTileProps) {
	const [isMouseIn, setIsMouseIn] = useState(false)

	const acceptedFiletypes = props.fileTypes ? props.fileTypes : '*.jpg,*.png,*.svg'

	function uploadImage(): void {
		FileManagementService.uploadFileFromExplorer(acceptedFiletypes, false, false).then((files) => {
			if (files.length > 0) {
				const file = files[0]
				FileManagementService.convertFileToBase64(file).then((res) => {
					props.onUpload(res)
				})
			}
		})
	}

	return (
		<Paper style={props.style} className={`image-upload-tile ${props.className ? props.className : ''}`}>
			<div className="flex-fillSpace col-xs-12 flex flex-alignItems-center flex-column flex-justifyContent-center">
				<div
					className="image-upload-tile__image-wrapper"
					onMouseEnter={() => {
						setIsMouseIn(true)
					}}
					onMouseLeave={() => {
						setIsMouseIn(false)
					}}
					onClick={() => {
						uploadImage()
					}}
				>
					{props.image && (
						<div style={{ backgroundImage: `url(${props.image})` }} className="image-upload-tile__image" />
					)}
					{!props.image && <FontAwesomeIcon icon={['far', 'image']} size="3x" />}
					{isMouseIn && (
						<div className="image-upload__hover-bg">
							<FontAwesomeIcon icon={['far', 'upload']} size="3x" />
						</div>
					)}
				</div>
			</div>
		</Paper>
	)
}
