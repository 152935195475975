import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { IconButton } from '@components/icon-button/icon-button'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { Popover } from '@components/popover/popover'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { SearchFilterContainer } from '@components/search-filter-container/search-filter-container'
import Switch from '@components/switch/switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EditResourceRoute } from '../../../modals/admin-editors/edit-resource/edit-resource'
import { UsePopoverOnEvent } from '../../../services/hooks/use-popover-on-event'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { ResourcesAPI } from '../../../services/resources/resources.api'
import { ResourceService } from '../../../services/resources/resources.service'
import { LokationResource, LokationResourceSearchParams } from '../../../services/resources/resources.types'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { DetailedResourceAdminCard } from './detailed-resource-admin__card/detailed-resource-admin__card'
import { ResourceAdminCard } from './resource-admin__card/resource-admin__card'
import { ResourcesAdminService } from './resources-admin.service'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function ResourceAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>(store.getState().tags)
	const [selectedResource, updateSelectedResource] = useState<LokationResource | 'NEW_RESOURCE' | null>(null)
	const [isPending, setIsPending] = useState(true)

	const [isPopoverOpen, setIsPopoverOpen] = useState(false)

	const popoverRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isPopoverOpen,
		setIsPopoverOpen: setIsPopoverOpen,
		delay: 0,
	})

	useAdminRedirect(props.userProfile)

	//toggle detailed view
	type AdminTableViewMode = 'simple' | 'detailed'
	const [viewMode, setViewMode] = useState<AdminTableViewMode>('simple')

	const resourceAdminHook = useItemAdminHook<LokationResource, LokationResourceSearchParams>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return ResourcesAPI.findResources({ page, size, sort }, { tags: searchParams.tags })
		},
		searchParams: { tags: selectedTags.map((tag) => tag.tagId) },
		itemIdKey: 'resourceId',
		itemTitleKey: 'title',
		setIsPending: (state) => {
			setIsPending(state)
		},
		isItemShownInList: (item) => {
			return ResourceService.doesResourceIncludeTag(item, selectedTags)
		},
	})

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Resources Administration`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={true}
				>
					<ItemSearchTagFilter
						selectedTags={selectedTags}
						onChange={(value) => {
							updateSelectedTags(value)
						}}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 id="resources-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<h1>Resources Administration</h1>
						<div className="flex flex-alignItems-center">
							<div style={{ padding: '0 1em 0 0' }}>
								<Switch
									checked={viewMode === 'detailed'}
									onChange={() => {
										if (viewMode === 'detailed') {
											setViewMode('simple')
										} else {
											setViewMode('detailed')
										}
									}}
								/>
							</div>

							<div style={{ padding: '0 1em 0 0' }}>
								<p>Detailed View</p>
							</div>

							<div className={`resource-administration filter-dropdown`} ref={popoverRef}>
								{viewMode === 'detailed' && (
									<IconButton
										className="mr-10"
										onClick={() => {
											setIsPopoverOpen(true)
										}}
										icon={'filter'}
										tooltip="Filters"
									/>
								)}

								{popoverRef.current && (
									<Popover
										{...popoverProps}
										refElement={popoverRef.current}
										setShowPopover={(newState) => {
											setIsPopoverOpen(newState)
										}}
										escapeOnMouseOut={false}
										options={{}}
									>
										<SearchFilterContainer className="mb-40-sm-down" width="standard">
											<ItemSearchTagFilter
												selectedTags={selectedTags}
												onChange={(value) => {
													updateSelectedTags(value)
												}}
											/>
										</SearchFilterContainer>
									</Popover>
								)}
							</div>

							<IconButton
								className="mr-10"
								tooltip="Export Resources to CSV"
								onClick={() => {
									ResourcesAdminService().exportResourcesToCSV()
								}}
								icon={'file-export'}
							/>

							<LoKationButton
								variant="outlined"
								label="Create Resource"
								size={'sm'}
								onClick={() => {
									updateSelectedResource('NEW_RESOURCE')
								}}
							/>
						</div>
					</div>
					<div className="mb-20 opacity-50">{resourceAdminHook.totalElements} Results</div>
					{!isPending && !resourceAdminHook.hasMoreItems && resourceAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
							<h3>We cannot find any resources matching your criteria</h3>
						</div>
					)}

					{viewMode === 'detailed' && (
						// flex pl-20 mb-10 flex-justifyContent-spaceBetween

						<>
							<div>
								<table className="mb-10">
									<tr>
										<th className="detailed-resource-param-headers">Title</th>
										<th className="detailed-resource-param-headers">Description</th>
										<th className="detailed-resource-param-headers">Resource URL</th>
										<th className="detailed-resource-param-headers">States</th>
										<th className="detailed-resource-param-headers">Tags</th>
										<th className="detailed-resource-hide">HiddenText</th>
									</tr>
								</table>
							</div>
						</>
					)}

					<InfiniteScroll
						dataLength={resourceAdminHook.items.length}
						next={resourceAdminHook.loadNextPageOfItems}
						hasMore={resourceAdminHook.hasMoreItems}
						scrollableTarget={props.ui.viewMode === 'FULL' ? `resources-admin__route-body` : undefined}
						loader={
							<>
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
							</>
						}
					>
						{resourceAdminHook.items &&
							resourceAdminHook.items.map((resource) => {
								return (
									<div key={resource.resourceId}>
										{viewMode === 'simple' && (
											<ResourceAdminCard
												resource={resource}
												key={resource.title}
												onClick={() => {
													updateSelectedResource(resource)
												}}
											/>
										)}
										{viewMode === 'detailed' && (
											<DetailedResourceAdminCard
												resource={resource}
												key={resource.title}
												onClick={() => {
													updateSelectedResource(resource)
												}}
											/>
										)}
									</div>
								)
							})}
					</InfiniteScroll>
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedResource === 'NEW_RESOURCE' && (
				<EditResourceRoute
					dismissModal={() => {
						updateSelectedResource(null)
					}}
					onCreate={(resource) => {
						resourceAdminHook.handleCreateItem(resource)
					}}
				/>
			)}

			{selectedResource && typeof selectedResource !== 'string' && 'resourceId' in selectedResource && (
				<EditResourceRoute
					item={selectedResource}
					dismissModal={() => {
						updateSelectedResource(null)
					}}
					onUpdate={(resource) => {
						resourceAdminHook.handleUpdateItem(resource)
					}}
					onDelete={(resource) => {
						resourceAdminHook.handleDeleteItem(resource)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const ResourceAdministrationRoute = connect(mapStateToProps)(ResourceAdministrationRoute__Prototype)
