import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { UserDropdownMenuTypes } from './user-dropdown-menu.types'

/** Displays a single clickable option within the user dropdown */
export function UserDropdownMenuOption(props: UserDropdownMenuTypes.Option) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`user-dropdown-menu__option`)

		if (props.prependDivider) {
			classes.push(`prepend-divider`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div
			className={getClass()}
			onClick={() => {
				props.onClick()
			}}
		>
			<FontAwesomeIcon icon={['fas', props.icon]} className="color__adjust-40 user-dropdown-menu__option-icon" />
			<div className="flex-fillSpace">
				<strong>{props.label}</strong>
				{props.desc && <div className="color__adjust-60">{props.desc}</div>}
			</div>
		</div>
	)
}
