import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { EndUserProps } from '../../../services/user/user.types'
import { AgentSearchState } from './agent-search__state.types'

function getInitialState(props: { user: EndUserProps | null }): AgentSearchState.LocalState {
	const initialState: AgentSearchState.LocalState = {
		viewMode: 'LIST',
		endUsers: null,
		resultsPage: null,
		hasMore: true,
		searchParams: {
			firstName: undefined,
			lastName: undefined,
			email: undefined,
			geography: {
				radius: 10,
				srcLat: null,
				srcLong: null,
				address1: '',
				address2: '',
				city: '',
				mailingState: '',
				zip: '',
				formattedAddress: '',
			},
			licensedStateId: undefined,
			regionId: undefined,
			phone: undefined,
			specialties: undefined,
			languageSpecialties: undefined,
		},
	}
	return initialState
}

const AgentSearchContext = createContext(getInitialState({ user: null }))
const AgentSearchDispatchContext = createContext({} as AgentSearchState.DispatchParams)

function AgentSearchReducer(state: AgentSearchState.LocalState, actions: AgentSearchState.Actions) {
	let updatedState = _.cloneDeep(state)
	actions.forEach((action) => {
		switch (action.type) {
			case 'add-end-users': {
				if (!updatedState.endUsers) {
					updatedState.endUsers = action.payload
				} else {
					updatedState.endUsers = [...updatedState.endUsers, ...action.payload]
				}
				break
			}
			case 'set-end-users': {
				updatedState.endUsers = action.payload
				break
			}
			case 'set-search-page': {
				updatedState.resultsPage = action.payload
				break
			}
			case 'reset-end-users': {
				updatedState.endUsers = null
				break
			}
			case 'reset-search-page': {
				updatedState.resultsPage = null
				break
			}
			case 'set-email': {
				updatedState.searchParams.email = action.payload
				break
			}
			case 'set-first-name': {
				updatedState.searchParams.firstName = action.payload
				break
			}
			case 'set-geography': {
				updatedState.searchParams.geography = action.payload
				break
			}
			case 'set-has-more': {
				updatedState.hasMore = action.payload
				break
			}
			case 'set-last-name': {
				updatedState.searchParams.lastName = action.payload
				break
			}
			case 'set-licensed-state-id': {
				updatedState.searchParams.licensedStateId = action.payload
				break
			}
			case 'set-licensed-region-id': {
				updatedState.searchParams.regionId = action.payload
				break
			}
			case 'set-phone': {
				updatedState.searchParams.phone = action.payload
				break
			}
			case 'set-specialties': {
				if (action.payload.length > 0) {
					updatedState.searchParams.specialties = action.payload
				} else {
					updatedState.searchParams.specialties = undefined
				}
				break
			}
			case 'set-languageSpecialties': {
				if (action.payload.length > 0) {
					updatedState.searchParams.languageSpecialties = action.payload
				} else {
					updatedState.searchParams.languageSpecialties = undefined
				}
				break
			}
			case 'update-view-mode': {
				updatedState.viewMode = action.payload
				break
			}

			case 'clear-all-search-params': {
				updatedState.searchParams.email = undefined
				updatedState.searchParams.firstName = undefined
				updatedState.searchParams.lastName = undefined
				updatedState.searchParams.geography = {
					address1: '',
					address2: '',
					city: '',
					mailingState: '',
					zip: '',
					srcLat: null,
					srcLong: null,
					radius: state.searchParams.geography.radius,
					formattedAddress: '',
				}
				updatedState.searchParams.licensedStateId = undefined
				updatedState.searchParams.regionId = undefined
				updatedState.searchParams.phone = undefined
				updatedState.searchParams.specialties = []
				updatedState.endUsers = null
				updatedState.resultsPage = null
				break
			}
		}
	})
	return updatedState
}

export function AgentSearchProvider(props: { children: React.ReactNode; endUser: EndUserProps | null }) {
	const [state, dispatch] = useReducer(AgentSearchReducer, getInitialState({ user: props.endUser }))

	return (
		<AgentSearchContext.Provider value={state}>
			<AgentSearchDispatchContext.Provider value={dispatch}>{props.children}</AgentSearchDispatchContext.Provider>
		</AgentSearchContext.Provider>
	)
}

export function useAgentSearch() {
	return useContext(AgentSearchContext)
}

export function useAgentSearchDispatch() {
	return useContext(AgentSearchDispatchContext)
}
