import { UserAPI } from './user.api'
import { EndUserProps, EndUserSearchableProps } from './user.types'

const SEARCH_SIZE = 50

export function UserSearchService() {
	function findUsers(props: { params: Partial<EndUserSearchableProps> }): Promise<EndUserProps[]> {
		return createRequest({ searchPage: 0, previousResults: [], params: props.params })
	}

	function createRequest(props: {
		params: Partial<EndUserSearchableProps>
		searchPage: number
		previousResults: EndUserProps[]
	}): Promise<EndUserProps[]> {
		let foundEndUsers: EndUserProps[] = []

		return new Promise((resolve) => {
			UserAPI.findUsersInDomain({
				query: {
					first: props.params.firstName,
					last: props.params.lastName,
					phone: props.params.phone,
					email: props.params.email,
					licensedStateId: props.params.licensedStateId,
					specialtyIds: props.params.specialties
						? props.params.specialties.map((specialty) => specialty.specialtyId)
						: undefined,
					geography: props.params.geography ? props.params.geography : undefined,
				},
				page: props.searchPage,
				size: SEARCH_SIZE,
				sort: [{ property: 'registrationTimestamp', direction: 'desc' }],
			}).then((res) => {
				foundEndUsers = [...foundEndUsers, ...res.data.items]

				if (res.data.items.length === SEARCH_SIZE) {
					createRequest({
						params: props.params,
						searchPage: props.searchPage + 1,
						previousResults: foundEndUsers,
					}).then((nextRes) => {
						foundEndUsers = [...foundEndUsers, ...nextRes]
						resolve(foundEndUsers)
					})
				} else {
					resolve(foundEndUsers)
				}
			})
		})
	}

	return { findUsers }
}
