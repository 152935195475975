import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { OptionList } from '@components/option-list/option-list'
import { OptionListProps } from '@components/option-list/option-list.types'
import { Paper } from '@components/paper/paper'
import _ from 'lodash'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../../services/analytics/analytics.service'
import { NewsFeedAPI } from '../../../services/news-feed/news-feed.api'
import { NewsFeedService } from '../../../services/news-feed/news-feed.service'
import { NewsFeed } from '../../../services/news-feed/news-feed.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { ValidationService } from '../../../services/validation/validation.service'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'
import { EditStoryBodySection } from './edits-story__body'
import { EditStoryGeneralSection } from './edits-story__general'

type EditStorySections = 'general' | 'body'

export interface EditStorySectionProps {
	mergedStoryProps: NewsFeed.Story | Omit<NewsFeed.Story, 'newsStoryId'>
	updateStoryProp: (key: keyof NewsFeed.Story, value: any) => void
}

export default function EditNewsFeedStoryRoute(props: AdminItemEditorProps<NewsFeed.Story>) {
	const [activeSection, setActiveSection] = useState<EditStorySections>('general')
	const [modifiedStoryProps, setModifiedStoryProps] = useState<Partial<NewsFeed.Story>>({})
	const mergedStoryProps: NewsFeed.Story | Omit<NewsFeed.Story, 'newsStoryId'> = _.merge(
		getOriginalStoryProps(),
		modifiedStoryProps,
	)

	function getOriginalStoryProps(): NewsFeed.Story | Omit<NewsFeed.Story, 'newsStoryId'> {
		return props.item ? props.item : newStoryProps()
	}

	function updateStoryProp(key: keyof NewsFeed.Story, value: any): void {
		const updatedState = { ...modifiedStoryProps, [key]: value }
		setModifiedStoryProps(updatedState)
	}

	function newStoryProps(): Omit<NewsFeed.Story, 'newsStoryId'> {
		return {
			title: '',
			description: '',
			createdTimestamp: String(Date.now()),
			licensedStates: [],
			stateAgnostic: true,
			tags: [],
		}
	}

	function createStory(): void {
		NewsFeedAPI.createStory(mergedStoryProps).then((newStoryRes) => {
			AnalyticsService().pushEvent({
				event_category: 'change',
				event_label: 'create_news_feed_story',
				value: {
					news_feed_story_id: newStoryRes.data.newsStoryId,
					news_feed_story_title: newStoryRes.data.title,
				},
			})

			ToastService().create({ type: 'SUCCESS', body: `Story has been created` })
			if (props.onCreate) {
				props.onCreate(newStoryRes.data)
			}
			props.dismissModal()
		})
	}

	function updateStory(): void {
		if ('newsStoryId' in mergedStoryProps) {
			const storyId = mergedStoryProps.newsStoryId
			NewsFeedAPI.updateStory(storyId, modifiedStoryProps).then((updatedStoryRes) => {
				ToastService().create({ type: 'SUCCESS', body: `Story has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedStoryRes.data)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<NewsFeed.Story> {
		return {
			itemLabel: 'Story',
			item: props.item,
			itemIdKey: 'newsStoryId',
			deleteItemFunction: NewsFeedAPI.deleteStory,
			onDelete: (item) => {
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	function getSectionOptions(): OptionListProps.Option<EditStorySections>[] {
		return [
			{
				label: 'General',
				value: 'general',
			},
			{
				label: 'Body',
				value: 'body',
			},
		]
	}

	return (
		<Modal
			maxWidth={1200}
			maxHeight={1000}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<AdminEditorTitle {...getEditorTitleProps()} />

				<ModalBody className="flex">
					<Paper
						className="mr-20 flex flex-column flex-alignItems-start p-10 flex-noShrink"
						style={{ width: '250px', position: 'sticky', top: '0px' }}
					>
						<OptionList
							persistent={true}
							value={activeSection}
							options={getSectionOptions()}
							onClick={(option) => {
								setActiveSection(option)
							}}
						/>
					</Paper>

					<div className="flex-fillSpace">
						{activeSection === 'general' && (
							<EditStoryGeneralSection
								mergedStoryProps={mergedStoryProps}
								updateStoryProp={updateStoryProp}
							/>
						)}
						{activeSection === 'body' && (
							<EditStoryBodySection
								mergedStoryProps={mergedStoryProps}
								updateStoryProp={updateStoryProp}
							/>
						)}
					</div>
				</ModalBody>

				<ModalFooter>
					<LoKationButton
						variant="outlined"
						size="sm"
						primary={false}
						label="Cancel"
						className="mr-10"
						onClick={props.dismissModal}
					/>
					{props.item && (
						<LoKationButton
							variant="contained"
							size="sm"
							label="Update"
							onClick={() => {
								const validationResults = NewsFeedService.validate(mergedStoryProps)
								if (validationResults.isValid) {
									updateStory()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
					{!props.item && (
						<LoKationButton
							variant="contained"
							size="sm"
							label="Save"
							onClick={() => {
								const validationResults = NewsFeedService.validate(mergedStoryProps)
								if (validationResults.isValid) {
									createStory()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
				</ModalFooter>
			</>
		</Modal>
	)
}
