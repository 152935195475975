import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import Switch from '@components/switch/switch'
import { TextInput } from '@components/text-input/text-input'

import { Registration } from '../../registration/registration.types'

interface OptionEditorCardProps {
	referralType: Registration.ReferralTypeOption
	onChange: (newValue: Registration.ReferralTypeOption) => void
	onDelete: () => void
}

export function ReferralTypeOption(props: OptionEditorCardProps) {
	function updateOption(key: keyof Registration.ReferralTypeOption, value: unknown): void {
		const updatedOption: Registration.ReferralTypeOption = { ...props.referralType, [key]: value }
		props.onChange(updatedOption)
	}

	return (
		<Paper className="flex flex-alignItems-center p-10 bg-color__adjust-alpha-5 mb-10">
			<div className="col-xs-12">
				<strong>Option Label</strong>
				<TextInput
					dataType="text"
					value={props.referralType.displayValue}
					width={'100%'}
					onChange={(newValue) => {
						updateOption('displayValue', newValue)
					}}
				/>
			</div>
			{props.referralType.requireAdditionalInfo && (
				<div style={{ width: '400px' }} className="flex-noShrink ml-10">
					<strong>Text Entry Label</strong>
					<TextInput
						dataType="text"
						value={props.referralType.additionalInfoLabel ? props.referralType.additionalInfoLabel : ''}
						width={'100%'}
						onChange={(newValue) => {
							updateOption('additionalInfoLabel', newValue)
						}}
					/>
				</div>
			)}
			<div style={{ width: '100px' }} className="flex-noShrink ml-10">
				<strong>Text entry</strong>
				<Switch
					checked={props.referralType.requireAdditionalInfo}
					onChange={(checked) => {
						updateOption('requireAdditionalInfo', checked)
					}}
				/>
			</div>

			<IconButton icon="times" className="ml-10" onClick={() => props.onDelete()} />
		</Paper>
	)
}
