import { LoKationButton } from '@components/button/button'
import { EmailNotificationComponent } from '@components/email-notification/email-notification'
import { Paper } from '@components/paper/paper'
import { useEffect, useState } from 'react'
import { UserAPI } from 'src/services/user/user.api'

import { EndUserOffboardingStatus, EndUserProps } from '../../../../services/user/user.types'

function DocsAndForms(props: { userToEdit: EndUserProps; currentUser: EndUserProps | null }) {
	const [offboardingStatus, setOffboardingStatus] = useState<EndUserOffboardingStatus>()

	function getOffboardingStatus(endUserId: number) {
		UserAPI.getOffboardingStatus(endUserId).then((res) => {
			setOffboardingStatus(res.data)
		})
	}

	useEffect(() => {
		getOffboardingStatus(props.userToEdit.endUserId)
	}, [])

	return (
		<div>
			<h2 className="mb-20 text-bold">Docs and Forms</h2>
			<div style={{ maxWidth: `800px` }} className="mb-40">
				<Paper elevation={1} className="mb-30 p-20 mr-10">
					<h3 className="mt-20">W9 Form</h3>
					<p>
						If you've recently changed your name, business name, or address, you will need to fill out an
						updated W-9 form. It helps ensure accurate tax reporting for businesses and organizations that
						may need your updated information. Once your updated W-9 form is completed, please send it to{' '}
						<a href="mailto:billing@lokationre.com">billing@lokationre.com</a>.
					</p>

					<LoKationButton
						variant="contained"
						size="lg"
						primary={true}
						label={`Download W-9`}
						onClick={() => {
							window.open('https://www.irs.gov/pub/irs-pdf/fw9.pdf', '_blank')
						}}
					/>
				</Paper>

				<Paper elevation={1} className="mb-30 p-20 mr-10">
					<h3 className="mt-20">Exit Form</h3>
					<h4 className="mt-10">Not the right fit?</h4>

					{offboardingStatus && offboardingStatus.adminNotified ? (
						<>
							<div className="mt-10 mb-20">
								<em>
									You have requested more information about offboarding on{' '}
									{new Date(offboardingStatus.adminNotifiedTimestamp).toLocaleDateString('en-US', {
										year: 'numeric',
										month: 'long',
										day: 'numeric',
									})}
									{' at '}
									{new Date(offboardingStatus.adminNotifiedTimestamp).toLocaleTimeString('en-US', {
										hour: '2-digit',
										minute: '2-digit',
										hour12: true,
									})}
									. Response times may very, but please send us a note at{' '}
									<a href="mailto:programs@lokationre.com">programs@lokationre.com</a> if you do not
									hear from us within <strong>48 hours</strong>.
								</em>
							</div>
							{offboardingStatus.instructionsSent && (
								<div className="mt-10 mb-20">
									<em>
										Our team has sent you an email with instructions to finish offboarding on{' '}
										{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleDateString(
											'en-US',
											{
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											},
										)}
										{' at '}
										{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleTimeString(
											'en-US',
											{
												hour: '2-digit',
												minute: '2-digit',
												hour12: true,
											},
										)}
										. Please check your inbox at the following email: {props.userToEdit.email}
									</em>
								</div>
							)}
						</>
					) : (
						<div>
							<p>
								Look we get it. Needs change, but our door is always open! In order to start
								offboarding, click the button below to send a message to our offboarding department.
							</p>
							<EmailNotificationComponent
								serviceToNotify={'offboardingRequest'}
								title="Request Offboarding Info"
								noStyles={true}
								buttonLabel="Request Offboarding Info"
							/>
						</div>
					)}
				</Paper>
			</div>
		</div>
	)
}

export default DocsAndForms
