import { LoKationButton } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { useState } from 'react'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import LokationBannerColorado1 from './banners/Colorado_1_banner.jpg'
import LokationBannerColorado2 from './banners/Colorado_2_banner.jpg'
import LokationBannerFlorida from './banners/Florida_banner.jpg'
import LokationBannerGeneral from './banners/General_banner.jpg'
import LokationBannerGeorgia from './banners/Georgia_banner.jpg'
import EmailSignature from './email_signature.jpg'
import LokationIconBlueSm from './icon-blue/Lokation__Icon_Blue_small_200x200.png'
import LokationIconBlueMedSolid from './icon-blue/Lokation_Icon_blue_Medium_301x264.jpg'
import LokationIconBlueMed from './icon-blue/Lokation_Icon_blue_Medium_500x479.png'
import LokationIconBlueVector from './icon-blue/Lokation_Icon_Blue_Print.svg'
import LokationIconBlue from './icon-blue/Lokation_Logo_blue.png'
import LokationIconGreyMed from './icon-grey/Lokation_Icon_gray_Medium_500x479.png'
import LokationIconGreyVector from './icon-grey/Lokation_Icon_gray_Print.svg'
import LokationIconGrey from './icon-grey/Lokation_logo_gray.png'
import LokationIconWhiteSm from './icon-white/Lokation__Icon_White_small_200x200.png'
import LokationIconWhite from './icon-white/Lokation_Icon_white.png'
import LokationIconWhiteMedSolid from './icon-white/Lokation_Icon_white_Medium_301x271.jpg'
import LokationIconWhiteMed from './icon-white/Lokation_Icon_white_Medium_500x479.png'
import LokationIconWhiteVector from './icon-white/Lokation_Icon_White_Print.svg'
import LokationLogoBlueMedSolid from './Lokation_blue_Medium_500x334.jpg'
import LokationLogoBlueMed from './Lokation_blue_Medium_500x334.png'
import LokationLogoBlueSm from './Lokation_blue_small_200x200_1.png'
import LokationLogoBlueVector from './LoKation_Logo_Registered_Name_2019.svg'
import LokationLogoNameBlue from './Lokation_name_blue.png'
import LokationLogoNameBlueMed from './Lokation_Name_blue_Medium_500x101.png'
import LokationLogoNameBlueMedSolid from './Lokation_Name_blue_Medium_500x114.jpg'
import LokationLogoNameBlueSm from './Lokation_name_blue_small_200x200_1.png'
import LokationLogoNameGreySm from './Lokation_name_gray.png'
import LokationLogoNameGreyMed from './Lokation_Name_gray_Medium_500x101.png'
import LokationLogoNameGreyVector from './Lokation_Name_gray_Print.svg'
import LokationNameWhite from './Lokation_name_white.png'
import LokationNameWhiteMed from './Lokation_Name_white_Medium_500x101.png'
import LokationNameWhiteMedSolid from './Lokation_Name_white_Medium_500x114.jpg'
import LokationNameWhiteSm from './Lokation_name_white_small_200x200_1.png'
import LokationLogoWhiteMedSolid from './Lokation_white_Medium_371x257.jpg'
import LokationLogoWhiteMed from './Lokation_white_Medium_500x334.png'
import LokationLogoWhiteVector from './Lokation_White_Print.svg'
import LokationLogoWhiteSm from './Lokation_white_small_200x200_1.png'
import BuiltByAgents from './marketing-material/built-by-agents.jpg'
import LokationStats from './marketing-material/lokation-stats.jpg'
import LokationPinAlabama from './pins/Alabama_Pin.png'
import LokationPinColorado from './pins/Colorado_Pin.png'
import LokationPinFlorida from './pins/Florida_Pin.png'
import LokationPinGeorgia from './pins/Georgia_Pin.png'
import LokationPinTexas from './pins/Texas_pin.png'
// import ZoomBackground from './zoom_background.png'

export function LoKationResourceBrandResources() {
	function downloadFile(file: string, filename: string): void {
		var a = document.createElement('a')
		a.href = file
		a.download = filename
		a.click()
	}

	const [emailIsSent, setEmailIsSent] = useState(false)

	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 flex flex-wrap flex-alignItems-start">
					<h2 className="mb-20 col-xs-12">Brand Resources</h2>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Logos for Marketing</h6>
						<Paper className="p-10 flex flex-column flex-alignItems-center bg-color__adjust-alpha-5">
							<img src={LokationLogoBlueSm} className="col-xs-12" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationLogoBlueSm, 'Lokation_blue_small_200x200.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationLogoBlueMed, 'Lokation_blue_Medium_500x334.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Solid Background (.jpg)"
								onClick={() => {
									downloadFile(LokationLogoBlueMedSolid, 'Lokation_blue_Medium_500x334.jpg')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationLogoBlueVector, 'LoKation_Logo_Registered_Name_2019.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Name Blue</h6>
						<Paper className="p-10 flex flex-column flex-alignItems-center bg-color__adjust-alpha-5">
							<img src={LokationLogoNameBlue} className="col-xs-12 mb-10" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationLogoNameBlueSm, 'Lokation_name_blue_small_200x200.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationLogoNameBlueMed, 'Lokation_Name_blue_Medium_500x101.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Solid Background (.jpg)"
								onClick={() => {
									downloadFile(LokationLogoNameBlueMedSolid, 'Lokation_Name_blue_Medium_500x114.jpg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Name Grey</h6>
						<Paper className="p-10 flex flex-column flex-alignItems-center bg-color__adjust-alpha-5">
							<img src={LokationLogoNameGreySm} className="col-xs-12 mb-10" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationLogoNameGreySm, 'Lokation_Name_gray.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationLogoNameGreyMed, 'Lokation_Name_gray_Medium_500x101.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationLogoNameGreyVector, 'Lokation_Name_gray_Print.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Logo White</h6>
						<Paper
							className="p-10 flex flex-column flex-alignItems-center"
							style={{ backgroundColor: '#333', color: '#fff' }}
						>
							<img src={LokationLogoWhiteSm} className="col-xs-12 mb-10" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationLogoWhiteSm, 'Lokation_white_small_200x200_1.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationLogoWhiteMed, 'Lokation_white_Medium_500x334.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Solid Background (.jpg)"
								onClick={() => {
									downloadFile(LokationLogoWhiteMedSolid, 'Lokation_white_Medium_371x257.jpg')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationLogoWhiteVector, 'Lokation_White_Print.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Icon White</h6>
						<Paper
							className="p-10 flex flex-column flex-alignItems-center"
							style={{ backgroundColor: '#333', color: '#fff' }}
						>
							<img src={LokationIconWhite} className="col-xs-12 mb-10" style={{ maxWidth: '100px' }} />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationIconWhiteSm, 'Lokation__Icon_White_small_200x200.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationIconWhiteMed, 'Lokation_Icon_white_Medium_500x479.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Solid Background (.jpg)"
								onClick={() => {
									downloadFile(LokationIconWhiteMedSolid, 'Lokation_Icon_white_Medium_301x271.jpg')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationIconWhiteVector, 'Lokation_Icon_White_Print.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Name White</h6>
						<Paper
							className="p-10 flex flex-column flex-alignItems-center"
							style={{ backgroundColor: '#333', color: '#fff' }}
						>
							<img src={LokationNameWhite} className="col-xs-12 mb-10" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationNameWhiteSm, 'Lokation_name_white_small_200x200_1.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationNameWhiteMed, 'Lokation_Name_white_Medium_500x101.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								style={{ color: '#fff' }}
								label="Medium - Solid Background (.jpg)"
								onClick={() => {
									downloadFile(LokationNameWhiteMedSolid, 'Lokation_Name_white_Medium_500x114.jpg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12"></div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Icon Grey</h6>
						<Paper className="p-10 flex flex-column flex-alignItems-center bg-color__adjust-alpha-5">
							<img src={LokationIconGrey} className="col-xs-12 mb-10" style={{ maxWidth: '100px' }} />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationIconGrey, 'Lokation__Icon_Grey.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationIconGreyMed, 'Lokation_Icon_gray_Medium_500x479.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationIconGreyVector, 'Lokation_Icon_Grey_Print.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-3 mb-20 mr-20">
						<h6 className="mb-10">Lokation Icon Blue</h6>
						<Paper className="p-10 flex flex-column flex-alignItems-center bg-color__adjust-alpha-5">
							<img src={LokationIconBlue} className="col-xs-12 mb-10" style={{ maxWidth: '100px' }} />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Small (Email) Version File"
								onClick={() => {
									downloadFile(LokationIconBlueSm, 'Lokation__Icon_Blue_small_200x200.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationIconBlueMed, 'Lokation_Icon_blue_Medium_500x479.png')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Medium - Transparent File"
								onClick={() => {
									downloadFile(LokationIconBlueMedSolid, 'Lokation_Icon_blue_Medium_301x264.jpg')
								}}
							/>
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Hi-Resolution (Print) Vector File"
								onClick={() => {
									downloadFile(LokationIconBlueVector, 'Lokation_Icon_Blue_Print.svg')
								}}
							/>
						</Paper>
					</div>

					<div className="col-xs-12 mb-20 mr-20">
						<h6 className="mb-10">Pins</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationPinColorado}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '80px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Colorado"
									onClick={() => {
										downloadFile(LokationPinColorado, 'Colorado_Pin.png')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationPinFlorida}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '80px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Florida"
									onClick={() => {
										downloadFile(LokationPinFlorida, 'Florida_Pin.png')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationPinGeorgia}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '80px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Georgia"
									onClick={() => {
										downloadFile(LokationPinGeorgia, 'Georgia_Pin.png')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img src={LokationPinTexas} className="col-xs-12 mb-10" style={{ maxWidth: '80px' }} />
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Texas"
									onClick={() => {
										downloadFile(LokationPinTexas, 'Texas_Pin.png')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationPinAlabama}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '80px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Alabama"
									onClick={() => {
										downloadFile(LokationPinAlabama, 'Alabama_Pin.png')
									}}
								/>
							</div>
						</Paper>
					</div>

					<div className="col-xs-12 mb-20 mr-20">
						<h6 className="mb-10">Banners</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationBannerGeneral}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '120px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Facebook - Cover"
									onClick={() => {
										downloadFile(LokationBannerGeneral, 'General_banner.jpg')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationBannerColorado1}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '120px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Colorado"
									onClick={() => {
										downloadFile(LokationBannerColorado1, 'Colorado_1_banner.jpg')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationBannerColorado2}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '120px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Colorado Group"
									onClick={() => {
										downloadFile(LokationBannerColorado2, 'Colorado_2_banner.jpg')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationBannerFlorida}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '120px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Florida"
									onClick={() => {
										downloadFile(LokationBannerFlorida, 'Florida_banner.jpg')
									}}
								/>
							</div>
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img
									src={LokationBannerGeorgia}
									className="col-xs-12 mb-10"
									style={{ maxWidth: '120px' }}
								/>
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Georgia"
									onClick={() => {
										downloadFile(LokationBannerGeorgia, 'Georgia_Pin.png')
									}}
								/>
							</div>
						</Paper>
					</div>

					<div className="col-xs-12 mb-20 mr-20">
						<h6 className="mb-10">Lokation Marketing Material</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img src={LokationStats} className="col-xs-12 mb-10" style={{ maxWidth: '120px' }} />
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="LoKation Stats"
									onClick={() => {
										downloadFile('/pdf/lokation-stats.pdf', 'lokation-stats.pdf')
									}}
								/>
							</div>

							<div className="col-xs-12 col-lg-2 flex flex-column flex-alignItems-center">
								<img src={BuiltByAgents} className="col-xs-12 mb-10" style={{ maxWidth: '120px' }} />
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Built By Agents"
									onClick={() => {
										downloadFile('/pdf/build-by-agents.pdf', 'build-by-agents.pdf')
									}}
								/>
							</div>
						</Paper>
					</div>

					<div className="col-xs-12 col-lg-5 mb-20 mr-20">
						<h6 className="mb-10">Email Signature</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<img src={EmailSignature} className="col-xs-12 mb-10" />

							{emailIsSent ? (
								<div>Your email signature has been sent. Please check your inbox.</div>
							) : (
								<LoKationButton
									variant="text"
									size="sm"
									className="mb-10"
									label="Request LoKation E-Mail Signature"
									onClick={() => {
										EmailNotificationAPI.emailSignatureNotification()
											.then((res) => {
												setEmailIsSent(true)
											})
											.catch((error) => {
												console.log(error)
											})
									}}
								/>
							)}
						</Paper>
					</div>

					{/* <div className="col-xs-12 col-lg-5 mb-20 mr-20">
						<h6 className="mb-10">Zoom Background</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<img src={ZoomBackground} className="col-xs-12 mb-10" />
							<LoKationButton
								variant="text"
								size="sm"
								className="mb-10"
								label="Download Template"
								onClick={() => {
									downloadFile(ZoomBackground, 'zoom_background.png')
								}}
							/>
						</Paper>
					</div> */}

					<div className="col-xs-12 mb-20 mr-20">
						<h6 className="mb-10">Color Palette</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#002A4E',
									}}
								></div>
								<div className="mb-10">
									<strong>#002A4E</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=100 M=57 Y=12 K=66</div>
								<div style={{ fontSize: '85%' }}>R=0 G=48 B=87</div>
								<div style={{ fontSize: '85%' }}>PANTONE=540C</div>
							</div>

							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#083E63',
									}}
								></div>
								<div className="mb-10">
									<strong>#083E63</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=100 M=77 Y=37 K=25</div>
								<div style={{ fontSize: '85%' }}>R=8 G=62 B=99</div>
								<div style={{ fontSize: '85%' }}>PANTONE=7694C</div>
							</div>

							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#222222',
									}}
								></div>
								<div className="mb-10">
									<strong>#222222</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=72 M=66 Y=64 K=72</div>
								<div style={{ fontSize: '85%' }}>R=34 G=34 B=34</div>
								<div style={{ fontSize: '85%' }}>PANTONE=NATURAL BLACK C</div>
							</div>

							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#4D4D4D',
									}}
								></div>
								<div className="mb-10">
									<strong>#4D4D4D</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=65 M=58 Y=57 K=37</div>
								<div style={{ fontSize: '85%' }}>R=77 G=77 B=77</div>
								<div style={{ fontSize: '85%' }}>PANTONE=7540C</div>
							</div>

							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#8F8F8F',
									}}
								></div>
								<div className="mb-10">
									<strong>#8F8F8F</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=46 M=38 Y=39 K=3</div>
								<div style={{ fontSize: '85%' }}>R=143 G=143 B=143</div>
								<div style={{ fontSize: '85%' }}>PANTONE=877C</div>
							</div>

							<div className="flex flex-column flex-alignItems-center text-center p-20">
								<div
									className="mb-10"
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '50%',
										backgroundColor: '#D0D0D0',
									}}
								></div>
								<div className="mb-10">
									<strong>#D0D0D0</strong>
								</div>
								<div style={{ fontSize: '85%' }}>C=18 M=13 Y=14 K=0</div>
								<div style={{ fontSize: '85%' }}>R=208 G=208 B=208</div>
								<div style={{ fontSize: '85%' }}>PANTONE=COOL GRAY 2 C</div>
							</div>
						</Paper>
					</div>

					{/* <div className='col-xs-12 col-lg-5 mb-20 mr-20'>
						<h6 className='mb-10'>Fonts</h6>
						<Paper className="p-10 flex flex-wrap bg-color__adjust-alpha-5">
							<LoKationButton variant='text' size='sm' className='mb-10' label="Axis Extra Bold" onClick={() => {
								window.open('./fonts/Axis_Extrabold.otf', '_blank')
							}} />
						</Paper>
					</div> */}
				</div>
			</div>
		</div>
	)
}
