import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownChildOption } from '@components/dropdown/dropdown.types'
import { ModalBody } from '@components/modal/modal-body'
import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { TagSelectorDropdown } from '@components/tag-selector-dropdown/tag-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { Calendar } from '../../../services/calendar/calendar.types'
import { CalendarEventAPI } from '../../../services/calendar/calendar-event.api'
import { LicensedState } from '../../../services/licensed-states/licenses-states.types'
import { LokationTag } from '../../../services/tags/tag.types'
import { AdminEditorTitle, AdminEditorTitleProps } from './../components/editor-title'
import { EditCalendarEventFooter } from './components/edit-calendar-event__footer'
import { EditCalendarEventNonRecurringConfig } from './components/edit-calendar-event__non-recurring-config'
import { EditCalendarEventRecurringConfig } from './components/edit-calendar-event__recurring-config'
import { useEditCalendarEvent, useEditCalendarEventDispatch } from './state/edit-calendar-event__state'

interface ConnectedProps {
	calendars: Calendar.Calendar[]
	tags: LokationTag[]
	licensedStates: LicensedState[]
}

export interface EditCalendarEventInnerProps {
	dismissModal: () => void
	onDelete?: (event: Calendar.Event) => void
	onCreate?: (event: Calendar.Event) => void
	onUpdate?: (event: Calendar.Event) => void
}

function EditCalendarEventInnerPrototype(props: ConnectedProps & EditCalendarEventInnerProps): JSX.Element {
	const editCalendarState = useEditCalendarEvent()
	const editCalendarDispatch = useEditCalendarEventDispatch()

	/** ================================== */
	/** Methods*/

	function getEditorTitleProps(): AdminEditorTitleProps<Calendar.Event> {
		return {
			itemLabel: 'Event',
			item: editCalendarState,
			itemIdKey: 'calendarEventId',
			deleteItemFunction: CalendarEventAPI.deleteEvent,
			onDelete: (event) => {
				if (props.onDelete) {
					props.onDelete(event)
				}
				props.dismissModal()
			},
		}
	}

	function getCalendarOptions(): DropdownChildOption<Calendar.Calendar>[] {
		return props.calendars.map((calendar) => {
			return { label: calendar.name, value: calendar }
		})
	}

	return (
		<>
			<AdminEditorTitle {...getEditorTitleProps()} />

			<ModalBody>
				<TextInput
					label="Title"
					dataType="text"
					value={editCalendarState.title}
					width="100%"
					className="mb-20"
					onChange={(updatedValue) => {
						editCalendarDispatch([{ type: 'update-event-props', payload: { title: updatedValue } }])
					}}
				/>
				Calendar
				<Dropdown<Calendar.Calendar>
					value={editCalendarState.calendar ? [editCalendarState.calendar] : []}
					options={getCalendarOptions()}
					onSelect={(selectedOptions) => {
						editCalendarDispatch([
							{ type: 'update-event-props', payload: { calendar: selectedOptions[0] } },
						])
					}}
					className="mb-20"
				/>
				<div className="flex flex-alignItems-center mt-20 mb-20">
					<Checkbox
						className="mr-20"
						checked={editCalendarState.allDay}
						onChange={(newState) => {
							editCalendarDispatch([{ type: 'update-event-props', payload: { allDay: newState } }])
						}}
					>
						This event is all day
					</Checkbox>
				</div>
				<Checkbox
					checked={editCalendarState.recurring}
					className="mb-20"
					onChange={(newState) => {
						editCalendarDispatch([{ type: 'update-event-props', payload: { recurring: newState } }])
					}}
				>
					This event is recurring
				</Checkbox>
				{!editCalendarState.recurring && <EditCalendarEventNonRecurringConfig />}
				{editCalendarState.recurring && <EditCalendarEventRecurringConfig />}
				<h6 className="mb-5">Event Details</h6>
				<RichTextEditor
					placeholder={`Add description`}
					className="mb-20"
					value={editCalendarState.description}
					rows={5}
					onChange={(updatedValue) => {
						editCalendarDispatch([{ type: 'update-event-props', payload: { description: updatedValue } }])
					}}
				/>
				<div className="mb-20 flex flex-column">
					<strong className="mb-5">Tags</strong>
					<TagSelectorDropdown
						selectedTags={editCalendarState.tags}
						options={props.tags}
						onSelect={(selectedTags) => {
							editCalendarDispatch([{ type: 'update-event-props', payload: { tags: selectedTags } }])
						}}
					/>
				</div>
				<div className="mb-20 flex flex-column">
					<strong className="mb-5">Applicable States</strong>
					<StateSelectorDropdown
						multiselect={true}
						selectedStates={editCalendarState.licensedStates}
						options={props.licensedStates}
						onSelect={(selectedStates) => {
							editCalendarDispatch([
								{ type: 'update-event-props', payload: { licensedStates: selectedStates } },
							])
						}}
					/>
				</div>
			</ModalBody>

			<EditCalendarEventFooter {...props} />
		</>
	)
}

function mapStateToProps(state: RootState, ownProps: EditCalendarEventInnerProps) {
	return {
		calendars: state.calendar,
		tags: state.tags,
		licensedStates: state.licensedStates,
	}
}

export const EditCalendarEventInner = connect(mapStateToProps)(EditCalendarEventInnerPrototype)
